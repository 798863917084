import React, { useEffect, useState } from 'react';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation';

const FptBlockoutItem = (props) => {

    useEffect(() => {
        setAvailable(!props.blocked);
    }, [props.blocked]);

    const [available, setAvailable] = useState(true);

    const bttnAvailableClick = () => {
        // Event ausgeben!
        // onBlockoutChange( idTimeSlot, blocked )
        props.onBlockoutChange && props.onBlockoutChange(props.idFptMeetingZeitslot, available);
        setAvailable(!available)
    }

    return (
        <ListItem
            disabled={props.disabled}
        >
            <ListItemIcon>
                <InsertInvitationIcon />
            </ListItemIcon>
            <ListItemText
                primary={
                    <span>
                        {props.uhrzeit}

                        { !props.disabled &&
                            <FormControlLabel
                                style={{ marginLeft: '24px' }}
                                label="Verfügbar"
                                control={
                                    <Switch
                                        onChange={bttnAvailableClick}
                                        checked={available}
                                    />
                                }
                            />
                        }
                    </span>
                }
            />
        </ListItem>
    )
}

export default FptBlockoutItem;