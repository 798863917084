import React from 'react';
import { useParams } from "react-router-dom";

import FptHeader from '../../utils/FptHeader';
import FptForceAnmeldung from '../../utils/FptForceAnmeldung';
import FptThema from '../generic/FptThemaSeite';


const idFpt = 'fpl001';

const PageThema = (props) => {

	const { idThema } = useParams();

	return (
		<React.Fragment>
			<FptHeader title="fachpresse.live 09/2020" />

			<FptForceAnmeldung idFpt={idFpt} ablaufdatum='2020-12-01' fptTitel="fachpresse.live 09/2020">

				<FptThema idThema={idThema} idFpt={idFpt} hideFotos hideInvitation download/>

			</FptForceAnmeldung>
		</React.Fragment>
	);

}

export default PageThema;
