import React, { useEffect } from 'react';
import { useParams } from "react-router-dom";
import { connect } from 'react-redux'

import Button from '@material-ui/core/Button';
import FptForceAnmeldung from '../../utils/FptForceAnmeldung';
import FptProfilPersonCard from '../../utils/FptProfilPersonCard';
import FptProfilFirmaCard from '../../utils/FptProfilFirmaCard';
import FptHeader from '../../utils/FptHeader';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { startReadProfileFirmaByUser, startReadTeilnehmerPersonen } from '../../../actions/fpt';
import { startSaveProfilPerson, startReadProfilPerson } from '../../../actions/fpt';

const idFpt = 'fpt032';

const PageProfil = (props) => {

	const { idPerson } = useParams();

	useEffect(() => {
		loadProfilPerson(idPerson)
		loadFirmenPerson(idPerson);
	}, []);


	const loadProfilPerson = (id) => {
		console.log('Load Profile', id);
		props.dispatch(startReadProfilPerson(id));
	}

	const loadFirmenPerson = (idProfilPerson) => {
		props.dispatch(startReadProfileFirmaByUser(idProfilPerson, idFpt, false))
	}

	const bttnEinladenClick = () => {
		props.history.push(`/fpt032/einladen/${idPerson}/${idFpt}/${!!props.profilPerson.idFptTeilnehmerTyp.includes('j')}/false`)
	}

	return (

		<React.Fragment>
			<FptHeader title="Profil" />

			<FptForceAnmeldung idFpt={idFpt} fptTitel="Fachpressetage 2021">
				{props.profilPerson &&
					<div style={{marginBottom: '24px'}}>
						<Button color="primary" variant="contained" onClick={bttnEinladenClick}>
							{(props.profilPerson.titel ? props.profilPerson.titel + " " : "") + props.profilPerson.vorname + " " + props.profilPerson.nachname} zum Gespräch einladen
						</Button>
					</div>
				}

				<Grid container spacing={2}>

					{props.profilPerson &&
						<React.Fragment>
							<Grid item xs={12} md={6} lg={4}>
								<FptProfilPersonCard
									readonly={true}
									profilPerson={props.profilPerson}
								/>
							</Grid>

							{props.profileFirma && props.profileFirma.map(firma =>
								<Grid item xs={12} md={6} lg={4} key={firma.idFptProfilFirma}>
									<FptProfilFirmaCard readonly={true} profilFirma={firma} isRedaktion={props.profilPerson.idFptTeilnehmerTyp.includes('j')} />
								</Grid>
							)}
						</React.Fragment>
					}



					{props.profilPerson && !props.profilPerson.idFptTeilnehmerTyp.includes('j') && !props.profilPerson.idFptTeilnehmerTyp.includes('f') &&
						<React.Fragment>
							FEHLER: Person ist nicht angemeldet!
					</React.Fragment>
					}

				</Grid>

			</FptForceAnmeldung>

		</React.Fragment>
	);

}


const mapStateToProps = state => ({
	profilPerson: state.fpt.profilPersonSelected,
	profileFirma: state.fpt.profileFirmaSelected,
});

export default connect(mapStateToProps)(PageProfil);
