import React, { Fragment, useEffect } from 'react';
import { startBuildZip, startGetThemaFiles, strtRemoveThemaFile } from '../../actions/fpt';
import { connect } from 'react-redux';
import RbsFileVizualizer from './RbsFileVizualizer';
import { Button, CircularProgress, Typography } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';

const FptThemaFiles = (props) => {

    const [zipFile, setZipFile] = React.useState(undefined);
    const [themaFiles, setThemaFiles] = React.useState(undefined);
    const [forceRegenerateZip, setForceRegenerateZip] = React.useState(false);

    useEffect(() => {
        props.thema && loadThemaFiles();
        // Wir trggern auch, dasss das Zipfile gneriert wird
        // Aber nicht im Edit-Mode, dann wollen wir das nicht
        props.thema && !props.deletable && props.dispatch(startBuildZip(props.thema.idAuftragFpt.trim(), props.thema.idFptThema, onZipFileGenerated));

    }, [props.thema]);


    useEffect(() => {
        props.thema && loadThemaFiles();
        setForceRegenerateZip(props.forceRegenerateZip);
    }, [props.forceRegenerateZip]);

    const loadThemaFiles = () => {
        props.thema.idAuftragFpt &&
            props.dispatch(startGetThemaFiles(props.thema.idAuftragFpt.trim(), props.thema.idFptThema, onThemaFilesLoaded));
    }

    const onThemaFilesLoaded = (files) => {
        setThemaFiles(files);
    }

    const onZipFileGenerated = (zipfile) => {
        setZipFile(zipfile);
        props.onZipFileGenerated && props.onZipFileGenerated(zipfile);
        setForceRegenerateZip(false);
    }

    const onDeleteFile = (id) => {
        props.dispatch(strtRemoveThemaFile(props.thema.idFptThema, id, () => loadThemaFiles()));
        setForceRegenerateZip(new Date().getTime());
    }

    const onBttnRegenerateZipClick = () => {
        props.dispatch(startBuildZip(props.thema.idAuftragFpt.trim(), props.thema.idFptThema, onZipFileGenerated));
    }

    const isZipFileGenerating = () => {
        return !!props.zipFilesGenerating.find(id => id == props.thema.idFptThema)
    }

    return (
        <Fragment>
            {themaFiles && themaFiles.length > 0 ?
                <Fragment>
                    <Typography variant='subtitle2' gutterBottom>
                        Klicken Sie zum Download auf die einzelnen Elemente.
                    </Typography>
                    <div>
                        {themaFiles && themaFiles.map(file =>
                            <RbsFileVizualizer key={file.id} deletable={props.deletable} onDeleteFile={onDeleteFile} float={props.float} file={file} />
                        )}
                    </div>
                    <div style={{ clear: 'both', marginBottom: '12px' }} />

                    {zipFile && !forceRegenerateZip &&
                        <Button
                            href={zipFile.name && "https://files.rbsonline.de/Files/fptzip/" + zipFile.name}
                            size="medium"
                            variant="contained"
                            color="secondary"
                            disabled={!zipFile || isZipFileGenerating()}
                        >
                            <GetAppIcon />
                            {isZipFileGenerating() ?
                                <span>
                                    <CircularProgress size={20} />
                                    ... Zipfile wird generiert
                                </span>
                                :
                                <span>Download komplett als zip-File</span>
                            }
                        </Button>
                    }
                    {forceRegenerateZip &&
                        <Button
                            size="medium"
                            variant="contained"
                            color="primary"
                            onClick={onBttnRegenerateZipClick}
                        >
                            <GetAppIcon />
                            {isZipFileGenerating() ?
                                <span>
                                    <CircularProgress size={20} />
                                    ... Zipfile wird generiert
                                </span>
                                :
                                <span>Wenn Sie fertig sind, hier klicken und zip generieren</span>
                            }
                        </Button>
                    }
                </Fragment>
                :
                <Typography variant='subtitle2' gutterBottom>
                    Es wurden noch keine Dateien hochgeladen.
                </Typography>
            }


        </Fragment>
    )
}


const mapStateToProps = (state, ownProps) => ({
    //themaFiles: ownProps.thema && state.fpt.themaFiles[ownProps.thema.idFptThema],
    //themaZipfile: state.fpt.themaZipfile,
    //themaFilesChanged: state.fpt.themaFilesChanged,
    zipFilesGenerating: state.fpt.zipFilesGenerating,
});


export default connect(mapStateToProps)(FptThemaFiles);