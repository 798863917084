import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router';

import { styled } from '@material-ui/core/styles';
import { Card, Grid, IconButton, Typography } from '@material-ui/core';
import FptBewertenButtons from '../../utils/FptBewertenButtons';
import { ArrowForwardIos, ArrowBackIos } from '@material-ui/icons';
import ReactHtmlParser from 'react-html-parser';

import { logoUri } from '../../../config/globals';


const FptBewertungThema = (props) => {

    const LogoDiv = styled('div')(({ theme }) => ({
        [theme.breakpoints.up('sm')]: {
            position: 'absolute',
            right: '24px',
            top: '24px'
        },
    }));


    const FirmaDiv = styled('div')(({ theme }) => ({
        [theme.breakpoints.down('sm')]: {
            clear: 'both',
            marginTop: '12px'
        },
        [theme.breakpoints.up('sm')]: {
            marginRight: '200px'
        },
    }));



    const [idFptThema, setIdFptThema] = useState(undefined);
    const [selectedBewertung, setSelectedBewertung] = useState(undefined);

    useEffect(() => {
        setData(props.idThema);
    }, [props.idThema]);


    const setData = (idThema) => {
        setIdFptThema(idThema);
        console.log('###### aktiuelle bewertung', getMeineBewertung(idThema));
        setSelectedBewertung(getMeineBewertung(idThema));
    }

    const getThema = (id) => {
        if (!id) {
            return {}
        }

        const thema = props.fpt[props.idFpt].themen ? props.fpt[props.idFpt].themen.find(thema => thema.idFptThema == id) : {};
        return thema;
    }

    const getMeineBewertung = (idThema) => {
        if (!idThema) {
            return {}
        }

        return props.fpt[props.idFpt].meineBewertungen ? props.fpt[props.idFpt].meineBewertungen.find(b => b.idFptThema == idThema) : {}
    }


    const getThemenSortiert = () => {
        return props.fpt[props.idFpt].themen
            .filter(thema => thema.veranstaltung.includes('Pressekonferenz'))
            .sort((a, b) => (a.firmenname.toUpperCase() < b.firmenname.toUpperCase() ? -1 : 1))
    }


    const getNextId = () => {
        const themenSortiert = getThemenSortiert();

        if (themenSortiert) {
            const index = themenSortiert.findIndex(thema => thema.idFptThema == idFptThema);
            if (index < themenSortiert.length - 1) {
                return themenSortiert[index + 1].idFptThema;
            }
            else {
                return themenSortiert[0].idFptThema
            }
        }
    }

    const getPreviousId = () => {
        const themenSortiert = getThemenSortiert();

        if (themenSortiert) {
            const index = themenSortiert.findIndex(thema => thema.idFptThema == idFptThema);
            if (index >= 1) {
                return themenSortiert[index - 1].idFptThema;
            }
            else {
                return themenSortiert[themenSortiert.length - 1].idFptThema
            }
        }
    }

    const bttnWeiterClick = () => {
        setData(getNextId());
    }

    const bttnZurueckClick = () => {
        setData(getPreviousId());
    }

    return (
        <React.Fragment>
            <Grid container spacing={0} style={{paddingTop:'20px', paddingBottom:'20px', backgroundColor:'#f0f0f0'}}>

                <Grid xs={12} sm={2} lg={1} style={{ position: 'relative', minHeight: '60px' }}>
                    <div style={{ position: 'absolute', top: '50%', right: '50%', transform: 'translate(50%,-50%)' }}>
                        <IconButton onClick={bttnZurueckClick}>
                            <ArrowBackIos />
                        </IconButton>
                    </div>
                </Grid>


                <Grid xs={12} sm={8} lg={10} >
                    {props.fpt[props.idFpt].themen &&
                        <Card variant="outlined" square style={{ padding: "24px 12px 24px 12px", position: 'relative' }}>

                            <div style={{ minHeight: '90px' }}>
                                <FirmaDiv>
                                    <Typography variant="h6" gutterBottom >
                                        {getThema(idFptThema).firmenname}
                                    </Typography>
                                </FirmaDiv>
                                <LogoDiv>
                                    <img
                                        src={getThema(idFptThema).logoFile && logoUri + getThema(idFptThema).logoFile + "?v=" + (new Date()).getTime()}
                                        style={{ float: 'left', maxWidth: "200px", maxHeight: "60px", marginBottom: '32px' }}
                                    />
                                </LogoDiv>
                                <div style={{ clear: 'both' }}></div>
                            </div>

                            <div>
                                <Typography variant="h5" gutterBottom>
                                    {getThema(idFptThema).titel}
                                </Typography>

                                <Typography variant="body1" gutterBottom>
                                    {ReactHtmlParser(getThema(idFptThema).abstract)}
                                </Typography>
                            </div>

                            <div>
                                <div style={{ height: '120px', marginTop: '24px', position: 'relative' }}>
                                    <Typography variant="button" style={{ minWidth: '140px', position: 'absolute', right: '50%', transform: 'translateX(50%)' }}>
                                        Meine Bewertung:
                                    </Typography>

                                    <div style={{ position: 'relative', top: '15px', width: '100%' }}>
                                        <FptBewertenButtons
                                            idFpt={props.idFpt}
                                            idThema={idFptThema}
                                            bemerkungVisible={true}
                                            bewertung={selectedBewertung}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Card>
                    }
                </Grid>

                <Grid xs={12} sm={2} lg={1} style={{ position: 'relative', minHeight: '60px' }}>
                    <div style={{ position: 'absolute', top: '50%', right: '50%', transform: 'translate(50%,-50%)' }}>
                        <IconButton onClick={bttnWeiterClick}>
                            <ArrowForwardIos />
                        </IconButton>
                    </div>
                </Grid>

            </Grid>
        </React.Fragment>
    )
}


const mapStateToProps = state => ({
    user: state.global.user,
    fpt: state.fpt
});

export default withRouter(connect(mapStateToProps)(FptBewertungThema));



