import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { ButtonGroup, IconButton, TextField, Tooltip, InputAdornment } from '@material-ui/core';
import IconYes from '@material-ui/icons/CheckCircleOutline';
import IconYesSelected from '@material-ui/icons/CheckCircle';
import IconNo from '@material-ui/icons/CancelOutlined';
import IconNoSelected from '@material-ui/icons/Cancel';
import IconEnthaltung from '@material-ui/icons/RemoveCircleOutline';
import IconEnthaltungSelected from '@material-ui/icons/RemoveCircle';
import { Save } from '@material-ui/icons';

import { startUpdateBewertung } from '../../actions/fpt';


const FptBewertenButtons = (props) => {

    const [bewertung, setBewertung] = useState({});

    useEffect(() => {
        props.bewertung && setBewertung(props.bewertung);
    }, [props.bewertung]);


    const setAndSaveBewertung = (wert) => {
        const newBewertung = {
            ...bewertung,
            bewertung: wert
        };
        setBewertung(newBewertung);
        saveBeweretung(newBewertung);
    }

    const saveBeweretung = (bew) => {
        props.dispatch(startUpdateBewertung(props.idFpt, props.idThema, props.user.idFptProfilPerson, bew.bewertung, bew.bemerkung ? bew.bemerkung :''));
    }

    const bttnEnthaltungClick = (e) => {
        e.stopPropagation();  
        setAndSaveBewertung(0);
    }

    const bttnYesClick = (e) => {
        e.stopPropagation();
        setAndSaveBewertung(1);
    }

    const bttnNoClick = (e) => {
        e.stopPropagation();
        setAndSaveBewertung(-1);
    }

    const bttnSaveClick = () => {
        saveBeweretung(bewertung);
    }

    const txtBemerkungChange = (e) => {
        setBewertung({
            ...bewertung,
            bemerkung: e.target.value
        });
    }

    const txtBemerkungBlur = () => {
        saveBeweretung(bewertung);
    }

    return (
        <React.Fragment>

            <ButtonGroup variant="outlined" style={{ position: 'absolute', right: '50%', transform: 'translateX(50%)' }}>
                <IconButton onClick={bttnNoClick}>
                    <Tooltip title="soll nicht ins Programm">
                        {bewertung.bewertung == -1 ?
                            <IconNoSelected fontSize="large" style={{ color: '#e72512' }} />
                            :
                            <IconNo fontSize="large" style={{ color: '#e72512' }} />
                        }
                    </Tooltip>
                </IconButton>
                <IconButton onClick={bttnEnthaltungClick}>
                    <Tooltip title="Enthaltung">
                        {(bewertung.bewertung == 0 || !bewertung.bewertung) ?
                            <IconEnthaltungSelected fontSize="large" style={{ color: '#ffd021' }} />
                            :
                            <IconEnthaltung fontSize="large" style={{ color: '#ffd021' }} />
                        }
                    </Tooltip>
                </IconButton>
                <IconButton onClick={bttnYesClick}>
                    <Tooltip title="soll ins Programm">
                        {bewertung.bewertung == 1 ?
                            <IconYesSelected fontSize="large" style={{ color: '#25e712' }} />
                            :
                            <IconYes fontSize="large" style={{ color: '#25e712' }} />
                        }
                    </Tooltip>
                </IconButton>
            </ButtonGroup>

            {props.bemerkungVisible &&
                <TextField
                    fullWidth
                    label="Meine Bemerkung"
                    variant="outlined"
                    style={{ marginTop: '65px' }}
                    InputProps={{
                        endAdornment: <InputAdornment position="start">
                            <IconButton onClick={bttnSaveClick}><Save /></IconButton>
                        </InputAdornment>,
                    }}
                    onChange={txtBemerkungChange}
                    onBlur={txtBemerkungBlur}
                    value={bewertung.bemerkung ? bewertung.bemerkung : ""}
                />
            }

        </React.Fragment>
    );
}


const mapStateToProps = state => ({
    user: state.global.user,
    //fpt: state.global.fpt
});


export default connect(mapStateToProps)(FptBewertenButtons);