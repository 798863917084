import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom'
import { connect } from 'react-redux'

import axios from 'axios';
import AccountCircle from '@material-ui/icons/AccountCircle';
import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import Backdrop from '@material-ui/core/Backdrop';
import RbsAlertSnack from '../utils/RbsAlertSnack';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import Login from '../utils/Login';
import MainNavigation from './MainNavigation';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles';

import { setUser, resetGlobalError, logout, setLoading, setMobileOpen, startLoadTeilnehmer, setGlobalError, resetGlobalSuccess } from '../../actions/global';
import { baseURL } from '../../config/webservice';


const drawerWidth = 280;
const imageUrl = 'https://fptfiles.rbsonline.de/personen/';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height: 'calc(100% - 65px)'
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },

    },
    appBar: {
        [theme.breakpoints.up('sm')]: {
            background: '#fafafa',
            color: '#000',
            width: location.pathname.includes('/wahl') ? '100%' : `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
        background: '#333',
        color: '#fff',
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
        background: '#333',
        color: '#fff',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    pageRoot: {
        flexGrow: 1,
        height: '100%',
        padding: theme.spacing(3),
    },
}));

function FptLayout(props) {

    const history = useHistory();

    const { window } = props;
    const classes = useStyles();
    //const theme = useTheme();
    const theme = React.useMemo(
        () =>
            createMuiTheme({
                breakpoints: {
                    values: {
                        xs: 0,
                        sm: 600,
                        md: 960,
                        lg: 1280,
                        xl: 1536,
                    },
                },
                palette: {
                    type: 'light', // prefersDarkMode ? 'dark' : 'light',
                    primary: {
                        // Purple and green play nicely together.
                        main: '#fc790d',// '#fc790d', //'#0a7dd6',
                        light: '#ffaa47',
                        contrastText: '#fff',
                    },
                    secondary: {
                        // This is green.A700 as hex.
                        main: '#1976d2', //'#fc790d',
                    },
                },
            }),
        [true],
    );

    useEffect(() => {
        console.log('#### Layout useEffect!');

        configureWebservice()

        if (!props.user) {
            const storedUserString = localStorage.getItem('fptLoginUser');
            if (storedUserString) {
                const storedUser = JSON.parse(storedUserString);

                // Session expires after 1 day!
                if (isLoginNewerThanDays(storedUser, 1)) {
                    // Token beim Aufruf setzten
                    axios.defaults.headers.common = { 'Authorization': `Bearer ${storedUser.token}` }
                    // user in den Store nehmen
                    props.dispatch(setUser(storedUser));
                    // Teilnahmen laden
                    props.dispatch(startLoadTeilnehmer(storedUser))
                }
            }
        }
    }, []);

    const isLoginNewerThanDays = (user, days) => {
        const loginDate = new Date(user.lastLogin);
        var maxDate = new Date();
        maxDate.setDate(maxDate.getDate() - 1);
        //maxDate.setMinutes(maxDate.getMinutes() - days);

        return (loginDate > maxDate);
    }


    const configureWebservice = () => {
        console.log('Configuring Webservice...');

        axios.defaults.baseURL = baseURL;
        axios.defaults.headers.post['Content-Type'] = 'application/json';
        axios.defaults.headers.post['Accept'] = 'application/json';


        axios.interceptors.response.use((response) => {
            // Return a successful response back to the calling service
            return response;
        }, (error) => {
            console.log("Error-Interceptor");

            // Return any error which is not due to authentication back to the calling service
            if (error.response && (error.response.status !== 401)) {
                return new Promise((resolve, reject) => {
                    reject(error);
                });
            }
            else {
                // Logout
                props.dispatch(setGlobalError("Session expired: Bitte loggen Sie sich aus und erneut ein!"))
                console.log("Loggin out...", error);
                props.dispatch(setLoading(false));
                //props.dispatch(logout());

                return new Promise((resolve, reject) => {
                    resolve(error);
                });
            }
        });

        props.onInitialized && props.onInitialized(true);
    }

    const location = useLocation();

    //const [mobileOpen, setMobileOpen] = React.useState(false);
    const [aktuelleInfo, setAktuelleInfo] = React.useState(undefined);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const isMenuOpen = Boolean(anchorEl);


    const handleDrawerToggle = () => {
        props.dispatch(setMobileOpen(!props.mobileOpen));
    };

    const noLoginNeeded = () => {
        console.log(location.pathname);
        if (location.pathname === '/redakteur' || location.pathname === '/signup' || location.pathname.includes('/password') || location.pathname.includes('/wahl')) {
            return true;
        }

        return false;
    }

    const bttnProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const bttnLogoutClick = () => {
        props.dispatch(logout());
        setAnchorEl(undefined)
    }

    const bttnChangePasswordClick = () => {
        setAnchorEl(undefined);
        history.push('/changePassword');
    }

    const bttnProfilCLick = () => {
        setAnchorEl(undefined);
        history.push('/meineDaten');
    }

    const bttnBlockoutClick = () => {
        setAnchorEl(undefined);
        history.push('/fpt032/blockout');
    }


    const menuId = 'account-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={menuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMenuOpen}
            onClose={() => setAnchorEl(undefined)}
        >
            <MenuItem disabled>{props.user && props.user.loginname}</MenuItem>
            <Divider />
            <MenuItem onClick={bttnChangePasswordClick}>Passwort ändern</MenuItem>
            <MenuItem onClick={bttnLogoutClick}>Ausloggen</MenuItem>
            <Divider />
            <MenuItem onClick={bttnProfilCLick}>Mein Profil</MenuItem>
            {/*<MenuItem onClick={bttnBlockoutClick}>Meine Blockouts</MenuItem>*/}
        </Menu>
    );

    const bttnErrorClose = () => {
        props.dispatch(resetGlobalError());
    }


    const bttnSuccessClose = () => {
        props.dispatch(resetGlobalSuccess());
    }

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <ThemeProvider theme={theme}>

            <div className={classes.root}>
                <CssBaseline />
                <AppBar position="fixed" className={classes.appBar} elevation={1}>
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            className={classes.menuButton}
                        >
                            <MenuIcon />
                        </IconButton>
                        <div style={{ flexGrow: 1, marginTop: '5px' }}>
                            <Typography variant="h6" gutterBottom>
                                {props.pageTitle}
                            </Typography>
                        </div>
                        <div>
                            <IconButton
                                edge="end"
                                aria-label="account of current user"
                                aria-controls={menuId}
                                aria-haspopup="true"
                                onClick={bttnProfileMenuOpen}
                                color="inherit"
                                disabled={!props.user}
                            >
                                <Avatar src={props.user && props.user.bild && imageUrl + props.user.bild} />
                            </IconButton>


                        </div>
                    </Toolbar>
                </AppBar>
                {renderMenu}

                {!location.pathname.includes('/wahl') &&
                    <nav className={classes.drawer} aria-label="mailbox folders">
                        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}

                        <React.Fragment>
                            <Hidden smUp implementation="css">
                                <Drawer
                                    container={container}
                                    variant="temporary"
                                    anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                                    open={props.mobileOpen}
                                    onClose={handleDrawerToggle}
                                    classes={{
                                        paper: classes.drawerPaper,
                                    }}
                                    ModalProps={{
                                        keepMounted: true, // Better open performance on mobile.
                                    }}
                                >
                                    <MainNavigation />
                                </Drawer>
                            </Hidden>
                            <Hidden xsDown implementation="css">
                                <Drawer
                                    classes={{
                                        paper: classes.drawerPaper,
                                    }}
                                    variant="permanent"
                                    open
                                >
                                    <MainNavigation />
                                </Drawer>
                            </Hidden>
                        </React.Fragment>
                    </nav>
                }

                <main style={{ width: '100%' }}>
                    <div className={classes.toolbar} />
                    {aktuelleInfo &&
                        <Box style={{ background: 'yellow', margin: '0 0 24px 0', padding: '12px', color: 'black' }}>
                            <Typography variant="subtitle1" gutterBottom>
                                {aktuelleInfo}
                            </Typography>
                        </Box>
                    }

                    {(props.user || noLoginNeeded()) ?
                        <div className={classes.pageRoot}>
                            {props.initialized || noLoginNeeded() ?
                                props.children
                                :
                                <Backdrop open={true}>
                                    <CircularProgress />
                                </Backdrop>
                            }
                        </div>
                        :
                        <Login />
                    }
                </main>

                <RbsAlertSnack
                    message={props.error && props.error.message}
                    isOpen={!!props.error ? true : false}
                    onClose={bttnErrorClose}
                />
                <RbsAlertSnack
                    autoHideDuration={1000}
                    message={props.success}
                    isOpen={!!props.success ? true : false}
                    onClose={bttnSuccessClose}
                    severity="success"
                />
            </div>

        </ThemeProvider>
    );
}

FptLayout.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};



const mapStateToProps = state => ({
    user: state.global.user,
    mobileOpen: state.global.mobileOpen,
    pageTitle: state.global.pageTitle,
    error: state.global.error,
    success: state.global.success,
    initialized: state.global.initialized ? state.global.initialized : false,
});



export default connect(mapStateToProps)(FptLayout);