import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'


import ChatItem from '../utils/ChatItem';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import SendIcon from '@material-ui/icons/Send';
import UpdateIcon from '@material-ui/icons/Update';

import { startGetChatEntries, startAddChatEntry, setReload } from '../../actions/chat';



const PageChat = (props) => {

    const [txtInput, setTxtInput] = useState('');

    const sendMessage = () => {

        const newData = {
            idChatEntry: 0,
            avatar: props.user.bild,
            sender: props.user.vorname + " " + props.user.nachname,
            datum: null,
            message: txtInput,
            hide: false
        };

        props.dispatch(startAddChatEntry(newData))
        setTxtInput('');
    }

    const bttnSendClick = () => {
        txtInput && sendMessage();
    }

    const bttnUpdateClick = () => {
        props.dispatch(startGetChatEntries());
    }

    const txtInputKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.stopPropagation();
            sendMessage();
        }
    }

    return (
        <Grid container spacing={0}>
            <Grid item xs={12} md={8} lg={6}>

                <FormControl variant="outlined" fullWidth style={{ padding: '12px' }}>
                    <Input
                        autoFocus={false}
                        value={txtInput}
                        onChange={(e) => setTxtInput(e.target.value)}
                        onKeyDown={txtInputKeyDown}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton color="primary" component="span" onClick={bttnSendClick}>
                                    <SendIcon />
                                </IconButton>
                                <IconButton color="primary" component="span" onClick={bttnUpdateClick}>
                                    <UpdateIcon />
                                </IconButton>
                            </InputAdornment>
                        }

                    />
                </FormControl>

                {props.chatEntries && props.chatEntries
                    .map(c =>
                        <ChatItem 
                            key={c.idChatEntry} 
                            avatar={c.avatar} 
                            sender={c.sender} 
                            date={c.datum} 
                            message={c.message} 
                            bodyVariant="body1"
                        />
                    )
                }
            </Grid>
        </Grid>
    )
}

const mapStateToProps = state => ({
    user: state.global.user,
    chatEntries: state.chat.chatEntries,
});

export default connect(mapStateToProps)(PageChat);
