import axios from 'axios';
import { setGlobalError, setGlobalSuccess, setLoading, startLoadTeilnehmer, startSetUserData } from './global';
import { buildErrorMessage } from '../helper/errorHelper';



const setThemenliste = (idFpt, themen) => ({
    type: 'SET_THEMEN_LISTE',
    idFpt,
    themen,
});

const setMyFirmenprofile = (idFpt, myFirmenProfile) => ({
    type: 'SET_MY_FIRMENPROFILE',
    idFpt,
    myFirmenProfile,
});

const setThema = (thema) => ({
    type: 'SET_THEMA',
    thema,
});

const setSelectedThemen = (themenSelected) => ({
    type: 'SET_SELECTED_THEMEM',
    themenSelected,
});


const setProfilFirma = (profilFirma) => ({
    type: 'SET_PROFIL_FIRMA',
    profilFirma,
});

const setProfilPersonSelected = (profilPerson) => ({
    type: 'SET_PROFIL_PERSON_SELECTED',
    profilPerson,
});

const setProfileFirmaSelected = (profileFirma) => ({
    type: 'SET_PROFILE_FIRMA_SELECTED',
    profileFirma,
});

const setTeilnehmer = (teilnehmer, idFpt) => ({
    type: 'SET_TEILNEHMER',
    teilnehmer,
    idFpt
})

const setTeilnehmerPersonen = (teilnehmerPersonen, idProfilFirma, idFpt) => ({
    type: 'SET_TEILNEHMER_PERSONEN',
    teilnehmerPersonen,
    idProfilFirma,
    idFpt
})

export const setTeilnehmerPerson = (idProfilPerson, teilnehmerPerson, idProfilFirma, idFpt) => ({
    type: 'SET_TEILNEHMER_PERSON',
    idProfilPerson,
    teilnehmerPerson,
    idProfilFirma,
    idFpt
})

const setAllePersonen = (idFpt, allePersonen) => ({
    type: 'SET_ALLE_PERSONEN',
    allePersonen,
    idFpt
})

export const setMeineThemen = (idFpt, meineThemen) => ({
    type: 'SET_MEINE_THEMEN',
    meineThemen,
    idFpt
})

const addMeineThemen = (idFpt, meineThemen) => ({
    type: 'ADD_MEINE_THEMEN',
    meineThemen,
    idFpt
})


const updateMeinThema = (idFpt, idFptThema, updateThema) => ({
    type: 'UPDATE_MEIN_THEMA',
    idFpt,
    idFptThema,
    updateThema
});

const setRedakteureTitel = (redakteureTitel, idFpt) => ({
    type: 'SET_REDAKTEURE_TITEL',
    redakteureTitel,
    idFpt,
})

const setYoutubeId = (youtubeId, idFpt) => ({
    type: 'SET_YOUTBUE_ID',
    youtubeId,
    idFpt,
});

const setAnwesenheiten = (idFpt, anwesenheiten) => ({
    type: 'SET_ANWESENHEITEN',
    idFpt,
    anwesenheiten
});

export const setThemaZipFile = (zipFile) => ({
    type: 'SET_THEMA_ZIP_FILE',
    zipFile
});


export const setThemaFilesChanged = () => ({
    type: 'SET_THEMA_FILES_CHANGED',
    themaFilesChanged: new Date(),
});

export const setSelectedProgramTab = (idFpt, selectedProgramTab) => ({
    type: 'SET_SELECTED_PROGRAM_TAB',
    idFpt,
    selectedProgramTab
});

export const startGeSomeData = () => {
    return (dispatch, getState) => {
        const service = "";
        axios.get(
            service
        ).then(res => {
            dispatch(getRbsTime(res.data));
        }).catch((error) => {
            dispatch(setGlobalError(buildErrorMessage(error)));
        });
    };
}


export const startReadThemenliste = (idFpt) => {
    return (dispatch) => {
        dispatch(setLoading(true));
        const service = `/api/Fpt/ReadThemen/${idFpt}`;

        axios.get(
            service
        ).then(res => {
            //console.log('Liste geladen', res.data);        
            dispatch(setLoading(false));
            dispatch(setThemenliste(idFpt, res.data));
        }).catch((error) => {
            dispatch(setGlobalError(buildErrorMessage(error)));
            dispatch(setLoading(false));
        });
    };
}


export const startReadProfileFirmaByUser = (idProfilPerson, idFpt, my = true, onSuccess) => {
    return (dispatch) => {
        dispatch(setLoading(true));
        const service = `/api/Fpt/ReadProfileFirmaByUser/${idProfilPerson}/${idFpt}`;

        axios.get(
            service
        ).then(res => {
            dispatch(setLoading(false));
            if (my) {
                dispatch(setMyFirmenprofile(idFpt, res.data));
            }
            else {
                dispatch(setProfileFirmaSelected(res.data));
            }
            onSuccess && onSuccess(res.data);
        }).catch((error) => {
            dispatch(setGlobalError(buildErrorMessage(error)));
            dispatch(setLoading(false));
        });
    };
}


export const startTeilnahmeRedakteurAnmelden = (user, idAuftragFpt, idFptTeilnehmerTyp, onSuccsess) => {
    return (dispatch) => {
        dispatch(setLoading(true));
        const service = `/api/Fpt/TeilnahmeRedakteurAnmelden/${user.idFptProfilPerson}/${idAuftragFpt}/${idFptTeilnehmerTyp}/${user.loginname}`;

        axios.put(
            service
        ).then(res => {
            dispatch(setLoading(false));
            dispatch(startLoadTeilnehmer(user))
            onSuccsess && onSuccsess();
        }).catch((error) => {
            dispatch(setGlobalError(buildErrorMessage(error)));
            dispatch(setLoading(false));
        });
    };
}

export const startReadThema = (idFptThema) => {
    return (dispatch) => {
        console.log("Read Thema", idFptThema);
        dispatch(setThema(undefined));
        dispatch(setLoading(true));
        const service = `/api/Fpt/ReadThema/${idFptThema}`;

        axios.get(
            service
        ).then(res => {
            console.log("Read Thema done", idFptThema);
            dispatch(setLoading(false));
            dispatch(setThema(res.data));
        }).catch((error) => {
            dispatch(setGlobalError(buildErrorMessage(error)));
        });
    };
}

export const startReadFotos = (idFptThema, onSuccess) => {
    return (dispatch) => {
      
        dispatch(setLoading(true));

        const tag = 'idfptthema' + idFptThema
        const service = `/api/File/GetRbsFilelistByTag/2/${tag}`;

        axios.get(
            service
        ).then(res => {
            dispatch(setLoading(false));
            onSuccess && onSuccess(res.data);
        }).catch((error) => {
            dispatch(setGlobalError(buildErrorMessage(error)));
        });
    };
}


export const startReadFile = (id, onSuccess) => {
    return (dispatch) => {
      
        dispatch(setLoading(true));
        const service = `/api/File/GetRbsFile/2/${id}`;

        axios.get(
            service
        ).then(res => {
            dispatch(setLoading(false));
            onSuccess && onSuccess(res.data);
        }).catch((error) => {
            dispatch(setGlobalError(buildErrorMessage(error)));
        });
    };
}


export const startReadThemenByFirmen = (idProfilFirmen, idFpt) => {
    return (dispatch) => {
        dispatch(setLoading(true));
        const service = `/api/Fpt/ReadThemenByFirmen/${idFpt}`;

        axios.post(
            service, idProfilFirmen
        ).then(res => {
            dispatch(setLoading(false));
            dispatch(setSelectedThemen(res.data));
        }).catch((error) => {
            dispatch(setGlobalError(buildErrorMessage(error)));
            dispatch(setLoading(false));
        });
    };
}



export const startReadProfilFirma = (idProfilFirma) => {
    return (dispatch) => {
        dispatch(setProfilFirma({}));
        dispatch(setLoading(true));
        const service = `/api/Fpt/ReadProfilFirma/${idProfilFirma}`;

        axios.get(
            service
        ).then(res => {
            dispatch(setLoading(false));
            dispatch(setProfilFirma(res.data));
        }).catch((error) => {
            dispatch(setGlobalError(buildErrorMessage(error)));
        });
    };
}

export const startReadProfilPerson = (idProfilPerson, onSuccess) => {
    return (dispatch) => {
        dispatch(setProfilFirma({}));
        dispatch(setLoading(true));
        const service = `/api/Fpt/ReadProfilPersonById/${idProfilPerson}`;

        axios.get(
            service
        ).then(res => {
            dispatch(setLoading(false));
            dispatch(setProfilPersonSelected(res.data));
            onSuccess && onSuccess(res.data);
        }).catch((error) => {
            dispatch(setGlobalError(buildErrorMessage(error)));
        });
    };
}


export const startReadTeilnehmer = (idAuftragFpt, typ) => {
    return (dispatch) => {

        dispatch(setLoading(true));
        const service = `/api/Fpt/ReadTeilnehmer/${idAuftragFpt}/${typ}`;

        axios.get(
            service
        ).then(res => {
            dispatch(setLoading(false));
            dispatch(setTeilnehmer(res.data, idAuftragFpt));
        }).catch((error) => {
            dispatch(setGlobalError(buildErrorMessage(error)));
        });
    };
}

export const startReadTeilnehmerPersonen = (idProfilFirma, idAuftragFpt) => {
    return (dispatch) => {
        //dispatch(setTeilnehmerPersonen([]));
        dispatch(setLoading(true));
        const service = `/api/Fpt/ReadProfilePersonenByFirma/${idProfilFirma}/${idAuftragFpt}`;

        axios.get(
            service
        ).then(res => {
            dispatch(setLoading(false));
            dispatch(setTeilnehmerPersonen(res.data, idProfilFirma, idAuftragFpt));
        }).catch((error) => {
            dispatch(setGlobalError(buildErrorMessage(error)));
        });
    };
}

export const startReadAllePersonen = (idAuftragFpt, jc = false, onSuccess) => {
    return (dispatch) => {
        dispatch(setAllePersonen(idAuftragFpt, []));
        dispatch(setLoading(true));
        const service = `/api/Fpt/ReadTelnehmerPersonen/${idAuftragFpt}/${true}/${true}/${jc}`;

        axios.get(
            service
        ).then(res => {
            dispatch(setLoading(false));
            dispatch(setAllePersonen(idAuftragFpt, res.data));
            onSuccess && onSuccess(res.data);
        }).catch((error) => {
            dispatch(setGlobalError(buildErrorMessage(error)));
        });
    };
}


export const startReadMeineThemen = (idProfilFirma, idAuftragFpt) => {
    return (dispatch) => {
        dispatch(setLoading(true));
        const service = `/api/Fpt/ReadMeineThemen/${idProfilFirma}/${idAuftragFpt}`;

        axios.get(
            service
        ).then(res => {
            dispatch(setLoading(false));
            console.log("Add Themen: ", res.data);
            dispatch(addMeineThemen(idAuftragFpt, res.data));
        }).catch((error) => {
            dispatch(setGlobalError(buildErrorMessage(error)));
        });
    };
}

export const startUpdateThema = (idAuftragFpt, fptThema) => {
    return (dispatch) => {
        dispatch(setLoading(true));
        const service = `/api/Fpt/UpdateThema/`;

        axios.put(
            service, fptThema
        ).then(res => {
            dispatch(setLoading(false));
            dispatch(updateMeinThema(idAuftragFpt, fptThema.idFptThema, res.data));
        }).catch((error) => {
            dispatch(setGlobalError(buildErrorMessage(error)));
        });
    };
}


export const startSaveProfilPerson = (profilPerson, idProfilFirma = undefined, idFpt = undefined) => {
    return (dispatch, getState) => {

        const service = `/api/Fpt/UpdateProfilPerson`;
        axios.put(
            service, profilPerson
        ).then(res => {
            if (idProfilFirma && idFpt) {
                console.log("Set Teilnehmer", idProfilFirma, idFpt);
                dispatch(setTeilnehmerPerson(profilPerson.idFptProfilPerson, profilPerson, idProfilFirma, idFpt))
            }
            else {
                dispatch(startSetUserData(res.data));
            }

        }).catch((error) => {
            dispatch(setGlobalError(buildErrorMessage(error)));
        });
    };
}



export const startGetRedakteureTitel = (idFpt, jc = false) => {
    return (dispatch, getState) => {

        const service = `/api/Fpt/GetRedakteureFirma/${idFpt}/${jc}`;
        axios.get(
            service
        ).then(res => {
            dispatch(setRedakteureTitel(res.data, idFpt));
        }).catch((error) => {
            dispatch(setGlobalError(buildErrorMessage(error)));
        });
    };
}

export const startGetYoutubeId = (idFpt) => {
    return (dispatch, getState) => {

        const service = `/api/Fpt/GetYoutubeId/${idFpt}`;
        axios.get(
            service
        ).then(res => {
            dispatch(setYoutubeId(res.data, idFpt));
        }).catch((error) => {
            //console.log(error.response.data.error);
            dispatch(setGlobalError(error.response.data.error));
        });
    };
}

export const startGetAnwesenheiten = (minutes) => {
    return (dispatch, getState) => {

        const service = `/api/Fpt/GetAnwesenheiten/${minutes}`;
        axios.get(
            service
        ).then(res => {
            console.log(res);
            dispatch(setAnwesenheiten(res.data));
        }).catch((error) => {
            //console.log(error.response.data.error);
            dispatch(setGlobalError(error.response.data.error));
        });
    };
}

//#region 


export const setMeineBewertungen = (idFpt, bewertungen) => ({
    type: 'SET_BEWERTUNGEN',
    idFpt,
    bewertungen
});


export const setMeineBewertung = (idFpt, idFptThema, bewertung) => ({
    type: 'SET_BEWERTUNG',
    idFpt,
    idFptThema,
    bewertung
});

export const setMeineAnwesenheiten = (idFpt, anwesenheiten) => ({
    type: 'SET_MEINE_ANWESENHEITEN',
    idFpt,
    anwesenheiten
});

export const setSelectedAnwesenheiten = (anwesenheitenSelected) => ({
    type: 'SET_SELECTED_ANWESENHEITEN',
    anwesenheitenSelected
});



export const addMeineAnwesenheiten = (idFpt, anwesenheiten) => ({
    type: 'ADD_MEINE_ANWESENHEITEN',
    idFpt,
    anwesenheiten
});

export const setMeineAnwesenheit = (idFpt, anwesenheit) => ({
    type: 'SET_MEINE_ANWESENHEIT',
    idFpt,
    anwesenheit
});

export const setProgramm = (idFpt, tag, programm) => ({
    type: 'SET_PROGAMM',
    idFpt,
    tag,
    programm
})

export const startReadMeineBewertungen = (idProfilPerson, idFpt) => {
    return (dispatch) => {
        dispatch(setLoading(true));
        const service = `/api/Fpt/ReadMyBewertungen/${idProfilPerson}/${idFpt}`;

        axios.get(
            service
        ).then(res => {
            dispatch(setLoading(false));
            dispatch(setMeineBewertungen(idFpt, res.data));
        }).catch((error) => {
            dispatch(setGlobalError(buildErrorMessage(error)));
            dispatch(setLoading(false));
        });
    };
}

export const startUpdateBewertung = (idFpt, idThema, idProfilBewerter, valueBewertung, bemerkung) => {
    return (dispatch) => {
        //dispatch(setSavingBewertung(idFpt, idThema, "wird gespeichert..."));
        const service = `/api/Fpt/UpdateBewertung/${idThema}/${idProfilBewerter}/${valueBewertung}/${bemerkung}`;

        axios.put(
            service
        ).then(res => {
            dispatch(setMeineBewertung(idFpt, idThema, res.data));
            //dispatch(setSavingBewertung(idFpt, idThema, false));
        }).catch((error) => {
            dispatch(setGlobalError(buildErrorMessage(error)));
            //dispatch(setSavingBewertung(idFpt, idThema, false));
        });
    };
}

/*
export const startGetAnwesenheitenByFirmaFpt = (idProfilFirma, idFpt) => {
    return (dispatch) => {
        const service = `/api/Fpt/GetAnwesenheitenByFirmaFpt/${idProfilFirma}/${idFpt}`;

        axios.get(
            service
        ).then(res => { 
            dispatch(addMeineAnwesenheiten(idFpt, res.data));
        }).catch((error) => {
            dispatch(setGlobalError(buildErrorMessage(error)));
        });
    };
}*/

export const startGetAnwesenheitenByPersonFpt = (idProfilPerson, idFpt, my=true) => {
    return (dispatch) => {
        const service = `/api/Fpt/GetAnwesenheitenByPersonFpt/${idProfilPerson}/${idFpt}`;

        axios.get(
            service
        ).then(res => {
            if(my){
                dispatch(setMeineAnwesenheiten(idFpt, res.data));
            }
            else {
                console.log("setSelectedAnwesenheiten", res.data)
                dispatch(setSelectedAnwesenheiten(res.data));
            }
        }).catch((error) => {
            dispatch(setGlobalError(buildErrorMessage(error)));
        });
    };
}


export const updateAnwesenheit = (idFpt, anwesenheit) => {
    return (dispatch) => {
        const service = `/api/Fpt/UpdateAnwesenheit/`;

        axios.put(
            service, anwesenheit
        ).then(res => {
            dispatch(setMeineAnwesenheit(idFpt, res.data));
            dispatch(setGlobalSuccess("Daten wurden gespeichert!"));
        }).catch((error) => {
            dispatch(setGlobalError(buildErrorMessage(error)));
        });
    };
}

export const startGetAnwesenheitenFpt = (idFpt) => {
    return (dispatch) => {
        const service = `/api/Fpt/GetAnwesenheitenExtByFpt/${idFpt}`;

        axios.get(
            service
        ).then(res => {
            dispatch(setAnwesenheiten(idFpt, res.data));
        }).catch((error) => {
            dispatch(setGlobalError(buildErrorMessage(error)));
        });
    };
}


export const startReadProgram = (idFpt, tag, xmlFile) => {
    return (dispatch) => {
        const service = `/api/Fpt/ReadProgram/${xmlFile}`;

        axios.get(
            service
        ).then(res => {
            dispatch(setProgramm(idFpt, tag, res.data));
        }).catch((error) => {
            dispatch(setGlobalError(buildErrorMessage(error)));
        });
    };
}


export const readVortrag = (idVortrag, onSuccess) => {
    return (dispatch) => {
        const service = `/api/Fpt/ReadVortrag/${idVortrag}`;

        axios.get(
            service
        ).then(res => {
            onSuccess(res.data);
        }).catch((error) => {
            dispatch(setGlobalError(buildErrorMessage(error)));
        });
    };
}




export const setSavingBewertung = (idFpt, idFptThema, saving) => ({
    type: 'SET_SAVING_BEWERTUNG',
    saving,
    idFpt,
    idFptThema
});


export const startReadBewertungErgebnis = (idFpt) => {
    return (dispatch) => {
        const service = `/api/Fpt/ReadBewertungErgebnis/${idFpt}`;

        axios.get(
            service
        ).then(res => {
            console.log("Set Ergebnis", res.data);
            dispatch(setBewertungErgebnis(idFpt, res.data))
        }).catch((error) => {
            dispatch(setGlobalError(buildErrorMessage(error)));
        });
    };
}


export const setBewertungErgebnis = (idFpt, wahlergebnis) => ({
    type: 'SET_BEWERTUNG_ERGEBNIS',
    idFpt,
    wahlergebnis
});


export const startGetThemaFiles = (idFpt, idThema, onSuccess) => {
    return (dispatch) => {
        const service = `/api/Fpt/GetThemaFiles/sharepoint/${idFpt}/${idThema}`;
        //dispatch(setThemaFiles, undefined);

        axios.get(
            service
        ).then(res => {
            //dispatch(setThemaFiles(idFpt, idThema, res.data))
            onSuccess && onSuccess(res.data);
        }).catch((error) => {
            dispatch(setGlobalError(buildErrorMessage(error)));
        });
    };
}

export const setThemaFiles = (idFpt, idFptThema, themaFiles) => ({
    type: 'SET_THEMA_FILES',
    idFpt,
    idFptThema,
    themaFiles
});

export const setZipFileGenerating = (idFptThema, generating) => ({
    type: 'SET_ZIP_FILE_GENERATING',
    idFptThema,
    generating
});


export const removeThemaFile = (idFptThema, id) => ({
    type: 'REMOVE_THEMA_FILE',
    idFptThema,
    id
});


export const getThumbnail = (idFile, callbackSuccess) => {
    return (dispatch) => {

        const service = `/api/Fpt/GetThumbnail/sharepoint/${idFile}`;
        axios.get(
            service
        ).then(res => {
            callbackSuccess && callbackSuccess(res.data);
        }).catch((error) => {
            // Der ist manchaml zu schnell und lädt das schon geöschte Bild. Dann kommt ne Exception, die mich aber net bockt
            //dispatch(setGlobalWarning('Thumbnail konnte nicht geladen werden: ' + error.message));            
        });
    };
}


export const strtRemoveThemaFile = (idFptThema, idFile, callbackSuccess) => {
    return (dispatch) => {

        const service = `/api/Fpt/RemoveThemaFile/sharepoint/${idFile}`;
        axios.delete(
            service
        ).then(res => {
            console.log("removed");
            //dispatch(removeThemaFile(idFptThema, idFile));
            callbackSuccess && callbackSuccess(res.data);

        }).catch((error) => {
            dispatch(setGlobalWarning('File konnte nicht gelöscht werden: ' + error.message));            
        });
    };
}


export const startBuildZip = (idFpt, idThema, onSuccess) => {
    return (dispatch) => {
        //dispatch(setThemaZipFile({loading: true}));
        dispatch(setZipFileGenerating(idThema, true))

        const service = `/api/Fpt/DownloadZip/sharepoint/${idFpt}/${idThema}`;
        axios.get(
            service
        ).then(res => {
            console.log("zipfile", res.data);
            dispatch(setZipFileGenerating(idThema, false))
            onSuccess && onSuccess(res.data);

        }).catch((error) => {
            //dispatch(setThemaZipFile({loading: false}));
            dispatch(setGlobalWarning('Zip-File konnte nicht generiert werden: ' + error.message));            
        });
    };
}

//#endregion



