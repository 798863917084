import React, { useState } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import PageStart from '../components/fpt/PageStart';
import FptLayout from '../components/layout/FptLayout';
import NotFoundPage from '../components/NotFoundPage';
import PageStream from '../components/fpt/PageStream';

import PageChangePassword from '../components/system/PageChangePassword';
import PageMeineDaten from '../components/fpt/PageMeineDaten';
import PageResetPassword from '../components/system/PageResetPassword';
import PageSignUp from '../components/system/PageSignUp';
import PageAkkreditieren from '../components/system/PageAkkreditieren';
import PageThemaFpl001 from '../components/fpt/fpl001/PageThema';

import PageThemaFpt031 from '../components/fpt/fpt031/PageThema';
import PageThemenFpt031 from '../components/fpt/fpt031/PageThemenFpt031';

import PageBlockout from '../components/fpt/fpt032/PageBlockout';
import PageEinladen from '../components/fpt/fpt032/PageEinladen';
import PageThemaFpt032 from '../components/fpt/fpt032/PageThema';
import PageThemenFpt032 from '../components/fpt/fpt032/PageThemenFpt032';
import PageTermineFpt032 from '../components/fpt/fpt032/PageTermineFpt032';
import PageTerminFpt032 from '../components/fpt/fpt032/PageTerminFpt032';
import PageTeilnehmerFpt032 from '../components/fpt/fpt032/PageTeilnehmerFpt032';
import PageMeineThemen032 from '../components/fpt/fpt032/PageMeineThemen032';
import PageRedaktionen032 from '../components/fpt/fpt032/PageRedaktionen032';
import PageProfil032 from '../components/fpt/fpt032/PageProfil';

import PageMeineThemenFpt033 from '../components/fpt/fpt033/PageMeineThemen';
import PageProfilFpt033 from '../components/fpt/fpt033/PageProfil';
import PageTeilnehmerFpt033 from '../components/fpt/fpt033/PageTeilnehmer';
import PageThemenFpt033 from '../components/fpt/fpt033/PageThemen';
import PageThemaFpt033 from '../components/fpt/fpt033/PageThema';
import PageTermineFpt033 from '../components/fpt/fpt033/PageTermine';
import PageRedaktionenFpt033 from '../components/fpt/fpt033/PageRedaktionen';
import PageBewertungFpt033 from '../components/fpt/fpt033/PageBewertung';
import PageBewertungThemaFpt033 from '../components/fpt/fpt033/PageBewertungThema';
import PageWerWannFpt033 from '../components/fpt/fpt033/PageWerWann';
import PageProgrammFpt033 from '../components/fpt/fpt033/PageProgramm';
import PagePressegespraechFpt033 from '../components/fpt/fpt033/PagePressegespraech';

import PageMeineThemenFpt034 from '../components/fpt/fpt034/PageMeineThemen';
import PageProfilFpt034 from '../components/fpt/fpt034/PageProfil';
import PageTeilnehmerFpt034 from '../components/fpt/fpt034/PageTeilnehmer';
import PageThemenFpt034 from '../components/fpt/fpt034/PageThemen';
import PageThemaFpt034 from '../components/fpt/fpt034/PageThema';
import PageBewertungFpt034 from '../components/fpt/fpt034/PageBewertung';
import PageBewertungThemaFpt034 from '../components/fpt/fpt034/PageBewertungThema';
import PageWahlergebnis034 from '../components/fpt/fpt034/PageWahlergebnis';
import PageWerWannFpt034 from '../components/fpt/fpt034/PageWerWann';
import PagePressegespraechFpt034 from '../components/fpt/fpt034/PagePressegespraech';
import PageProgrammFpt034 from '../components/fpt/fpt034/PageProgramm';
import PageRedaktionenFpt034 from '../components/fpt/fpt034/PageRedaktionen';

import PageMeineThemenFpt035 from '../components/fpt/fpt035/PageMeineThemen';
import PageProfilFpt035 from '../components/fpt/fpt035/PageProfil';
import PageTeilnehmerFpt035 from '../components/fpt/fpt035/PageTeilnehmer';
import PageThemenFpt035 from '../components/fpt/fpt035/PageThemen';
import PageThemaFpt035 from '../components/fpt/fpt035/PageThema';
import PageBewertungFpt035 from '../components/fpt/fpt035/PageBewertung';
import PageBewertungThemaFpt035 from '../components/fpt/fpt035/PageBewertungThema';
import PageWahlergebnis035 from '../components/fpt/fpt035/PageWahlergebnis';
import PageWerWannFpt035 from '../components/fpt/fpt035/PageWerWann';
import PageProgrammFpt035 from '../components/fpt/fpt035/PageProgramm';
import PageRedaktionenFpt035 from '../components/fpt/fpt035/PageRedaktionen';
import PagePressegespraechFpt035 from '../components/fpt/fpt035/PagePressegespraech';
import ListeMailFpt035 from '../components/fpt/fpt035/ListeMail';

import PageMeineThemenFpt036 from '../components/fpt/fpt036/PageMeineThemen';
import PageProfilFpt036 from '../components/fpt/fpt036/PageProfil';
import PageTeilnehmerFpt036 from '../components/fpt/fpt036/PageTeilnehmer';
import PageThemenFpt036 from '../components/fpt/fpt036/PageThemen';
import PageThemaFpt036 from '../components/fpt/fpt036/PageThema';
import PageBewertungFpt036 from '../components/fpt/fpt036/PageBewertung';
import PageBewertungThemaFpt036 from '../components/fpt/fpt036/PageBewertungThema';
import PageWahlergebnis036 from '../components/fpt/fpt036/PageWahlergebnis';
import PageWerWannFpt036 from '../components/fpt/fpt036/PageWerWann';
import PageProgrammFpt036 from '../components/fpt/fpt036/PageProgramm';
import PageRedaktionenFpt036 from '../components/fpt/fpt036/PageRedaktionen';
import PagePressegespraechFpt036 from '../components/fpt/fpt036/PagePressegespraech';
import ListeMailFpt036 from '../components/fpt/fpt036/ListeMail';

import PageLive from '../components/fpt/fpt032/PageLive';
import PageLiveFpt033 from '../components/fpt/fpt033/PageLive';
import PageLiveFpt034 from '../components/fpt/fpt034/PageLive';
import PageLiveFpt035 from '../components/fpt/fpt035/PageLive';
import PageLiveFpt036 from '../components/fpt/fpt036/PageLive';

import PageChat from '../components/system/PageChat';
import PagePing from '../components/system/PagePing';
import PageWahl from '../components/fpt/PageWahl';
import PageRbsThema from '../components/fpt/PageRbsThema';
import PageRedakteur from '../components/fpt/PageRedakteur';



const AppRouter = () => {

	const [initialized, setInitialized] = useState(false);

	//const location = useLocation();

	//console.log('location', location);

	return (

		<BrowserRouter>

			{initialized && <Route path="/stream" component={PageStream} exact={true} />}

			<FptLayout onInitialized={(success) => setInitialized(success)}>

				{initialized &&
					<Switch>
						<Route path="/" component={PageStart} exact={true} />
						<Route path="/wahl/:id" component={PageWahl} exact={true} />
						<Route path="/password/:username?" component={PageResetPassword} exact />
						<Route path="/changePassword" component={PageChangePassword} exact={true} />
						<Route path="/signup" component={PageSignUp} exact={true} />
						<Route path="/redakteur" component={PageRedakteur} exact={true} />
						<Route path="/live" component={PageLiveFpt033} exact={true} />
						<Route path="/meineDaten" component={PageMeineDaten} exact={true} />
						<Route path="/rbsThema/:idFpt/:idThema" component={PageRbsThema} />


						<Route path="/fpt031/themen" component={PageThemenFpt031} exact={true} />
						<Route path="/fpt031/thema/:idThema" component={PageThemaFpt031} />

						<Route path="/fpl001/thema/:idThema" component={PageThemaFpl001} />

						<Route path="/fpt032/teilnehmer" component={PageTeilnehmerFpt032} exact={true} />
						<Route path="/fpt032/themen" component={PageThemenFpt032} exact={true} />
						<Route path="/fpt032/termine" component={PageTermineFpt032} exact={true} />
						<Route path="/fpt032/termin/:idMeeting" component={PageTerminFpt032} />
						<Route path="/fpt032/thema/:idThema" component={PageThemaFpt032} />
						<Route path="/fpt032/einladen/:idPerson/:idFpt/:isJournalist/:isErsatzTermin" component={PageEinladen} />
						<Route path="/fpt032/einladen/:idFptMeeting" component={PageEinladen} />
						<Route path="/fpt032/blockout" component={PageBlockout} />
						<Route path="/fpt032/meineThemen" component={PageMeineThemen032} />
						<Route path="/fpt032/redaktionen" component={PageRedaktionen032} />
						<Route path="/fpt032/profil/:idPerson" component={PageProfil032} />

						<Route path="/fpt033/meineThemen" component={PageMeineThemenFpt033} />
						<Route path="/fpt033/teilnehmer" component={PageTeilnehmerFpt033} exact={true} />
						<Route path="/fpt033/themen" component={PageThemenFpt033} exact={true} />
						<Route path="/fpt033/thema/:idThema" component={PageThemaFpt033} exact={true} />
						<Route path="/fpt033/termine" component={PageTermineFpt033} exact={true} />
						<Route path="/fpt033/redaktionen" component={PageRedaktionenFpt033} />
						<Route path="/fpt033/profil/:idPerson" component={PageProfilFpt033} />
						<Route path="/fpt033/bewertung/" component={PageBewertungFpt033} />
						<Route path="/fpt033/bewertungThema/:idThema" component={PageBewertungThemaFpt033} />
						<Route path="/fpt033/werWann/" component={PageWerWannFpt033} />
						<Route path="/fpt033/programm/" component={PageProgrammFpt033} />
						<Route path="/fpt033/pressegespraech" component={PagePressegespraechFpt033} />
						<Route path="/fpt033/live" component={PageLiveFpt033} />

						<Route path="/fpt034/meineThemen" component={PageMeineThemenFpt034} />
						<Route path="/fpt034/teilnehmer" component={PageTeilnehmerFpt034} exact={true} />
						<Route path="/fpt034/themen" component={PageThemenFpt034} exact={true} />
						<Route path="/fpt034/thema/:idThema" component={PageThemaFpt034} exact={true} />
						<Route path="/fpt034/profil/:idPerson" component={PageProfilFpt034} />
						<Route path="/fpt034/bewertung/" component={PageBewertungFpt034} />
						<Route path="/fpt034/bewertungThema/:idThema" component={PageBewertungThemaFpt034} />
						<Route path="/fpt034/ergebnis" component={PageWahlergebnis034} />
						<Route path="/fpt034/pressegespraech" component={PagePressegespraechFpt034} />
						<Route path="/fpt034/werWann/" component={PageWerWannFpt034} />
						<Route path="/fpt034/programm/" component={PageProgrammFpt034} />
						<Route path="/fpt034/redaktionen" component={PageRedaktionenFpt034} />
						<Route path="/fpt034/live" component={PageLiveFpt034} />

						<Route path="/fpt035/meineThemen" component={PageMeineThemenFpt035} />
						<Route path="/fpt035/teilnehmer" component={PageTeilnehmerFpt035} exact={true} />
						<Route path="/fpt035/themen" component={PageThemenFpt035} exact={true} />
						<Route path="/fpt035/thema/:idThema" component={PageThemaFpt035} exact={true} />
						<Route path="/fpt035/profil/:idPerson" component={PageProfilFpt035} />
						<Route path="/fpt035/bewertung/" component={PageBewertungFpt035} />
						<Route path="/fpt035/bewertungThema/:idThema" component={PageBewertungThemaFpt035} />
						<Route path="/fpt035/ergebnis" component={PageWahlergebnis035} />
						<Route path="/fpt035/pressegespraech" component={PagePressegespraechFpt035} />
						<Route path="/fpt035/werWann/" component={PageWerWannFpt035} />
						<Route path="/fpt035/programm/" component={PageProgrammFpt035} />
						<Route path="/fpt035/redaktionen" component={PageRedaktionenFpt035} />
						<Route path="/fpt035/live" component={PageLiveFpt035} />
						<Route path="/fpt035/listeMail" component={ListeMailFpt035} />
						
						<Route path="/fpt036/meineThemen" component={PageMeineThemenFpt036} />
						<Route path="/fpt036/teilnehmer" component={PageTeilnehmerFpt036} exact={true} />
						<Route path="/fpt036/themen" component={PageThemenFpt036} exact={true} />
						<Route path="/fpt036/thema/:idThema" component={PageThemaFpt036} exact={true} />
						<Route path="/fpt036/profil/:idPerson" component={PageProfilFpt036} />
						<Route path="/fpt036/bewertung/" component={PageBewertungFpt036} />
						<Route path="/fpt036/bewertungThema/:idThema" component={PageBewertungThemaFpt036} />
						<Route path="/fpt036/ergebnis" component={PageWahlergebnis036} />
						<Route path="/fpt036/pressegespraech" component={PagePressegespraechFpt036} />
						<Route path="/fpt036/werWann/" component={PageWerWannFpt036} />
						<Route path="/fpt036/programm/" component={PageProgrammFpt036} />
						<Route path="/fpt036/redaktionen" component={PageRedaktionenFpt036} />
						<Route path="/fpt036/live" component={PageLiveFpt036} />
						<Route path="/fpt036/listeMail" component={ListeMailFpt036} />

						<Route path="/admin/chat" component={PageChat} />
						<Route path="/admin/ping" component={PagePing} />

						<Route component={NotFoundPage} />
					</Switch>
				}

			</FptLayout>

		</BrowserRouter>

	);
}

export default AppRouter;