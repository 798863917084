import React from 'react';

import FlickrLightbox from 'react-flickr-lightbox'
import FptCard from './FptCard';


/**
 * Zeigt eine Themenseite an
 * 
 * @param {object} props Component props
 * @param {object} props.firma (required)
 */
const FptFotosCard = (props) => {

   
    return (
        <FptCard title="Fotos">
            { props.thema &&
                <FlickrLightbox api_key='e117905dbf4b792551c6d094ddd8fc41' user_id='140184022@N05' searchTerm={'idfptthema' + props.thema.idFptThema} limit={20} />
            }
        </FptCard>
    )

}


export default FptFotosCard