const fptReducerDefaultState = {
    error: false,
    themaFiles: {},
    zipFilesGenerating: [-1],
    zipFilesObsolete: [-1],
    fpt030: {
        allePersonen: [],
        firmen: []
    },
    fpt031: {
        allePersonen: [],
        firmen: [],
    },
    fpl001: {
        allePersonen: [],
        firmen: [],
    },
    fpt032: {
        allePersonen: [],
        firmen: [],
    },
    fpt033: {
        allePersonen: [],
        firmen: [],
        anwesenheiten: [],
        meineAnwesenheiten: [],
    },
    fpt034: {
        allePersonen: [],
        firmen: [],
        anwesenheiten: [],
        meineAnwesenheiten: [],
        wahlergebnis: []
    },
    fpt035: {
        allePersonen: [],
        firmen: [],
        anwesenheiten: [],
        meineAnwesenheiten: [],
        wahlergebnis: [],
        selectedProgramTab: 0,
    },
    fpt036: {
        allePersonen: [],
        firmen: [],
        anwesenheiten: [],
        meineAnwesenheiten: [],
        wahlergebnis: [],
        selectedProgramTab: 0,
    },
};

const fptReducer = (state = fptReducerDefaultState, action) => {
    switch (action.type) {

        case 'SET_THEMEN_LISTE':
            {
                return {
                    ...state,
                    [action.idFpt]: {
                        ...state[action.idFpt],
                        themen: action.themen
                    }
                }
            }
        case 'SET_ZIP_FILE_GENERATING':
            {
                return {
                    ...state,
                    zipFilesGenerating: action.generating ?
                        [
                            ...state.zipFilesGenerating,
                            action.idFptThema
                        ]
                        :
                        state.zipFilesGenerating.filter(id => id !== action.idFptThema)
                }
            }
        case 'SET_MY_FIRMENPROFILE':
            {
                return {
                    ...state,
                    [action.idFpt]: {
                        ...state[action.idFpt],
                        myFirmenProfile: action.myFirmenProfile
                    }
                }
            }
        case 'SET_SELECTED_THEMEM':
            {
                return {
                    ...state,
                    themenSelected: action.themenSelected
                }
            }
        case 'SET_THEMA':
            {
                return {
                    ...state,
                    thema: action.thema
                }
            }
        case 'SET_THEMA_ZIP_FILE':
            {
                return {
                    ...state,
                    themaZipfile: action.zipFile
                }
            }
        /*
    case 'SET_THEMA_FILES':
        {
            return {
                ...state,
                themaFiles: {
                    ...state.themaFiles,
                    [action.idFptThema]: action.themaFiles
                }
            }
        }*/
        case 'SET_THEMA_FILES_CHANGED':
            {
                return {
                    ...state,
                    themaFilesChanged: action.themaFilesChanged
                }
            }
        case 'REMOVE_THEMA_FILE':
            {
                return {
                    ...state,
                    themaFiles: {
                        ...state.themaFiles,
                        [action.idFptThema]: state.themaFiles[action.idFptThema].filter(file => file.id !== action.id)
                    }
                }
            }
        case 'SET_PROFIL_FIRMA':
            {
                return {
                    ...state,
                    profilFirma: action.profilFirma
                }
            }
        case 'SET_PROFIL_PERSON_SELECTED':
            {
                return {
                    ...state,
                    profilPersonSelected: action.profilPerson
                }
            }
        case 'SET_PROFILE_FIRMA_SELECTED':
            {
                return {
                    ...state,
                    profileFirmaSelected: action.profileFirma,
                }
            }
        case 'SET_TEILNEHMER':
            {
                return {
                    ...state,
                    [action.idFpt]: {
                        ...state[action.idFpt],
                        teilnehmer: action.teilnehmer
                    }
                }
            }
        case 'SET_TEILNEHMER_PERSONEN':
            {
                console.log(action.idFpt);
                return {
                    ...state,
                    [action.idFpt]: {
                        ...state[action.idFpt],
                        firmen: {
                            ...state[action.idFpt].firmen,
                            [action.idProfilFirma]: {
                                ...state[action.idFpt].firmen[action.idProfilFirma],
                                teilnehmerPersonen: action.teilnehmerPersonen,
                            }
                        }

                    }
                }
            }
        case 'SET_TEILNEHMER_PERSON':
            {
                console.log(action.idFpt);
                return {
                    ...state,
                    [action.idFpt]: {
                        ...state[action.idFpt],
                        firmen: {
                            ...state[action.idFpt].firmen,
                            [action.idProfilFirma]: {
                                ...state[action.idFpt].firmen[action.idProfilFirma],
                                teilnehmerPersonen: state[action.idFpt].firmen[action.idProfilFirma].teilnehmerPersonen.map((item) => {
                                    if (item.idFptProfilPerson === action.idProfilPerson) {
                                        return {
                                            ...item,
                                            ...action.teilnehmerPerson
                                        };
                                    } else {
                                        return item;
                                    }
                                }),
                            }
                        }

                    }
                }
            }
        case 'SET_ALLE_PERSONEN':
            {
                return {
                    ...state,
                    [action.idFpt]: {
                        ...state[action.idFpt],
                        allePersonen: action.allePersonen
                    }
                }
            }
        case 'SET_MEINE_THEMEN':
            {
                return {
                    ...state,
                    [action.idFpt]: {
                        ...state[action.idFpt],
                        meineThemen: action.meineThemen
                    }
                }
            }
        case 'ADD_MEINE_THEMEN':
            {
                return {
                    ...state,
                    [action.idFpt]: {
                        ...state[action.idFpt],
                        meineThemen: [
                            ...state[action.idFpt].meineThemen,
                            ...action.meineThemen
                        ]
                    }
                }
            }
        case 'UPDATE_MEIN_THEMA':
            {
                return {
                    ...state,
                    [action.idFpt]: {
                        ...state[action.idFpt],
                        meineThemen: state[action.idFpt].meineThemen.map((item) => {
                            if (item.idFptThema === action.idFptThema) {
                                return {
                                    ...item,
                                    ...action.updateThema
                                };
                            } else {
                                return item;
                            }
                        })
                    }
                }
            }
        case 'SET_REDAKTEURE_TITEL':
            {
                return {
                    ...state,
                    [action.idFpt]: {
                        ...state[action.idFpt],
                        redakteureTitel: action.redakteureTitel,
                    }
                }
            }
        case 'SET_YOUTBUE_ID':
            {
                return {
                    ...state,
                    [action.idFpt]: {
                        ...state[action.idFpt],
                        youtubeId: action.youtubeId,
                    }
                }
            }
        case 'SET_ANWESENHEITEN':
            {
                return {
                    ...state,
                    [action.idFpt]: {
                        ...state[action.idFpt],
                        anwesenheiten: action.anwesenheiten
                    }
                };
            }
        case 'SET_BEWERTUNGEN':
            {
                return {
                    ...state,
                    [action.idFpt]: {
                        ...state[action.idFpt],
                        meineBewertungen: action.bewertungen
                    }
                }
            }
        case 'SET_SAVING_BEWERTUNG':
            return {
                ...state,
                [action.idFpt]: {
                    ...state[action.idFpt],
                    meineBewertungen: state[action.idFpt].meineBewertungen.map((item) => {
                        if (item.idFptThema === action.idFptThema) {
                            return {
                                ...item,
                                saving: action.saving
                            };
                        } else {
                            return item;
                        }
                    })
                }
            }
        case 'SET_BEWERTUNG':
            return {
                ...state,
                [action.idFpt]: {
                    ...state[action.idFpt],
                    meineBewertungen: state[action.idFpt].meineBewertungen.map((item) => {
                        if (item.idFptThema === action.idFptThema) {
                            return action.bewertung
                        }
                        else {
                            return item
                        }
                    })
                }
            }
        case 'SET_SELECTED_ANWESENHEITEN':
            return {
                ...state,
                anwesenheitenSelected: action.anwesenheitenSelected
            }
        case 'SET_MEINE_ANWESENHEITEN':
            return {
                ...state,
                [action.idFpt]: {
                    ...state[action.idFpt],
                    meineAnwesenheiten: action.anwesenheiten
                }
            }
        case 'ADD_MEINE_ANWESENHEITEN':
            console.log(action.idFpt);
            console.log(state[action.idFpt]);
            return {
                ...state,
                [action.idFpt]: {
                    ...state[action.idFpt],
                    meineAnwesenheiten:
                        [
                            ...state[action.idFpt].meineAnwesenheiten,
                            ...action.anwesenheiten
                        ]
                }
            }
        case 'SET_MEINE_ANWESENHEIT':
            return {
                ...state,
                [action.idFpt]: {
                    ...state[action.idFpt],
                    meineAnwesenheiten: state[action.idFpt].meineAnwesenheiten.map(anw => {
                        return anw.idFptAnwesenheit === action.anwesenheit.idFptAnwesenheit ?
                            action.anwesenheit
                            :
                            anw;
                    }),
                    anwesenheiten: state[action.idFpt].anwesenheiten.map(anw => {
                        return anw.idFptAnwesenheit === action.anwesenheit.idFptAnwesenheit ?
                            {
                                ...anw,
                                ...action.anwesenheit
                            }
                            :
                            anw;
                    })
                }
            }
        case 'SET_PROGAMM':
            return {
                ...state,
                [action.idFpt]: {
                    ...state[action.idFpt],
                    programm: {
                        ...state[action.idFpt].programm,
                        [action.tag]: action.programm
                    }
                }
            }
        case 'SET_BEWERTUNG_ERGEBNIS':
            return {
                ...state,
                [action.idFpt]: {
                    ...state[action.idFpt],
                    wahlergebnis: action.wahlergebnis
                }
            }
        case 'SET_SELECTED_PROGRAM_TAB':
            return {
                ...state,
                [action.idFpt]: {
                    ...state[action.idFpt],
                    selectedProgramTab: action.selectedProgramTab
                }
            }
        default:
            return state;
    }
}



export default fptReducer;