import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'

import CircularProgress from '@material-ui/core/CircularProgress';
import FptForceAnmeldung from '../../utils/FptForceAnmeldung';
import FptHeader from '../../utils/FptHeader';
import { FptList, FptListItem } from '../../utils/FptList';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { startReadThemenliste } from '../../../actions/fpt';
import { logoUri } from '../../../config/globals';

const idFpt = 'fpt031';
const fptTitel = "Fachpressetage 2020";

const PageThemenFpt031 = (props) => {

	useEffect(() => {
		startLoadThemen();
	}, []);

	const [filter,setFilter] = useState('');

	const startLoadThemen = () => {
		props.dispatch(startReadThemenliste(idFpt));
	}

	const openThema = (id) => {
		props.history.push(`/fpt031/thema/${id}`);
	}

	return (
		<React.Fragment>
			<FptHeader title="Fachpressetage 2020" />

			<FptForceAnmeldung idFpt={idFpt} fptTitel={fptTitel}>

				<Grid container spacing={0}>
					<Grid item xs={12} lg={6}>

						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Typography variant="h4" gutterBottom>
									Alle Themen
								</Typography>
							</Grid>
						</Grid>

						<Grid container spacing={2}>
							<Grid item xs={12}>
								<TextField
									autoFocus={false}
									fullWidth                                
									label="Suche"
									variant="outlined"
									value={filter}
									onChange={(e) => setFilter(e.target.value)}
								/>
							</Grid>
						</Grid>

						<Grid container spacing={2}>
							<Grid item xs={12}>

								<FptList>
									{props.themen ?
										props.themen
											.sort((a, b) => (a.firmenname.toUpperCase() < b.firmenname.toUpperCase() ? -1 : 1))
											.filter(thema => filter ? 
												thema.firmenname.toUpperCase().includes(filter.toUpperCase()) || (thema.titel && thema.titel.toUpperCase().includes(filter.toUpperCase())) : true ) 
											.map(thema => (
												<FptListItem
													key={thema.idFptThema}
													primaryText={thema.firmenname}
													secondaryText={thema.titel}
													image={thema.logoFile && logoUri + thema.logoFile}
													tooltipRechts={thema.veranstaltung}
													iconRechts={thema.veranstaltung.includes('Kontaktmesse') ? 'group' : 'speaker_notes'}
													onClick={() => openThema(thema.idFptThema)}
												/>
											))
										:
										<CircularProgress />
									}
								</FptList>

							</Grid>
						</Grid>

					</Grid>
				</Grid>

			</FptForceAnmeldung>
		</React.Fragment>
	);

}


const mapStateToProps = state => ({
	themen: state.fpt[idFpt].themen,
});

export default connect(mapStateToProps)(PageThemenFpt031);
