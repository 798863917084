import React from 'react';
import { connect } from 'react-redux'

import { Grid, Tab, Tabs, Typography } from '@material-ui/core';
import FptForceAnmeldung from '../../utils/FptForceAnmeldung';
import FptHeader from '../../utils/FptHeader';
import FptProgrammTag from '../generic/FptProgrammTag';
import { setSelectedProgramTab } from '../../../actions/fpt';
import { config } from '../../../config/config';
import { dateToDisplay, dateToDisplayShort } from '../../../helper/dateHelper';

const idFpt = 'fpt036';
const titel = config[idFpt].titel; 
const tag1 = config[idFpt].tag1;
const tag2 = config[idFpt].tag2;

const PageProgramm = (props) => {

    //const [selectedTab, setSelectedTab] = useState(0);

    const setSelectedTab = (value) => {
        console.log('setSelectedTab', value);
        props.dispatch(setSelectedProgramTab(idFpt, value));
    }   

    return (
        <React.Fragment>
            <FptHeader title={titel} />

            <FptForceAnmeldung idFpt={idFpt} fptTitel={titel}>

                <Grid container spacing={2}>
                    <Grid item xs={12} md={10} lg={7}>

                        <Typography variant="h4" gutterBottom>
                            Programm
                        </Typography>

                        <Tabs value={props.selectedTab} onChange={(e, value) => setSelectedTab(value)}>
                            <Tab label={dateToDisplay(tag1)} value={0} />
                            <Tab label={dateToDisplay(tag2)} value={1} />
                        </Tabs>

                        {props.selectedTab == 0 && <Typography variant="subtitle1" gutterBottom>Das Programm finden Sie hier ab Ende November</Typography>}
                        {props.selectedTab == 1 && <Typography variant="subtitle1" gutterBottom>Das Programm finden Sie hier ab Ende November</Typography>}

                        {props.selectedTab == 10 && <FptProgrammTag idFpt={idFpt} tag={'tag1'} xmlFile="fpt035_tag1.xml" />}
                        {props.selectedTab == 11 && <FptProgrammTag idFpt={idFpt} tag={'tag2'} xmlFile="fpt035_tag2.xml" />}
                    </Grid>
                </Grid>



            </FptForceAnmeldung>

        </React.Fragment>
    )
}

const mapStateToProps = state => ({
    selectedTab: state.fpt[idFpt].selectedProgramTab
});

export default connect(mapStateToProps)(PageProgramm);