import React from 'react';

import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import MuiAlert from '@material-ui/lab/Alert';

/**
 * Component, das eine Info/Fehler/Alert anzeigt
 * 
 * @param {object} props Component props
 * @param {string} props.message to display
 * @param {boolean} props.isOpen
 * @param {function} props.onClose handle alert close
 */
const RbsAlert = ({message, onClose, severity="error"}) => {

    const bttnErrorClose = () => {
        onClose && onClose(true);
    }

    return (
        <MuiAlert elevation={6} variant="filled" severity={severity} style={{marginRight: 70}}
            action={
                <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={bttnErrorClose}
                >
                    <CloseIcon fontSize="inherit" />
                </IconButton>
            }
        >
            <div>
                {message}
            </div>
        </MuiAlert>
    );
}

export default RbsAlert;