import React, { useEffect } from 'react';
import { connect } from 'react-redux'


import { startGetAnwesenheitenFpt } from '../../../actions/fpt';
import FptWerWannListe from '../../utils/FptWerWannListe';

const FptWerWann = (props) => {

    useEffect(() => {
        loadAnwesenheiten();
    }, []);

    const loadAnwesenheiten = () => {
        props.dispatch(startGetAnwesenheitenFpt(props.idFpt));
    }

    const einladen = (person) => {
        history.push(`/${props.idFpt}/einladen/${person.idFptProfilPerson}/${props.idFpt}/${!person.firmenname}/false`);
    }

    
    return (
        <FptWerWannListe anwesenheiten={props.anwesenheiten && props.anwesenheiten.filter(a => !a.nurAnsprechpartner)} idFpt={props.idFpt}/>
    )
}

const mapStateToProps = (state, componentProps) => ({
    anwesenheiten: state.fpt[componentProps.idFpt].anwesenheiten
});

export default connect(mapStateToProps)(FptWerWann);