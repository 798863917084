import React, { useEffect } from 'react';
import { connect } from 'react-redux'
import { useParams } from "react-router-dom";

import Alert from '@material-ui/lab/Alert';
import FptTerminCard from '../../utils/FptTerminCard';
import { startGetMeineTermine } from '../../../actions/meeting';



const FptTermin = (props) => {

    const { idMeeting } = useParams();

    useEffect(() => {
        if (idMeeting) {
            getTermine(props.user.idFptProfilPerson);
        }
    }, [idMeeting]);


    const getTermine = (idProfil) => {
        props.dispatch(startGetMeineTermine(idProfil));
    }

    const findTermin = () => {
        return props.meineTermine.find(termin => termin.idFptMeeting === parseInt(idMeeting));
    }

    return (
        <React.Fragment>
            {props.meineTermine &&
                <div>
                    { findTermin() ?
                        <FptTerminCard meeting={findTermin()} idFpt={props.idFpt}/>
                        :
                        <Alert severity='error'>Das Meeting mit der id={idMeeting} wurde nicht in Ihren Terminen gefunden!</Alert>
                    }
                </div>
            }
        </React.Fragment>
    );

}


const mapStateToProps = state => ({
    meineTermine: state.meeting.meineTermine,
    user: state.global.user,
});

export default connect(mapStateToProps)(FptTermin);