import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'

import { Alert } from '@material-ui/lab';
import FptBewertungThemen from '../generic/FptBewertungThemen';
import FptForceAnmeldung from '../../utils/FptForceAnmeldung';
import FptHeader from '../../utils/FptHeader';
import Grid from '@material-ui/core/Grid';
import IconYes from '@material-ui/icons/CheckCircleOutline';
import IconEnthaltung from '@material-ui/icons/RemoveCircleOutline';
import IconNo from '@material-ui/icons/CancelOutlined';
import Typography from '@material-ui/core/Typography';

import { startReadMeineBewertungen, startReadThemenliste } from '../../../actions/fpt';
import { config } from '../../../config/config';


const idFpt = "fpt033";
const titel = 'Fachpressetage 2022 - Vorträge bewerten';

const PageBewertung = (props) => {

    useEffect(() => {
        !props.fpt[idFpt].meineBewertungen && loadBewertungen();
        !props.fpt[idFpt].themen && loadThemen();
    }, []);

    const loadBewertungen = () => {
        props.dispatch(startReadMeineBewertungen(props.user.idFptProfilPerson, idFpt));
    }

    const loadThemen = () => {
        props.dispatch(startReadThemenliste(idFpt))
    }

    return (
        <React.Fragment>
            <FptHeader title={titel} />

            <FptForceAnmeldung ablaufdatum="2021-11-22" idFpt={idFpt} fptTitel={titel}>

                <Grid container spacing={2}>

                    {config.fpt033.votingOpen ?
                        <Grid item xs={12} md={10} lg={8} xl={6}>
                            <Alert severity="info">
                                Entscheiden Sie mit, welche Vorträge ins Programm aufgenommen werden sollen.
                                <br />
                                <IconNo fontSize="small" /> soll nicht ins Programm<br />
                                <IconEnthaltung fontSize="small" /> Enthaltung (z.B. nicht mein Themengebiet)<br />
                                <IconYes fontSize="small" /> soll ins Programm<br />
                                <br />
                                Klicken Sie auf den jeweiligen Titel, um alle Details (+ Abstract) zu sehen und einen Kommentar einzutragen.
                            </Alert>
                            <FptBewertungThemen idFpt={idFpt} />
                        </Grid>
                        :
                        <Grid item xs={12} md={10} lg={8} xl={6}>
                            <div style={{ display: 'table', margin: '0 auto', paddingBottom: '20px', paddingTop: '10px' }}>
                                <img src="/images/closed.png" />
                            </div>
                            <Typography variant='h5'>
                                Das Wahllokal ist geschlossen. Alle Stimmen, die bis zum 11.11.2021 abgegeben wurden, werden gezählt!
                            </Typography>
                        </Grid>
                    }
                </Grid>

            </FptForceAnmeldung>

        </React.Fragment>
    )


}


const mapStateToProps = state => ({
                    user: state.global.user,
                fpt: state.fpt
});

                export default connect(mapStateToProps)(PageBewertung);
