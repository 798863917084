import React, { useEffect } from "react";
import { connect } from "react-redux";
import { startReadThemenliste } from "../../../actions/fpt";
import { Grid } from "@material-ui/core";

const currentFpt = 'fpt036';
const idFptVeranstaltung = 160;

const PageLogos = (props) => {

    useEffect(() => {
        // load Data
        props.dispatch(startReadThemenliste(currentFpt));
    }, []);

    useEffect(() => {
        // load Data
        console.log('props.fpt036', props.currentFpt);
    }, [props.currentFpt]);

    return (
        <div style={{ padding: "20px", backgroundColor: "#fff" }}>
            <h1>Logos Vorträge</h1>
            <Grid container spacing={2}>
                {props.currentFpt.themen && props.currentFpt.themen
                    .filter(a => a.idFptVeranstaltung === idFptVeranstaltung)
                    .sort((a, b) => (a.firmenname.toUpperCase() < b.firmenname.toUpperCase() ? -1 : 1))
                    .map(thema => (
                        <Grid item xs={3} key={thema.idFptThema}
                            style={{ height: '100px', width: '240px', textAlign: "center", whiteSpace: "nowrap", margin: '1em, 0' }}>
                            <span style={{ display: 'inline-block', height: '100%', verticalAlign: 'middle' }}></span>
                            <img
                                style={{ maxWidth: "210px", maxHeight: "100px", verticalAlign: "middle" }}
                                src={"https://fptfiles.rbsonline.de/logos/" + thema.logoFile}
                            />
                        </Grid>
                    ))}
            </Grid>
            <br/>
            <br/>
        </div>
    )
}


const mapStateToProps = state => ({
    currentFpt: state.fpt[currentFpt],
});

export default connect(mapStateToProps)(PageLogos);