import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'

import { Grid, Typography } from '@material-ui/core';
import FptForceAnmeldung from '../../utils/FptForceAnmeldung';
import FptHeader from '../../utils/FptHeader';
import ErgebnisChart from '../../utils/ErgebnisChart';
import { startReadBewertungErgebnis } from '../../../actions/fpt';


const idFpt = "fpt035";
const titel = 'Fachpressetage 2024 - Wahlergebnis';

const PageWahlergebnis = (props) => {

    useEffect(() => {
        props.dispatch(startReadBewertungErgebnis(idFpt));
    }, []);


    return (

        <React.Fragment>
            <FptHeader title={titel} />

            <FptForceAnmeldung ablaufdatum="2022-11-22" idFpt={idFpt} fptTitel={titel}>

                <Grid container spacing={2}>

                    <ErgebnisChart wahlergebnis={props.fpt[idFpt].wahlergebnis} />
                </Grid>

            </FptForceAnmeldung>
        </React.Fragment>
    )


}


const mapStateToProps = state => ({
    fpt: state.fpt
});

export default connect(mapStateToProps)(PageWahlergebnis);