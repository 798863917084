import React from 'react';
import { useParams } from "react-router-dom";

import FptHeader from '../../utils/FptHeader';
import FptForceAnmeldung from '../../utils/FptForceAnmeldung';
import FptThema from '../generic/FptThemaSeite';


const idFpt = 'fpt031';
const fptTitel = "Fachpressetage 2020";


const PageThema = (props) => {

	const { idThema } = useParams();

	return (
		<React.Fragment>
			<FptHeader title={fptTitel} />

			<FptForceAnmeldung idFpt={idFpt} fptTitel={fptTitel}>

				<FptThema idThema={idThema} idFpt={idFpt} hideVideo hideInvitation download/>

			</FptForceAnmeldung>
		</React.Fragment>
	);

}

export default PageThema;
