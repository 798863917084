import React from 'react';
import { useParams } from "react-router-dom";

import FptHeader from '../../utils/FptHeader';
import FptForceAnmeldung from '../../utils/FptForceAnmeldung';
import FptThema from '../generic/FptThemaSeite';
import { config } from '../../../config/config';

const idFpt = 'fpt035';
const fptTitel = "Fachpressetage 2024";


const PageThema = (props) => {

	const { idThema } = useParams();

	return (
		<React.Fragment>
			<FptHeader title={fptTitel} />

			<FptForceAnmeldung idFpt={idFpt} fptTitel={fptTitel}>

				<FptThema inlineFiles idThema={idThema} download={config[idFpt].download} idFpt={idFpt} hideFotos={false} hideInvitation={!config[idFpt].einladungGespraech}/>

			</FptForceAnmeldung>
		</React.Fragment>
	);

}

export default PageThema;
