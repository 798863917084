import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'

import Button from '@material-ui/core/Button';
import { FptList, FptListItem } from '../utils/FptList';
import Typography from '@material-ui/core/Typography';
import FptHeader from '../utils/FptHeader';
import Grid from '@material-ui/core/Grid';

import { startGetAnwesenheiten } from '../../actions/fpt';

const PagePing = (props) => {

    const getData = () => {
        props.dispatch(startGetAnwesenheiten(3));
    }

    const bttnReloadClick = () => {
        getData();
    }

    return (
        <React.Fragment>

            <FptHeader title="Jetzt online" />

            <Grid container spacing={0} style={{ height: '100%' }}>
                <Grid item xs={12} md={6} lg={4} style={{ width: '100%' }}>

                    <Button variant="contained" onClick={bttnReloadClick}>Daten aktualisieren</Button>

                    <FptList>
                        {props.anwesenheiten
                            .map((pers, index) =>

                                <FptListItem
                                    key={pers.idFptProfilPerson}
                                    primaryText={(index + 1).toString() + " " + (pers.titel ? pers.titel + " " : "") + pers.vorname + " " + pers.nachname}
                                    secondaryText={
                                        <React.Fragment>
                                            {pers.funktion &&
                                                <Typography variant="body2">
                                                    {pers.funktion}
                                                </Typography>
                                            }

                                            <Typography variant="body2" style={{ color: '#ddd' }}>
                                                {pers.email ?
                                                    <a href={'mailto:' + pers.email}>{pers.email}</a>
                                                    :
                                                    'keine E-Mail hinterlegt'
                                                }
                                                <br />
                                            </Typography>

                                            <Typography variant="body2" style={{ color: '#ddd' }}>
                                                {pers.telefonnummer ?
                                                    <a href={'tel:' + pers.telefonnummer}>{pers.telefonnummer}</a>
                                                    :
                                                    'kein Fon hinterlegt'
                                                }
                                                <br />
                                            </Typography>
                                        </React.Fragment>
                                    }
                                    avatar={pers.bild ? pers.bild : " "}
                                />

                            )
                        }
                    </FptList>

                </Grid>
            </Grid>
        </React.Fragment>
    )

}


const mapStateToProps = state => ({
    anwesenheiten: state.fpt.anwesenheiten,
});

export default connect(mapStateToProps)(PagePing);