import React from 'react';

import Alert from '@material-ui/lab/Alert';
import FptForceAnmeldung from '../../utils/FptForceAnmeldung';
import FptHeader from '../../utils/FptHeader';
import FptRedaktionen from '../generic/FptRedaktionen';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { config } from '../../../config/config';


const idFpt = "fpt036";
const titel = 'Fachpressetage 2025';

const PageRedaktionen = (props) => {

    return (
        <React.Fragment>
            <FptHeader title={titel} />

            <FptForceAnmeldung idFpt={idFpt} fptTitel={titel}>

                <Typography variant="h4" gutterBottom>
                    Redaktionen
                </Typography>

                <Typography variant="body1" gutterBottom>
                    Diese Redaktionen sind bislang angemeldet (Anmelung läuft noch):
                </Typography>

                <Grid container spacing={2}>
                    <Grid item xs={12} md={8} lg={6}>
                        {config[idFpt].meetingsOpen &&
                            <Alert severity="info" style={{ marginBottom: '24px' }}>Klicken Sie auf eine Person, um sie zu einem Gespräch einzuladen.</Alert>
                        }
                        <FptRedaktionen idFpt={idFpt} />

                    </Grid>

                </Grid>
            </FptForceAnmeldung>

        </React.Fragment>
    )
}



export default PageRedaktionen;