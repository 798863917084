import React from 'react';

import Alert from '@material-ui/lab/Alert';
import FptForceAnmeldung from '../../utils/FptForceAnmeldung';
import FptHeader from '../../utils/FptHeader';
import FptRedaktionen from '../generic/FptRedaktionen';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { config } from '../../../config/config';
import FptTische from '../generic/FptTische';


const idFpt = "fpt033";
const titel = 'Fachpressetage 2022';

const PagePressegespraech = (props) => {

    return (
        <React.Fragment>
            <FptHeader title={titel} />

            <FptForceAnmeldung idFpt={idFpt} fptTitel={titel}>

                <Typography variant="h4" gutterBottom>
                    Pressegesprächs-Tische
                </Typography>

                <FptTische idFpt={idFpt}/>
            </FptForceAnmeldung>

        </React.Fragment>
    );
}

export default PagePressegespraech;