import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import LoginControl from './LoginControl';


const Login = (props) => {

    const history = useHistory();

    useEffect(() => {
        if (props.global.user) {
            history.push('/');
        }
    }, [props.global.user]);


    return (
        <div style={{ height: '100%' }}>

            <Grid container spacing={0} style={{ height: '100%', marginTop: '16px' }}>
                <Grid item md={6} style={{ height: '100%' }}>
                    <Container component="main" maxWidth="xs">
                        <LoginControl hideForgotPwd={props.hideForgotPwd} />
                    </Container>
                </Grid>

                <Grid item md={6} style={{ height: '100%' }}>
                    <div
                        style={{ padding: '32px', height: '100%' }}
                    >
                    </div>
                </Grid>
            </Grid>

        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        global: state.global
    }
};

export default connect(mapStateToProps)(Login);
