import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { setMeineAnwesenheiten, startGetAnwesenheitenByFirmaFpt, startGetAnwesenheitenByPersonFpt, startReadProfileFirmaByUser } from '../../../actions/fpt';
import AnwesenheitEdit from '../../utils/AnwesenheitEdit';


const MeineAnwesenheiten = (props) => {

    useEffect(() => {
        props.ich && loadMeineAnwesenheiten(props.ich.idFptProfilPerson);
        console.log(props.ich);
    }, [props.ich]);

    const loadMeineAnwesenheiten = (idPerson) => {
        props.dispatch(startGetAnwesenheitenByPersonFpt(idPerson, props.idFpt));
    }

    const isFirma = props.ich && props.ich.idFptTeilnehmerTyp.includes('f')


    return (
        <div>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                Name/Firma
                            </TableCell>
                            {isFirma && <TableCell>nicht anwesend*</TableCell>}
                            <TableCell>V1</TableCell>
                            <TableCell>M1</TableCell>
                            <TableCell>N1</TableCell>
                            <TableCell>A1</TableCell>
                            <TableCell>V2</TableCell>
                            <TableCell>M2</TableCell>
                            <TableCell>N2</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>

                        {props.fpt[props.idFpt].meineAnwesenheiten && props.fpt[props.idFpt].meineAnwesenheiten.map(anw =>
                            <AnwesenheitEdit disabled={props.disabled} showNurAnsprechpartner={isFirma} key={anw.idFptAnwesenheit} idFpt={props.idFpt} data={anw} />
                        )}

                    </TableBody>
                </Table>
            </TableContainer>
            {isFirma && 
                <div>*) Bspw. Ansprechpartner, die nicht zur Veranstaltung vor Ort kommen.</div>
            }
        </div>
    )

}


const mapStateToProps = (state, componentProps) => ({
    ich: state.global.user,
    fpt: state.fpt,
    //myFirmen: componentProps.idFpt && state.fpt[componentProps.idFpt].myFirmenProfile,
    //meineAnwesenheiten: componentProps.idFpt && state.fpt[componentProps.idFpt].meineAnwesenheiten
});


export default connect(mapStateToProps)(MeineAnwesenheiten);