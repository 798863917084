import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router';

import { styled } from '@material-ui/core/styles';
import { Divider, List, ListItem, ListItemText, Dialog, DialogContent, Paper, Typography } from '@material-ui/core';
import FptBewetenButtons from '../../utils/FptBewertenButtons';
import FptBewertungThema from '../generic/FptBewertungThema';

import { logoUri } from '../../../config/globals';


const FptBewertungThemen = (props) => {

    const [detailOpen, setDetailOpen] = useState(false);
    const [selectedThema, setSelectedThema] = useState(undefined);


    const TabelleLinks = styled('div')(({ theme }) => ({
        width: "124px",
        minHeight: "40px",
        [theme.breakpoints.up('sm')]: {
            float: 'left'
        },
    }));

    const TabelleContent = styled('div')(({ theme }) => ({
        [theme.breakpoints.down('sm')]: {
            width: '100%' 
        },
        [theme.breakpoints.up('sm')]: {
            float: 'left',
            width: 'calc( 100% - 299px )' 
        },
    }));



    const openDetail = (idThema) => {
        setSelectedThema(idThema);
        setDetailOpen(true);
    }

    const getMeineBewertung = (idThema) => {
        return props.fpt[props.idFpt].meineBewertungen ? props.fpt[props.idFpt].meineBewertungen.find(b => b.idFptThema == idThema) : {}
    }

    const dialogClosed = () => {
        props.onNewDataNeeded && props.onNewDataNeeded(true);
        setDetailOpen(false)
    }

    return (
        <React.Fragment>

            <Dialog
                fullWidth={true}
                maxWidth='md'
                open={detailOpen}
                onClose={dialogClosed}
            >
                <DialogContent style={{padding: '0px'}}>
                    <FptBewertungThema idFpt={props.idFpt} idThema={selectedThema} />
                </DialogContent>
            </Dialog>

            {props.idFpt &&
                <List style={{ width: '100%', marginTop: '12px' }}>
                    <Divider />

                    {props.fpt[props.idFpt].themen && props.fpt[props.idFpt].themen
                        .filter(thema => thema.veranstaltung.includes('Pressekonferenz'))
                        .sort((a, b) => (a.firmenname.toUpperCase() < b.firmenname.toUpperCase() ? -1 : 1))
                        .map(thema => {
                            return (
                                <React.Fragment>
                                    <ListItem
                                        button
                                        key={thema.idFptThema}
                                        onClick={() => openDetail(thema.idFptThema)}
                                    >
                                        <ListItemText>
                                            <TabelleLinks>
                                                <img 
                                                    src={thema.logoFile && logoUri + thema.logoFile +"?v="+ (new Date()).getTime()} 
                                                    style={{ marginTop: '8px', maxWidth: "100px", maxHeight: "50px" }} 
                                                />
                                            </TabelleLinks>

                                            <TabelleContent>
                                                <Typography variant="h6" gutterBottom>
                                                    {thema.firmenname}
                                                </Typography>
                                                <Typography variant="body1" gutterBottom>
                                                    {thema.titel}
                                                </Typography>
                                            </TabelleContent>

                                            <div style={{ right: "0px", width: "175px", top: '5px', position: 'absolute' }}>
                                                <FptBewetenButtons
                                                    idFpt={props.idFpt}
                                                    idThema={thema.idFptThema}
                                                    bewertung={getMeineBewertung(thema.idFptThema)}
                                                />
                                            </div>
                                        </ListItemText>

                                    </ListItem>
                                    <Divider />

                                </React.Fragment>
                            )
                        })
                    }
                </List>
            }

        </React.Fragment>
    )
}


const mapStateToProps = state => ({
    user: state.global.user,
    fpt: state.fpt
});

export default withRouter(connect(mapStateToProps)(FptBewertungThemen));



