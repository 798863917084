import React from 'react';

import FptCard from './FptCard';


/**
 * Zeigt eine Themenseite an
 * 
 * @param {object} props Component props
 * @param {object} props.thema (required)
 */
const FptVideoCard = (props) => {


    return (
        <FptCard title="Video">
            {  props.thema && props.thema.youtubeId ?
                <iframe 
                    style={{width: '100%', height:'300px'}}
                    src={"https://www.youtube-nocookie.com/embed/" + props.thema.youtubeId} 
                    frameborder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                    allowfullscreen="">
                </iframe>
            :
                <div>kein Video vorhanden.</div>
            }
        </FptCard>
    )

}

export default FptVideoCard