import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'

import { Avatar, Checkbox } from '@material-ui/core';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { TableCell, TableRow } from '@material-ui/core';

import { updateAnwesenheit } from '../../actions/fpt';

const AnwesenheitEdit = (props) => {

    const [anwesenheit, setAnwesenheit] = useState()

    useEffect(() => {
        setAnwesenheit(props.data);
    }, [props.data]);


    const generateName = (anw) => {
        const name = anw.vorname + " " + anw.nachname;
        return anw.titelPerson ? anw.titelPerson + " " + name : name;
    }

    const changeNurAnsprechpartner = (e) => {
        const newData = {
            ...anwesenheit,
            v1: '0',
            m1: '0',
            n1: '0',
            a1: '0',
            v2: '0',
            m2: '0',
            n2: '0',
            nurAnsprechpartner: !anwesenheit.nurAnsprechpartner
        };
        props.dispatch(updateAnwesenheit(props.idFpt, newData));
    }

    const chkAnwesenheitChange = (e) => {
        const value = e.target.checked ? "X" : "0";
        const newData = {
            ...anwesenheit,
            [e.target.name]: value
        };

        console.log(newData);
        //setAnwesenheit(newData);        
        props.dispatch(updateAnwesenheit(props.idFpt, newData));
    }

    const isSelected = (anw, field) => {
        return anw[field] && anw[field].includes("X");
    }


    return (
        <TableRow>
            {anwesenheit &&
                <React.Fragment>
                    <TableCell style={{ padding: '0px' }}>
                        <ListItem>
                            <ListItemText
                                primary={anwesenheit && generateName(anwesenheit)}
                            ></ListItemText>
                        </ListItem>
                    </TableCell>
                    {props.showNurAnsprechpartner &&
                        <TableCell style={{ padding: '0px' }}>
                            <Checkbox name="nurAnsprechpartner" disabled={props.disabled} checked={anwesenheit.nurAnsprechpartner} onChange={changeNurAnsprechpartner} />
                        </TableCell>
                    }
                    <TableCell style={{ padding: '0px' }}><Checkbox name="v1" checked={isSelected(anwesenheit, "v1")} disabled={props.disabled || anwesenheit.nurAnsprechpartner} onChange={chkAnwesenheitChange} /></TableCell>
                    <TableCell style={{ padding: '0px' }}><Checkbox name="m1" checked={isSelected(anwesenheit, "m1")} disabled={props.disabled || anwesenheit.nurAnsprechpartner} onChange={chkAnwesenheitChange} /></TableCell>
                    <TableCell style={{ padding: '0px' }}><Checkbox name="n1" checked={isSelected(anwesenheit, "n1")} disabled={props.disabled || anwesenheit.nurAnsprechpartner} onChange={chkAnwesenheitChange} /></TableCell>
                    <TableCell style={{ padding: '0px' }}><Checkbox name="a1" checked={isSelected(anwesenheit, "a1")} disabled={props.disabled || anwesenheit.nurAnsprechpartner} onChange={chkAnwesenheitChange} /></TableCell>
                    <TableCell style={{ padding: '0px' }}><Checkbox name="v2" checked={isSelected(anwesenheit, "v2")} disabled={props.disabled || anwesenheit.nurAnsprechpartner} onChange={chkAnwesenheitChange} /></TableCell>
                    <TableCell style={{ padding: '0px' }}><Checkbox name="m2" checked={isSelected(anwesenheit, "m2")} disabled={props.disabled || anwesenheit.nurAnsprechpartner} onChange={chkAnwesenheitChange} /></TableCell>
                    <TableCell style={{ padding: '0px' }}><Checkbox name="n2" checked={isSelected(anwesenheit, "n2")} disabled={props.disabled || anwesenheit.nurAnsprechpartner} onChange={chkAnwesenheitChange} /></TableCell>
                </React.Fragment>
            }
        </TableRow>
    )
}

export default connect()(AnwesenheitEdit);