import React, {useEffect} from 'react';
import { connect } from 'react-redux'
import { setPageTitle } from '../../actions/global';


const FptHeader = (props) => {

    useEffect(() => {
        props.dispatch(setPageTitle(props.title));
    }, []);
    
    return(
        <React.Fragment>            
        </React.Fragment>
    );

}

export default connect()(FptHeader) ;