import React, { useState, useEffect } from 'react';

import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography'
import BusinessIcon from '@material-ui/icons/Business';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';

import { avatarUri } from '../../config/globals';
import { Badge, Chip, Grid } from '@material-ui/core';


export const FptListItem = (props) => {

    return (
        <React.Fragment>
            <ListItem button onClick={props.onClick}>
                <ListItemAvatar style={{ marginRight: props.avatar && '10px' }} >
                    {props.image ?
                        <React.Fragment>
                            <Hidden xsDown>
                                <div style={{ width: '100px', height: '50px', marginRight: '24px', marginLeft: '-16px' }}>
                                    {props.image !== 'none' &&
                                        <img style={{ maxWidth: '100px', maxHeight: '50px' }} src={props.image + "?v=" + (new Date()).getTime()} />
                                    }
                                </div>
                            </Hidden>
                            <Hidden smUp>
                                <div style={{ width: '65px', height: '50px', marginRight: '24px', marginLeft: '-16px' }}>
                                    {props.image !== 'none' &&
                                        <img style={{ maxWidth: '65px', maxHeight: '50px' }} src={props.image + "?v=" + (new Date()).getTime()} />
                                    }
                                </div>
                            </Hidden>
                        </React.Fragment>

                        : props.avatar &&
                        <Badge badgeContent={props.avatarBadge == 'f' ? <BusinessIcon fontSize="small" /> : props.avatarBadge == 'j' && <ImportContactsIcon fontSize="small" />} >
                            <Avatar src={avatarUri + props.avatar} />
                        </Badge>

                    }
                </ListItemAvatar>

                <ListItemText>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={8} lg={9} xl={10}>
                            <Typography variant="body1">
                                {props.primaryText}
                            </Typography>
                            <Typography variant="body2">
                                {props.secondaryText}
                            </Typography>

                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3} xl={2}>
                            {props.chipRechts &&
                                <Chip size="small" label={props.chipRechts} style={props.chipStyle && props.chipStyle} color={!props.chipStyle ? 'secondary' : undefined} />
                            }
                        </Grid>
                    </Grid>
                </ListItemText>

            </ListItem>

            <Divider />
        </React.Fragment>
    )
}
//veranstaltung


export const FptList = (props) => {

    return (
        <React.Fragment>

            <List>
                <Divider />
                {props.children}
            </List>

        </React.Fragment>
    )
}



/*

    <ListItemText
                    style={{paddingRight: '100px'}}
                    disableTypography
                    primary={
                        <Typography variant="body1">
                            {props.primaryText}
                        </Typography>
                    }

                    secondary={props.secondaryText}
                />
                {props.chipRechts &&
                    <ListItemSecondaryAction style={{ top: '30px' }}>
                        <Chip size="small" label={props.chipRechts} style={props.chipStyle && props.chipStyle} color={!props.chipStyle ? 'secondary' : undefined}/>
                    </ListItemSecondaryAction>
                }


                {props.iconRechts && 1 == 2 &&
                    <ListItemSecondaryAction>
                        <Tooltip title={props.tooltipRechts}>
                            {props.onClickRechts ?
                                <IconButton color="secondary" component="span" onClick={props.onClickRechts}>
                                    <Icon>{props.iconRechts}</Icon>
                                </IconButton>
                                :
                                <Icon>{props.iconRechts}</Icon>
                            }
                        </Tooltip>
                    </ListItemSecondaryAction>
                }

                */