import React from "react";
import { connect } from 'react-redux'
import { useEffect } from "react";

import { config } from "../../config/config";
import { startGetYoutubeId } from "../../actions/fpt";

// Dieser Iframe muss im PCO gesetzt werden
// <iframe src="https://portal.fachpresse.live/stream?id=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

const PageStream = (props) => {

    useEffect(() => {
        props.dispatch(startGetYoutubeId(config.currentFpt));
    }, []);

    useEffect(() => {        
        if( props.youtubeId ){ 
            //console.log(props.youtubeId);
            window.location =  "https://www.youtube.com/embed/" + props.youtubeId;
        }
    }, [props.youtubeId]);


    return (
        <div>
            forward funtioniert nicht
        </div>
    );
}

const mapStateToProps = state => ({
    youtubeId: state.fpt[config.currentFpt].youtubeId
});

export default connect(mapStateToProps)(PageStream);