import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import CheckIcon from '@material-ui/icons/Check';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import FptCard from './FptCard';
import FptLink from './FptLink';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { startCancelMeeting, startMeetingBestaetigen, downloadIcs, startSendMessage } from '../../actions/meeting';

/**
 * Zeigt eine Themenseite an
 * 
 * @param {object} props Component props
 * @param {object} props.thema (required)
 */
const FptTerminCard = (props) => {

    useEffect(() => {    
        if(props.user.idFptProfilPerson === props.meeting.idPersonGast){
            props.meeting.jitsi ? setTyp('jitsi') :
                props.meeting.fon ? setTyp('fon') : setTyp('sonst');
        }
    }, [props.meeting]);

    const history = useHistory();

    const [openCancel, setOpenCancel] = useState(false);
    const [nachricht, setNachricht] = useState('');
    const [sendFeedback, setSendFeedback] = useState(undefined);
    const [typ, setTyp] = useState('');

    const bttnVerschiebenClick = () => {
        setOpenCancel(true)
    }

    const bttnBestaetigenClick = () => {
        props.dispatch(startMeetingBestaetigen(props.meeting.idFptMeeting, typ, props.user.idFptProfilPerson));
    }

    const bttnCancelClick = () => {
        // Termin löschen 
        props.dispatch(startCancelMeeting(props.meeting.idFptMeeting, deleteTerminDone))
    }

    const deleteTerminDone = (data) => {
        // neuen Termin erstellen
        setOpenCancel(false);
        history.push(`/fpt032/einladen/${props.meeting.idPersonHost}/${props.idFpt}/${props.meeting.isHostJournalist}/${true}`);
    }

    const bttnDownloadClick = () => {
        props.dispatch(downloadIcs(props.meeting.idFptMeeting));
    }

    const txtNachrichtChange = (event) => {
        setNachricht(event.target.value);
    }

    const bttnSendenClick = () => {

        var idFrom = 0;
        var idTo = 0;

        console.log('user', props.user.idPerson);
        console.log('host', props.meeting.idPersonHost);
        console.log('gast', props.meeting.idPersonGast);

        if (props.user.idPerson == props.meeting.idPersonHost) {
            idFrom = props.meeting.idPersonHost;
            idTo = props.meeting.idPersonGast;
        }
        else {
            idTo = props.meeting.idPersonHost;
            idFrom = props.meeting.idPersonGast;
        }

        console.log(idFrom, '=>', idTo);
        //sendeNachricht(props.meeting.idPersonGast)

        props.dispatch(startSendMessage(idFrom, idTo, nachricht, sendSuccess));
    }

    const sendSuccess = (data) => {
        setSendFeedback('Die Nachricht wurde verschickt. Sie erhalten eine Kopie.');
    }


    const countChannels = () => {
        var channels = 0;
        props.meeting.jitsi && channels++;
        props.meeting.fon && channels++;
        props.meeting.sonstKanal && channels++;
        return channels;
    }


    return (
        <FptCard
            style={{ marginBottom: '16px' }}
            title={props.meeting.tagString + " - " + props.meeting.beginnStunde + ":" + (props.meeting.beginnMinute < 10 ? "0" : "") + props.meeting.beginnMinute}
            buttons={
                <React.Fragment>
                    {!openCancel ?
                        <React.Fragment>
                            {(props.user.idFptProfilPerson === props.meeting.idPersonGast && props.meeting.status < 50) &&
                                <Button
                                    startIcon={<CheckIcon />}
                                    variant="contained"
                                    color="primary"
                                    onClick={bttnBestaetigenClick}
                                >
                                    bestätigen
                                </Button>
                            }
                            {(props.user.idFptProfilPerson === props.meeting.idPersonGast && props.meeting.status < 50) &&
                                <Button
                                    startIcon={<EventBusyIcon />}
                                    variant="contained"
                                    color="secondary"
                                    onClick={bttnVerschiebenClick}
                                >
                                    verschieben
                            </Button>
                            }
                        </React.Fragment>
                        :
                        <div>
                            <Typography variant="body1" gutterBottom>
                                Wenn Sie einen Termin verschieben wird dieser gelöscht und ein Neuer erstellt!
                            </Typography>

                            <Button onClick={() => setOpenCancel(false)}>abbrechen</Button>

                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={bttnCancelClick}
                            >
                                Löschen &amp; neuen Termin suchen
                    </Button>
                        </div>
                    }
                </React.Fragment>
            }
        >

            <Alert severity={props.meeting.status < 20 ? "warning" : "success"}>
                {props.meeting.status === 10 &&
                    <React.Fragment>Termin wurde angefragt aber noch nicht bestätigt.</React.Fragment>
                }
                {props.meeting.status > 90 &&
                    <React.Fragment>Termin vom Gast bestätigt.</React.Fragment>
                }
            </Alert>

            <Table size="small">
                <TableBody>
                    <TableRow>
                        <TableCell width='100px' component="th" scope="row">
                            Host:
                            </TableCell>
                        <TableCell >
                            <FptLink to={'/fpt032/profil/' + props.meeting.idPersonHost}>
                                {props.meeting.vornameHost} {props.meeting.nachnameHost} <br /> {props.meeting.firmaHost}
                            </FptLink>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell width='100px' component="th" scope="row">
                            Gast:
                        </TableCell>
                        <TableCell >
                            <FptLink to={'/fpt032/profil/' + props.meeting.idPersonGast}>
                                {props.meeting.vornameGast} {props.meeting.nachnameGast} <br /> {props.meeting.firmaGast}
                            </FptLink>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell width='100px' component="th" scope="row">
                            Ort:
                        </TableCell>
                        <TableCell >
                            {props.meeting.status < 100 ?
                                <FormControl component="fieldset">
                                    {props.user.idFptProfilPerson === props.meeting.idPersonGast && (countChannels() > 1) &&
                                        <FormLabel component="legend">Bitte auswählen</FormLabel>
                                    }
                                    <RadioGroup name="typ" value={typ} onChange={(e) => setTyp(e.target.value) }>
                                        { props.meeting.jitsi &&
                                            <FormControlLabel value="jitsi" control={<Radio disabled={props.user.idFptProfilPerson != props.meeting.idPersonGast}/>} label={
                                                <React.Fragment>
                                                <FptLink
                                                    href={'https://meet.fachpresse.live/' + props.meeting.meetingRoomId}
                                                    typoVariant='body2'
                                                >
                                                    {'Standard: https://meet.fachpresse.live/' + props.meeting.meetingRoomId + ' (anklicken zum testen)'}
                                                </FptLink>
                                                </React.Fragment>
                                            } />
                                        }
                                        { props.meeting.fon &&
                                            <FormControlLabel value="fon" control={<Radio disabled={props.user.idFptProfilPerson != props.meeting.idPersonGast} />} label={
                                                <Typography variant="body2">
                                                    per Telefon: {props.meeting.fon} 
                                                </Typography>
                                            } />
                                        }
                                        { props.meeting.sonstKanal &&
                                            <FormControlLabel value="sonst" control={<Radio disabled={props.user.idFptProfilPerson != props.meeting.idPersonGast}/>} label={
                                                <Typography variant="body2">
                                                    Benutzerdefiniert von Host: {props.meeting.sonstKanal}
                                                </Typography>
                                            } />
                                        }
                                    </RadioGroup>
                                </FormControl>
                                :
                                <React.Fragment>
                                    {(!props.meeting.typ || props.meeting.typ == 'jitsi') &&
                                        <FptLink
                                            href={'https://meet.fachpresse.live/' + props.meeting.meetingRoomId}
                                            typoVariant='body2'
                                        >
                                            {'https://meet.fachpresse.live/' + props.meeting.meetingRoomId }
                                        </FptLink>
                                    }
                                    {(props.meeting.typ == 'fon') &&
                                        <Typography variant="body2">
                                            per Telefon: {props.meeting.fon} 
                                        </Typography>
                                    }
                                    {(props.meeting.typ == 'sonst') &&
                                        <Typography variant="body2">
                                            Benutzerdefiniert von Host: {props.meeting.sonstKanal}
                                        </Typography>
                                    }
                                </React.Fragment>   
                            } 
                        </TableCell>
                    </TableRow>
                    {props.meeting.infoGast &&
                        <TableRow>
                            <TableCell width='100px' component="th" scope="row">
                                Info:
                            </TableCell>
                            <TableCell >
                                {props.meeting.infoGast}
                            </TableCell>
                        </TableRow>
                    }
                </TableBody>
                {props.meeting.status > 50 &&
                    <TableRow>
                        <TableCell width='100px' component="th" scope="row">
                            Nachricht:
                        </TableCell>
                        <TableCell >
                            <TextField
                                id="filled-multiline-flexible"
                                placeholder="Hier können Sie eine Nachricht an Ihren Gesprächspartner schicken."
                                multiline
                                rows={4}
                                fullWidth
                                value={nachricht}
                                onChange={txtNachrichtChange}
                                variant="outlined"
                            />

                            {sendFeedback ?
                                <Alert severity='info'>{sendFeedback}</Alert>
                                :
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={bttnSendenClick}
                                >
                                    Senden
                                </Button>
                            }
                        </TableCell>
                    </TableRow>
                }
            </Table>
            <Button style={{ marginTop: '16px' }} color="secondary" onClick={bttnDownloadClick}>Downlod .ics File</Button>

        </FptCard>
    )

}

const mapStateToProps = state => ({
    user: state.global.user,
});

export default connect(mapStateToProps)(FptTerminCard);