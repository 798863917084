import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import { useParams } from 'react-router';

import FptBewertungThema from '../generic/FptBewertungThema';
import FptForceAnmeldung from '../../utils/FptForceAnmeldung';
import FptHeader from '../../utils/FptHeader';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { startReadMeineBewertungen, startReadThemenliste } from '../../../actions/fpt';


const idFpt = "fpt035";
const titel = 'Fachpressetage 2024';


const PageBewertungThema = (props) => {

    const { idThema } = useParams();

    useEffect(() => {
		!props.fpt[idFpt].meineBewertungen && loadBewertungen();
        !props.fpt[idFpt].themen && loadThemen();
	}, []);

    const loadBewertungen = () => {
		props.dispatch(startReadMeineBewertungen(props.user.idFptProfilPerson, idFpt));
	}

    const loadThemen = () => {
        props.dispatch(startReadThemenliste(idFpt))
    }

    return (
        <React.Fragment>
            <FptHeader title={titel} />

            <FptForceAnmeldung ablaufdatum={config[idFpt].wahlEnde} idFpt={idFpt} fptTitel={titel}>

                <Typography variant="h4" gutterBottom>
                    Vortrag bewerten
                </Typography>

                <Grid container spacing={2}>
                    <Grid item xs={12} md={10} lg={8} xl={6}>

                        <FptBewertungThema idFpt={idFpt} idThema={idThema} />

                    </Grid>
                </Grid>
            </FptForceAnmeldung>
        </React.Fragment>
    )
}


const mapStateToProps = state => ({
    user: state.global.user,
    fpt: state.fpt
});

export default connect(mapStateToProps)(PageBewertungThema);