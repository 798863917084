import React, { useEffect } from 'react';
import { connect } from 'react-redux'

import FptThemaCard from '../../utils/FptThemaCard';
import FptMitabeiterCard from '../../utils/FptMitabeiterCard';
import FptFirmaCard from '../../utils/FptFirmaCard';
import FptFotosCard from '../../utils/FptFotosCard';

import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';

import { startReadProfilFirma, startReadTeilnehmerPersonen, startReadThemenliste } from '../../../actions/fpt';
import { logoUri } from '../../../config/globals';
import FptDownloadCard from '../../utils/FptDownloadCard';
import FptMediaCard from '../../utils/FptMediaCard';

/**
 * Zeigt eine Themenseite an
 * 
 * @param {object} props Component props
 * @param {number} props.idThema (required)
 * @param {string} props.idFpt (required)
 * @param {string} props.fptTitel (required)
 */
const FptThemaSeite = (props) => {


    const [thema, setThema] = React.useState(undefined);

    useEffect(() => {
        // Die Themen werden nur einmal geladen. wenn noch keine da sind, laden wir sie
        if (!props.themen) {
            loadAlleThemen(props.idFpt);
        }
        else {
            const thema = props.themen.find(t => t.idFptThema == props.idThema);
            console.log('################### Thema', thema)
            setThema(thema);
            thema && loadProfilFirma(thema.idFptProfilFirma);
            thema && loadTeilnehmerPersonen(thema.idFptProfilFirma, props.idFpt);
        }

        /*
        if (props.thema && props.idThema && props.thema.idFptThema == props.idThema) {
            console.log('################### Thema changed', props.thema)
            if (props.thema && props.thema.idFptProfilFirma) {
                loadProfilFirma(props.thema.idFptProfilFirma);
                loadTeilnehmerPersonen(props.thema.idFptProfilFirma, props.idFpt)
            }
        }*/
    }, [props.themen]);


    const loadAlleThemen = (idFpt) => {
        props.dispatch(startReadThemenliste(idFpt))
    }

    const loadProfilFirma = (id) => {
        props.dispatch(startReadProfilFirma(id));
    }

    const loadTeilnehmerPersonen = (idFirma, idAuftrag) => {
        //console.log('###################', idAuftrag)
        props.dispatch(startReadTeilnehmerPersonen(idFirma, idAuftrag));
    }


    return (
        <React.Fragment>
            <div style={{ position: 'relative', marginBottom: '24px' }}>
                <Hidden mdUp>
                    <div style={{ marginBottom: '8px' }}>
                        {thema && thema.logoFile && <img src={logoUri + thema.logoFile + "?v=" + (new Date()).getTime()} style={{ marginRight: '20px', maxHeight: '45px' }} />}

                    </div>
                </Hidden>
                <Typography variant="h4" gutterBottom>
                    {thema && thema.firmenname}
                </Typography>
                <Hidden smDown>
                    <div style={{ position: 'absolute', right: '0px', top: '0px' }}>
                        {thema && thema.logoFile && <img src={logoUri + thema.logoFile + "?v=" + (new Date()).getTime()} style={{ marginRight: '20px', maxHeight: '45px' }} />}
                    </div>
                </Hidden>
            </div>



            <Grid container spacing={2}>
                <Grid item xs={12} md={6} xl={4}>
                    <FptThemaCard disableDownload={!!props.inlineFiles} thema={thema} download={props.download} />
                </Grid>

                <Grid item xs={12} md={6} xl={4}>
                    <FptMitabeiterCard
                        profilPersonen={thema && thema.idFptProfilFirma &&
                            props.fpt[props.idFpt].firmen[thema.idFptProfilFirma] &&
                            props.fpt[props.idFpt].firmen[thema.idFptProfilFirma].teilnehmerPersonen}
                        hideInvitation={props.hideInvitation}
                        idFpt={props.idFpt}
                    />
                </Grid>

                <Grid item xs={12} md={6} xl={4}>
                    <FptFirmaCard profilFirma={props.profilFirma} />
                </Grid>

                {!props.hideVideo && thema && thema.youtubeId &&
                    <Grid item xs={12} md={6} xl={4}>
                        <FptMediaCard thema={thema} />
                    </Grid>
                }

                {props.inlineFiles && thema &&
                    <Grid item xs={12} md={12} xl={8}>
                        <FptDownloadCard download={props.download} idThema={props.idThema} thema={thema} />
                    </Grid>
                }

                {!props.hideFotos && 1 === 2 &&
                    <Grid item xs={12} md={6} xl={4}>
                        <FptFotosCard thema={thema} />
                    </Grid>
                }

            </Grid>
        </React.Fragment>
    )

}

const mapStateToProps = (state, ownProps) => ({
    themen: state.fpt[ownProps.idFpt].themen,
    profilFirma: state.fpt.profilFirma,
    teilnehmerPersonen: state.fpt.teilnehmerPersonen,
    fpt: state.fpt,
});

export default connect(mapStateToProps)(FptThemaSeite);