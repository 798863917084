import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import EditableField from './EditableField'
import { FilePond, File, registerPlugin } from 'react-filepond'
import FptCard from './FptCard';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import { logoUri } from '../../config/globals';
import { baseURL } from '../../config/webservice';
import { startDeleteFirmenLogo, startSaveProfilFirma } from '../../actions/global';

// Import FilePond styles
import 'filepond/dist/filepond.min.css'


const FptProfilFirmaCard = (props) => {


    const [touched, setTouched] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [formData, setFormData] = useState(props.profilFirma);
    const [showUplaod, setShowUpload] = useState(false);
    const [files, setFiles] = useState([]);

    const bttnSaveClick = () => {
        setIsEdit(false);
        if (touched) {
            console.log(formData);
            props.dispatch(startSaveProfilFirma(formData, saveProfilDone));
        }
    }

    const bttnBearbeitenClick = () => {
        setIsEdit(true);
    }

    const txtFieldChange = (key, value) => {
        setFormData({
            ...formData,
            [key]: value
        });
        setTouched(true);
    }

    const bttnDeleteClick = () => {
        props.dispatch(startDeleteFirmenLogo(formData.idFptProfilFirma, deleteDone));
    }

    const deleteDone = () => {
        setFormData({
            ...formData,
            logoFile: undefined
        });
        props.onDataChanged("deletedLogo");
    }

    const saveProfilDone = () => {
        props.onDataChanged("updateData");
    }

    const filePondOnLoad = (logo) => {
        const bild = JSON.parse(logo);
        console.log(logo)

        if (bild) {
            setFormData({
                ...formData,
                logoFile: bild
            });

            props.onDataChanged("addedLogo");

            //props.dispatch(startSetAvatar(bild));
            setShowUpload(false);
        }

    }

    return (
        <FptCard
            style={{ marginBottom: '16px' }}
            title={props.isRedaktion ? "Redaktion" : "Firmendaten"}
            buttons={props.readonly ?
                undefined
                :
                isEdit ?
                    <Button color='primary' onClick={bttnSaveClick}>Speichern</Button>
                    :
                    <Button color='secondary' onClick={bttnBearbeitenClick}>Bearbeiten</Button>
            }
        >
            <div style={{ margin: '16px 0' }}>
                {!showUplaod ?
                    <React.Fragment>
                        {!props.readonly ? (
                            formData.logoFile ?
                                <React.Fragment>
                                    <div>
                                        <img 
                                            style={{ maxWidth: '100px', maxHeight: '50px' }} 
                                            src={logoUri + formData.logoFile +"?v="+ (new Date()).getTime()} 
                                        />
                                    </div>
                                    <Button color='secondary' onClick={bttnDeleteClick}>Logo löschen</Button>
                                </React.Fragment>
                                :
                                <Button color='secondary' onClick={() => setShowUpload(true)}>Logo hochladen</Button>
                        )
                            :
                            <div>
                                <img 
                                    style={{ maxWidth: '100px', maxHeight: '50px' }} 
                                    src={formData.logoFile && logoUri + formData.logoFile +"?v="+ (new Date()).getTime()} 
                                />
                            </div>
                        }
                    </React.Fragment>
                    :
                    <React.Fragment>
                        <FilePond
                            server={{
                                url: baseURL,
                                process: {
                                    url: `/api/Fpt/UploadFirmenLogo/${formData.idFptProfilFirma}`,
                                    method: 'POST',
                                    withCredentials: false,
                                    headers: {
                                        'authorization': `Bearer ${props.user.token}`
                                    },
                                    onload: filePondOnLoad,
                                    onerror: null
                                }
                            }
                            }
                            files={files}
                            onupdatefiles={fileItems => setFiles(fileItems.map(fileItem => fileItem.file))}
                            allowMultiple={false}
                            maxFiles={3}
                            instantUpload={true}
                            name="files"
                            labelIdle='Drag & Drop oder <span class="filepond--label-action">öffnen</span>'
                        />
                        <Button color='secondary' onClick={() => setShowUpload(false)}>Upload abbrechen</Button>
                    </React.Fragment>
                }
            </div>

            <Typography variant="h6">
                {formData.firmenname}
            </Typography>
            <Typography variant="body2">
                {formData.strasse}
            </Typography>
            <Typography variant="body2" gutterBottom>
                {formData.idLand} - {formData.plzStrasse} {formData.ort}
            </Typography>





            <Table size={isEdit && 'small'}>
                <TableBody>
                    <TableRow>
                        <TableCell width='100px' component="th" scope="row">
                            Webseite:
                        </TableCell>
                        <TableCell >
                            <EditableField
                                link='web'
                                value={formData.www}
                                displayMaxSize={25}
                                name="www"
                                isEdit={isEdit}
                                onChange={txtFieldChange}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell width='100px' component="th" scope="row">
                            Themen:
                        </TableCell>
                        <TableCell >
                            <EditableField
                                value={formData.themen}
                                name="themen"
                                isEdit={isEdit}
                                onChange={txtFieldChange}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell width='100px' component="th" scope="row">
                            Biete:
                        </TableCell>
                        <TableCell >
                            <EditableField
                                value={formData.biete}
                                name="biete"
                                isEdit={isEdit}
                                onChange={txtFieldChange}
                            />
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell width='100px' component="th" scope="row">
                            Suche:
                        </TableCell>
                        <TableCell >
                            <EditableField
                                value={formData.suche}
                                name="suche"
                                isEdit={isEdit}
                                onChange={txtFieldChange}
                            />
                        </TableCell>
                    </TableRow>

                </TableBody>
            </Table>

        </FptCard>
    )

}

const mapStateToProps = state => ({
    user: state.global.user,
});

export default connect(mapStateToProps)(FptProfilFirmaCard);