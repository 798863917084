import React, { useEffect } from 'react';
import { connect } from 'react-redux'

import FptTerminCard from '../../utils/FptTerminCard';
import Alert from '@material-ui/lab/Alert';
import { startGetMeineTermine } from '../../../actions/meeting';



const FptTermine = (props) => {

    useEffect(() => {
        if (props.idProfilPerson) {
            getTermine(props.idProfilPerson);
        }
    }, [props.idProfilPerson]);


    const getTermine = (id) => {
        props.dispatch(startGetMeineTermine(id));
    }

    return (
        <div>
            {props.meineTermine && props.meineTermine.length > 0 ?
                props.meineTermine
                    .sort((a, b) => (a.tagDatum != b.tagDatum ? (a.tagDatum < b.tagDatum ? -1 : 1) : (a.beginnStunde != b.beginnStunde ? a.beginnStunde - b.beginnStunde : a.beginnMinute - b.beginnMinute)))
                    .filter(m => m.status > 0)
                    .map(m => 
                        <FptTerminCard 
                            key={m.idFptMeeting}
                            meeting={m} 
                            idFpt={props.idFpt}/>
                    )
                :

                <Alert severity="info">Sie haben noch keine Termine vereinbart.</Alert>

            }
        </div>
    );

}


const mapStateToProps = state => ({
    meineTermine: state.meeting.meineTermine
});

export default connect(mapStateToProps)(FptTermine);