import React, { useEffect } from 'react';
import { connect } from 'react-redux'
import FptProgrammItem from '../../utils/FptProgrmmItem';

import { startReadProgram } from '../../../actions/fpt';


const FptProgrammTag = (props) => {

    useEffect(() => {
        loadProgrammData();
    }, []);

    const loadProgrammData = () => {
        props.dispatch(startReadProgram(props.idFpt, props.tag, props.xmlFile))
    }

    return (
        <div>
            {props.programm && props.programm[props.tag] && props.programm[props.tag].map(item =>
                <FptProgrammItem key={item.id} data={item} />
            )}
        </div>
    );

}


const mapStateToProps = (state, contolProps) => ({
    programm: state.fpt[contolProps.idFpt].programm
});

export default connect(mapStateToProps)(FptProgrammTag);