import { olderThanDays } from "./dateHelper";

export const isAngemledet = (user, idFpt) => {
    if(user.idFptAngemeldet && user.idFptAngemeldet.find(a => a === idFpt)){
        return true;
    }

    return false;
}

export const checkAnmeldungJ = (idFpt, user, teilnehmerRedakteur, ablaufdatum = undefined) => {

    if (user.idFptTeilnehmerTyp.includes('j')) {
        if (ablaufdatum) {
            if (!olderThanDays(ablaufdatum, 0)) {
                return true;
            }
        }

        if (teilnehmerRedakteur) {
            return teilnehmerRedakteur.some(tln => tln.idAuftragFpt.includes(idFpt) &&
                (tln.idFptTeilnehmerTyp.includes('ja') || tln.idFptTeilnehmerTyp.includes('jc')));
        }
    }

    return false;
}