import React, { Fragment, useState, useRef } from "react";
import { connect } from "react-redux";
import { FilePond } from 'react-filepond'
import { baseURL } from "../../config/webservice";
import FptThemaFiles from "./FptThemaFiles";
import FptCard from "./FptCard";
import { Typography } from "@material-ui/core";

const FptThemaUpload = (props) => {

    const filePondRef = useRef(null);

    //const [files, setFiles] = useState([]);
    const [thema, setThema] = useState(props.thema);
    const [filesChanged, setFilesChanged] = useState(false);

    const filePondOnLoad = (avatar) => {
        /*
        const bild = JSON.parse(avatar);
        console.log(bild)
        if(avatar){
            setFormData({
                ...formData,
                bild: bild
            });
            
            props.dispatch(startSetAvatar(bild));
            setShowUpload(false);
        }*/
    }

    // Wird aufgerufen, wenn der upload abgeschlossen ist
    const fileProcess = (error, file) => {
        console.log("process", file);

        if (!error) {
            filePondRef.current.removeFiles();
            // der State flesChanged ist an forceRegenerateZip gebunden
            // das sorgt dafür, dass alles neu generiert wird
            // damit das mehrfach gemacht werden kann setzwn wir nicht
            // uaf true sondern einen Timestamp
            setFilesChanged(new Date().getTime());
        }
    }

    const filesUpdated = (fileItems) => {
        //console.log("update", fileItems, files);
        //fileItems => setFiles(fileItems.map(fileItem => fileItem.file))
    }

    return (
        <FptCard
            style={{ marginBottom: '16px' }}
            title="Dateien hochladen"
        >
            {thema &&
                <Fragment>

                    <Typography variant="subtitle1" gutterBottom>
                        {thema.veranstaltung}
                    </Typography>

                    <FilePond
                        ref={filePondRef}
                        server={{
                            url: baseURL,
                            process: {
                                url: `/api/Fpt/UploadThemaFile/${thema.idAuftragFpt.trim()}/${thema.idFptThema}`,
                                method: 'POST',
                                withCredentials: false,
                                headers: {
                                    'authorization': `Bearer ${props.user.token}`
                                },
                                onload: filePondOnLoad,
                                onerror: null
                            }
                        }}
                        onprocessfile={fileProcess}
                        //files={files}
                        //onupdatefiles={filesUpdated}
                        allowMultiple={false}
                        allowReplace={true}
                        maxFiles={3}
                        instantUpload={true}
                        name="files"
                        labelIdle='Drag & Drop oder <span class="filepond--label-action">öffnen</span>'
                        allowRevert={false}
                    />

                    <FptThemaFiles
                        forceRegenerateZip={filesChanged}
                        onZipFileGenerated={() => setFilesChanged(false)}
                        deletable
                        float={"left"}
                        thema={thema}
                    />

                    <div style={{ clear: "both" }} />

                </Fragment>
            }
        </FptCard>
    )
}

const mapStateToProps = state => ({
    user: state.global.user,
});

export default connect(mapStateToProps)(FptThemaUpload);
