import React from 'react';

import Alert from '@material-ui/lab/Alert';
import FptForceAnmeldung from '../../utils/FptForceAnmeldung';
import FptHeader from '../../utils/FptHeader';
import FptRedaktionen from '../generic/FptRedaktionen';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';



const idFpt = "fpt032";


const PageRedaktionen032 = (props) => {

    return (
        <React.Fragment>
            <FptHeader title="Fachpressetage 2021" />

            <FptForceAnmeldung idFpt={idFpt} fptTitel="Fachpressetage 2021">
                
                <Typography variant="h4" gutterBottom>
                    Redaktionen 
                </Typography>

                <Grid container spacing={2}>
                    <Grid item xs={12} md={8} lg={6}>

                        <Alert severity="info" style={{marginBottom:'24px'}}>Klicken Sie auf eine Person, um sie zu einem Gespräch einzuladen.</Alert>

                        <FptRedaktionen idFpt={idFpt}/>

                    </Grid>

                </Grid>
            </FptForceAnmeldung>

        </React.Fragment>
    )
}



export default PageRedaktionen032;