import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'

import Alert from '@material-ui/lab/Alert';
import FptForceAnmeldung from '../../utils/FptForceAnmeldung';
import FptHeader from '../../utils/FptHeader';
import FptTeilnehmerListe from '../generic/FptTeilnehmerListe'
import FptWerWann from '../generic/FptWerWann';
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography';

import { startReadAllePersonen } from '../../../actions/fpt';
import { config } from '../../../config/config';
import MeineAnwesenheiten from '../generic/MeineAnwesenheiten';

const idFpt = 'fpt033';
const titel = 'Fachpressetage 2022';

const PageTeilnehmer = (props) => {

	useEffect(() => {
		loadTeilnehmer();
	}, []);

	const loadTeilnehmer = () => {
		props.dispatch(startReadAllePersonen(idFpt, true));
	}


	return (
		<React.Fragment>
			<FptHeader title={titel} />

			<FptForceAnmeldung idFpt={idFpt} fptTitel={titel}>

				<Grid container spacing={2}>
					<Grid item xs={12} md={12} lg={8}>

						<Typography variant="h4" gutterBottom>
							Teilnehmer:innen
						</Typography>


						{config[idFpt].meetingsOpen &&
							<Alert severity="info" style={{ marginBottom: '24px' }}>Klicken Sie auf eine Person, um sie zu einem Gespräch einzuladen.</Alert>
						}

						<FptTeilnehmerListe personen={props.allePersonen} idFpt={idFpt} />

					</Grid>
				</Grid>

			</FptForceAnmeldung>

		</React.Fragment>
	);

}


const mapStateToProps = state => ({
	allePersonen: state.fpt[idFpt].allePersonen
});

export default connect(mapStateToProps)(PageTeilnehmer);
