import React, { useEffect } from "react";
import MaxSizeImage from "../../utils/MaxSizeImage";
import { connect } from 'react-redux'
import { startReadTeilnehmer, startReadThemenliste } from "../../../actions/fpt";


const idFpt = 'fpt036';

const ListeMail = (props) => {


	useEffect(() => {
		startLoadThemen();
	}, []);

	useEffect(() => {
		console.log(props.teilmehmer);
	}, [props.teilmehmer]);

    const startLoadThemen = () => {
		props.dispatch(startReadTeilnehmer(idFpt, 'fo'));
	}

    return (
        <div>
            <table>
                {props.teilmehmer && props.teilmehmer.map((tln, i) => (
                    <tr key={i} >
                        <td style={{height: '76px'}}>
                            <MaxSizeImage imageStayle={{marginBottom: '16px'}} imageUrl={'https://fptfiles.rbsonline.de/logos/' + tln.logoFile} maxSize={{height: 60, width: 150}} />
                        </td>
                        <td>&nbsp;&nbsp;&nbsp;</td>
                        <td style={{verticalAlign: 'top'}}>
                            {tln.firmenname} <br/>
                            <a href={tln.www.startsWith('http') ? tln.www : 'http://' + tln.www}>{tln.www}</a>
                        </td>
                    </tr>

                ))}
            </table>
        </div>
    )
}

const mapStateToProps = state => ({
	teilmehmer: state.fpt[idFpt].teilnehmer,
});

export default connect(mapStateToProps)(ListeMail);