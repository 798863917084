import React from "react";
import { ListItem, ListItemText } from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";


const secondaryLight = '#fff'; //'#fc790d';

const SubNavListItemMain = (props) => {

    return (
        <ListItem button onClick={props.onClick}>
            <ListItemText
                primaryTypographyProps={{ style: { color: secondaryLight } }}
                primary={props.primary}
                secondaryTypographyProps={{ style: { color: secondaryLight } }}
                secondary={props.secondary}
            />
            {props.open ? <ExpandLess style={{ color: secondaryLight }} /> : <ExpandMore style={{ color: secondaryLight }} />}
        </ListItem>
    )
}

export default SubNavListItemMain;