import { Grid } from '@material-ui/core';
import React from 'react';

import { config } from '../../config/config';
import { dateToDisplayShort } from '../../helper/dateHelper';

const FptLegende = (props) => {

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6} lg={3}>
                V1: Vormittag {dateToDisplayShort(config[props.idFpt].tag1)} <br/>
                M1: Mittag {dateToDisplayShort(config[props.idFpt].tag1)} <br/>
                N1: Nachmittag {dateToDisplayShort(config[props.idFpt].tag1)} <br/>
                A1: Abend {dateToDisplayShort(config[props.idFpt].tag1)} <br/>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
                V2: Vormittag {dateToDisplayShort(config[props.idFpt].tag2)} <br/>
                M2: Mittag {dateToDisplayShort(config[props.idFpt].tag2)} <br/>
                N2: Nachmittag {dateToDisplayShort(config[props.idFpt].tag2)} <br/>
            </Grid>
        </Grid>

    );
}

export default FptLegende;