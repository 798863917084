import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'

import FptForceAnmeldung from '../../utils/FptForceAnmeldung';
import FptHeader from '../../utils/FptHeader';
import Grid from '@material-ui/core/Grid';
import MeineThemen from '../generic/MeineThemen';
import Typography from '@material-ui/core/Typography';



const idFpt = "fpt033";
const titel = 'Fachpressetage 2022';

const PageMeineThemen = (props) => {

  


    return (
        <React.Fragment>
            <FptHeader title={titel} />

            <FptForceAnmeldung idFpt={idFpt} fptTitel={titel}>
                
                <Typography variant="h4" gutterBottom>
                    Meine Themen 
                </Typography>

                <Grid container spacing={2}>
                    <Grid item xs={12} md={10} lg={8} xl={6}>

                        <MeineThemen idFpt={idFpt}/>

                    </Grid>

                </Grid>
            </FptForceAnmeldung>

        </React.Fragment>
    )
}



export default connect()(PageMeineThemen);