import React from 'react';
import { useHistory } from 'react-router-dom'

import { Tooltip, Typography } from '@material-ui/core';
import { config } from '../../config/config';


const ErgebnisChart = (props) => {

    const history = useHistory();

    const showDetail = (idThema) => {
        console.log(idThema);
        history.push('/' + config.currentFpt +  '/thema/' + idThema);
    }

    return (
        <div style={{ width: '600px' }}>

            {props.wahlergebnis.map((record) =>
                <Tooltip
                    key={record.idFptThema}                    
                    title={
                        <div>
                            <Typography variant='h6' gutterBottom>
                                {record.firmenname}
                            </Typography>
                            <Typography variant='body2' gutterBottom>
                                {record.titel}
                            </Typography>
                        </div>
                    }>
                    <button
                        style={{
                            textAlign: 'left',
                            border: 'none',
                            height: '42px',
                            marginBottom: '12px', 
                            paddingLeft: '5px', 
                            paddingTop: '5px', 
                            borderRadius: '5px',
                            width: record.zustimmungProzent + '%', 
                            backgroundColor: '#4d77cf',
                            color: '#fff'
                        }}
                        onClick={() => showDetail(record.idFptThema)}
                    >
                        <Typography variant='button'>
                            {record.firmenname.length > 25 ? record.firmenname.substring(0,25) + "..." : record.firmenname} - {record.zustimmungProzent} %
                        </Typography>
                    </button>
                </Tooltip>
            )}

        </div>
    )

}





export default ErgebnisChart;