import React from 'react';

import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';

import { jsonDateToChatDisplay } from '../../helper/dateHelper';

const imageUrl = 'https://fptfiles.rbsonline.de/personen/';


const ChatItem = (props) => {
    
    return (
        <Card square variant="outlined" key={props.idChatEntry}>
            <CardHeader
                avatar={
                    <Avatar src={props.avatar && imageUrl + props.avatar}/>
                }
                title={props.sender}
                subheader={jsonDateToChatDisplay(props.date)}
            />
            <CardContent>
                <Typography variant={props.bodyVariant ? props.bodyVariant : "body2"} color="textSecondary" component="p">
                    {props.message}
                </Typography>
            </CardContent>
        </Card>
    )
}


export default ChatItem;