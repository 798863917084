import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux'

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import EditableField from './EditableField'
import FptCard from './FptCard';
import { FilePond, File, registerPlugin } from 'react-filepond'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import { avatarUri } from '../../config/globals';
import { baseURL } from '../../config/webservice';
import { startDeleteAvatar, startSetAvatar, startSaveProfilPerson } from '../../actions/global';

// Import FilePond styles
import 'filepond/dist/filepond.min.css'


const FptProfilPersonCard = (props) => {

    
    useEffect(() => {   
        setFormData(props.profilPerson);
    }, [props.profilPerson]);


    const [touched, setTouched] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [formData, setFormData] = useState({});
    const [showUplaod, setShowUpload] = useState(false);
    const [files, setFiles] = useState([]);

    const bttnSaveClick = () => {
        setIsEdit(false);
        if (touched) {            
            console.log(formData);
            //props.dispatch(startSaveProfilPerson(formData));
            props.onSaveClick && props.onSaveClick(formData);
        }
    }

    const bttnBearbeitenClick = () => {
        setIsEdit(true);
    }

    const txtFieldChange = (key, value) => {
        setFormData({
            ...formData,
            [key]: value
        });
        setTouched(true);
    }

    const fileUploaded = (error, file) => {
        console.log(file);
        if (!error) {
            // relaod foto 

        }
    }

    const filePondOnLoad = (avatar) => {
        const bild = JSON.parse(avatar);
        console.log(bild)

        if(avatar){
            setFormData({
                ...formData,
                bild: bild
            });
            
            props.dispatch(startSetAvatar(bild));

            setShowUpload(false);
        }
        
    }

    const bttnRemoveAvatar = () => {
        props.dispatch(startDeleteAvatar(formData.idFptProfilPerson, deleteAvatarDone))
    }


    const deleteAvatarDone = () => {
        console.log("set form!!");
        setFormData({
            ...formData,
            bild: undefined
        });
    }


    return (
        <FptCard
            style={{ marginBottom: '16px' }}
            title={(formData.titel ? formData.titel + " " : "") + formData.vorname + " " + formData.nachname}
            buttons={
                props.readonly ? 
                    undefined : 
                    isEdit ?
                        <Button color='primary' onClick={bttnSaveClick}>Speichern</Button>
                        :
                        <Button color='secondary' onClick={bttnBearbeitenClick}>Bearbeiten</Button>
            }
        >
            <div style={{ margin: '16px 0' }}>
                {!showUplaod ?
                    <React.Fragment>
                        <Avatar style={{ width: '100px', height: '100px' }} src={formData.bild && avatarUri + formData.bild + "?" + Date.now()} />
                        {!props.readonly && (
                            formData.bild ?
                            <Button color='secondary' onClick={bttnRemoveAvatar}>Foto löschen</Button>
                            :
                            <Button color='secondary' onClick={() => setShowUpload(true)}>Foto hochladen</Button>
                        )}
                    </React.Fragment>
                :
                    <React.Fragment>
                        <FilePond
                            server={{
                                url: baseURL,
                                process: {
                                    url: `/api/Fpt/UploadAvatar/${formData.idFptProfilPerson}`,
                                    method: 'POST',
                                    withCredentials: false,
                                    headers: {
                                        'authorization': `Bearer ${props.user.token}`
                                    },
                                    onload: filePondOnLoad,
                                    onerror: null
                                }
                            }
                            }
                            onprocessfile={fileUploaded}
                            files={files}
                            onupdatefiles={fileItems => setFiles(fileItems.map(fileItem => fileItem.file))}
                            allowMultiple={false}
                            maxFiles={3}
                            instantUpload={true}
                            name="files"
                            labelIdle='Drag & Drop oder <span class="filepond--label-action">öffnen</span>'
                        />
                        <Button color='secondary' onClick={() => setShowUpload(false)}>Foto-upload abbrechen</Button>
                    </React.Fragment>
                }

            </div>

            <Table size={isEdit && 'small'}>
                <TableBody>
                    <TableRow>
                        <TableCell width='100px' component="th" scope="row">
                            Funktion:
                            </TableCell>
                        <TableCell >
                            <EditableField
                                value={formData.funktion}
                                name="funktion"
                                isEdit={isEdit}
                                onChange={txtFieldChange}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell width='100px' component="th" scope="row">
                            Telefon:
                        </TableCell>
                        <TableCell >
                            <EditableField
                                value={formData.telefonnummer}
                                name="telefonnummer"
                                isEdit={isEdit}
                                onChange={txtFieldChange}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell width='100px' component="th" scope="row">
                            E-Mail:
                    </TableCell>
                        <TableCell >
                            <EditableField
                                link='mailto:'
                                value={formData.email}
                                name="email"
                                isEdit={isEdit}
                                onChange={txtFieldChange}
                            />
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell width='100px' component="th" scope="row">
                            Xing-ID:
                            </TableCell>
                        <TableCell >
                            <EditableField
                                link={formData.xingId && 'xing'}
                                value={formData.xingId}
                                name="xingId"
                                isEdit={isEdit}
                                onChange={txtFieldChange}
                            />
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell width='100px' component="th" scope="row">
                            LinkedIn-ID:
                            </TableCell>
                        <TableCell >
                            <EditableField
                                link={formData.linkedInId && 'linkedIn'}
                                value={formData.linkedInId}
                                name="linkedInId"
                                isEdit={isEdit}
                                onChange={txtFieldChange}
                            />
                        </TableCell>
                    </TableRow>

                </TableBody>
            </Table>

        </FptCard>
    )

}

const mapStateToProps = state => ({
    user: state.global.user,
});

export default connect(mapStateToProps)(FptProfilPersonCard);


