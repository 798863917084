import { Grid, Tab, Tabs, Typography } from '@material-ui/core';
import { TabPanel } from '@material-ui/lab';
import React, { useState, useEffect } from 'react';
import FptForceAnmeldung from '../../utils/FptForceAnmeldung';
import FptHeader from '../../utils/FptHeader';
import FptProgrammTag from '../generic/FptProgrammTag';

const idFpt = 'fpt033';
const titel = 'Fachpressetage 2022';


const PageProgramm = (props) => {

    const [selectedTab, setSelectedTab] = useState(0);

    return (
        <React.Fragment>
            <FptHeader title={titel} />

            <FptForceAnmeldung idFpt={idFpt} fptTitel={titel}>

                <Grid container spacing={2}>
                    <Grid item xs={12} md={12} lg={8}>

                        <Typography variant="h4" gutterBottom>
                            Programm
                        </Typography>

                        <Tabs value={selectedTab} onChange={(e, value) => setSelectedTab(value)}>
                            <Tab label="Mittwoch 23.3." value={0}/>
                            <Tab label="Donnerstag 24.3" value={1}/>
                        </Tabs>

                        {selectedTab == 0 && <FptProgrammTag idFpt={idFpt} tag={'tag1'} xmlFile="fpt033_tag1.xml" />}
                        {selectedTab == 1 && <FptProgrammTag idFpt={idFpt} tag={'tag2'} xmlFile="fpt033_tag2.xml" />}
                    </Grid>
                </Grid>



            </FptForceAnmeldung>

        </React.Fragment>
    )
}

export default PageProgramm;