import axios from 'axios';
import { buildErrorMessage } from '../helper/errorHelper';
import { setLoading, setGlobalError } from './global';

const setZeitslots = (zeitslots) => ({
    type: 'SET_ZEITSLOTS',
    zeitslots
})

export const setGast = (gast) => ({
    type: 'SET_GAST',
    gast
})

export const setHost = (host) => ({
    type: 'SET_HOST',
    host
})

export const setMeineTermine = (meineTermine) => ({
    type: 'SET_MEINE_TERMINE',
    meineTermine
});

export const setGastTermine = (gastTermine) => ({
    type: 'SET_GAST_TERMINE',
    gastTermine
})


export const startGetZeitslots = ( idTage ) => {
    return (dispatch) => {
        dispatch(setLoading(true));
        const service = `/api/Fpt/GetZeitSlots`;
            
        axios.post(
            service, idTage
        ).then(res => {      
            dispatch(setLoading(false));                  
            dispatch(setZeitslots(res.data));
        }).catch((error) => { 
            dispatch(setGlobalError(buildErrorMessage(error)));            
        });
    };
}


export const readReadTelnehmerPersonMeeting = ( idProfilPerson, idAuftragFpt, isJournalist, isHost ) => {
    return (dispatch) => {
        isHost ? dispatch(setHost(undefined)) : dispatch(setGast(undefined));
        dispatch(setLoading(true));
        const service = `/api/Fpt/ReadTelnehmerPerson/${idProfilPerson}/${idAuftragFpt}/${isJournalist}`;
            
        axios.get(
            service
        ).then(res => {      
            dispatch(setLoading(false));     
            isHost ? dispatch(setHost(res.data)) : dispatch(setGast(res.data));
        }).catch((error) => { 
            dispatch(setGlobalError(buildErrorMessage(error)));            
        });
    };
}


export const startEinladungAussprechen = ( meeting, callbackSuccess ) => {
    return (dispatch) => {
        dispatch(setLoading(true));
        const service = `/api/Fpt/EinladungAussprechen`;
            
        axios.put(
            service, meeting
        ).then(res => {      
            dispatch(setLoading(false));     
            callbackSuccess && callbackSuccess(res.data)
        }).catch((error) => { 
            dispatch(setGlobalError(buildErrorMessage(error)));            
        });
    };
}


export const startGetMeineTermine = ( idProfilPerson ) => {
    return (dispatch) => {
        dispatch(setLoading(true));
        const service = `/api/Fpt/GetMeetingsByPerson/${idProfilPerson}`;
            
        axios.get(
            service
        ).then(res => {      
            dispatch(setLoading(false));     
            dispatch(setMeineTermine(res.data))
        }).catch((error) => { 
            dispatch(setGlobalError(buildErrorMessage(error)));            
        });
    };
}

export const startGetTermineGast = ( idProfilPerson ) => {
    return (dispatch) => {
        dispatch(setGastTermine([]))
        dispatch(setLoading(true));
        const service = `/api/Fpt/GetMeetingsByPerson/${idProfilPerson}`;
            
        axios.get(
            service
        ).then(res => {      
            dispatch(setLoading(false));     
            dispatch(setGastTermine(res.data))
        }).catch((error) => { 
            dispatch(setGlobalError(buildErrorMessage(error)));            
        });
    };
}


export const startCancelMeeting = ( idFptMeeting, callbackSuccess ) => {
    return (dispatch) => {
        dispatch(setLoading(true));
        const service = `/api/Fpt/CancelMeeting/${idFptMeeting}`;
            
        axios.put(
            service
        ).then(res => {      
            dispatch(setLoading(false));     
            callbackSuccess && callbackSuccess(res.data);
        }).catch((error) => { 
            dispatch(setGlobalError(buildErrorMessage(error)));            
        });
    };
}

export const startMeetingBestaetigen = ( idFptMeeting, typ, idProfilPerson, callbackSuccess ) => {
    return (dispatch) => {
        dispatch(setLoading(true));
        const service = `/api/Fpt/MeetingBestaetigen/${idFptMeeting}/${typ}`;
            
        axios.put(
            service
        ).then(res => {      
            dispatch(setLoading(false));                 
            dispatch(startGetMeineTermine(idProfilPerson));
            callbackSuccess && callbackSuccess(res.data);
        }).catch((error) => { 
            dispatch(setGlobalError(buildErrorMessage(error)));            
        });
    };
}

export const startSetBlockout = ( idPerson, idZeitslot, callbackSuccess ) => {
    return (dispatch) => {
        dispatch(setLoading(true));
        const service = `/api/Fpt/MeetingSetBlockout/${idPerson}/${idZeitslot}`;
            
        axios.put(
            service
        ).then(res => {      
            dispatch(setLoading(false));     
            startGetMeineTermine(idPerson)
            //callbackSuccess && callbackSuccess(res.data);
        }).catch((error) => { 
            dispatch(setGlobalError(buildErrorMessage(error)));            
        });
    };
}

export const startRemoveBlockout = ( idPerson, idZeitslot, callbackSuccess ) => {
    return (dispatch) => {
        dispatch(setLoading(true));
        const service = `/api/Fpt/MeetingRemoveBlockout/${idPerson}/${idZeitslot}`;
            
        axios.put(
            service
        ).then(res => {      
            dispatch(setLoading(false));     
            startGetMeineTermine(idPerson)
            //callbackSuccess && callbackSuccess(res.data);
        }).catch((error) => { 
            dispatch(setGlobalError(buildErrorMessage(error)));            
        });
    };
}

export const downloadIcs = (idFptMeeting) => {
    return (dispatch, getState) => {

        const baseurl = axios.defaults.baseURL;
        const service = `${baseurl}/api/Fpt/DownloadMeetingIcs/${idFptMeeting}`;
        const filename = "fachpressetage.ics";
 
        fetch(service)
            .then(response => {
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = filename;
                    a.click();
                });
                //window.location.href = response.url;
            })
            .catch((error) => {
                console.log(error);
                dispatch(setGlobalError('Fehler beim laden des Kalender ics: ' + error.message));
            })
    }
}


export const startSendMessage = ( idPersonSource, idPersonTarget, message, callbackSuccess ) => {
    return (dispatch) => {
        dispatch(setLoading(true));
        const service = `/api/Fpt/SendMessage/${idPersonSource}/${idPersonTarget}`;
            
        axios.put(
            service, {message}
        ).then(res => {      
            dispatch(setLoading(false));     
            callbackSuccess && callbackSuccess(res.data)
        }).catch((error) => { 
            dispatch(setGlobalError(buildErrorMessage(error)));            
        });
    };
}
