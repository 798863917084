import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { connect } from 'react-redux'

import Button from '@material-ui/core/Button';
import FptForceAnmeldung from '../../utils/FptForceAnmeldung';
import FptProfilPersonCard from '../../utils/FptProfilPersonCard';
import FptProfilFirmaCard from '../../utils/FptProfilFirmaCard';
import FptHeader from '../../utils/FptHeader';
import Grid from '@material-ui/core/Grid';

import { startGetAnwesenheitenByPersonFpt, startReadAllePersonen, startReadProfileFirmaByUser, startReadTeilnehmerPersonen, startReadThemenByFirmen } from '../../../actions/fpt';
import { startSaveProfilPerson, startReadProfilPerson } from '../../../actions/fpt';
import { config } from '../../../config/config';
import FptThemaCard from '../../utils/FptThemaCard';
import FptWerWannListe from '../../utils/FptWerWannListe';
import FptCard from '../../utils/FptCard';
import FptVideoCard from '../../utils/FptVideoCard';

const idFpt = 'fpt034';
const fptTitel = "Fachpressetage 2023";

const PageProfil = (props) => {

	const { idPerson } = useParams();

	useEffect(() => {
		loadProfilPerson(idPerson)
		loadFirmenPerson(idPerson);
		if (props.allePersonen.length == 0) {
			props.dispatch(startReadAllePersonen(idFpt, true));
		}
	}, [idPerson]);

	useEffect(() => {
		if (props.allePersonen) {
			setSelectedPerson(props.allePersonen.find(p => p.idFptProfilPerson == idPerson));
		}
	}, [props.allePersonen]);


	const [selectedPerson, setSelectedPerson] = useState(undefined);


	const loadProfilPerson = (id) => {
		console.log('Load Profile', id);
		props.dispatch(startReadProfilPerson(id), onPersonLoaded);
	}

	const loadFirmenPerson = (idProfilPerson) => {
		props.dispatch(startReadProfileFirmaByUser(idProfilPerson, idFpt, false, onFirmenLoaded))
	}

	const onPersonLoaded = (person) => {
		if (person.idFptTeilnehmerTyp.includes('j')) {
			// loadteilnahme(person.idFptProfilPerson)
		}
	}

	const onFirmenLoaded = (firmen) => {
		console.log(firmen);
		const idFirmen = firmen.map(f => f.idFirma);

		props.dispatch(startReadThemenByFirmen(idFirmen, idFpt));
		console.log("startGetAnwesenheitenByPersonFpt", idPerson, idFpt)
		props.dispatch(startGetAnwesenheitenByPersonFpt(idPerson, idFpt, false));
	}

	const bttnEinladenClick = () => {
		props.history.push(`/fpt032/einladen/${idPerson}/${idFpt}/${!!props.profilPerson.idFptTeilnehmerTyp.includes('j')}/false`)
	}



	return (

		<React.Fragment>
			<FptHeader title="Profil" />

			<FptForceAnmeldung idFpt={idFpt} fptTitel={fptTitel}>
				{props.profilPerson && config[idFpt].meetingsOpen &&
					<div style={{ marginBottom: '24px' }}>
						<Button color="primary" variant="contained" onClick={bttnEinladenClick}>
							{(props.profilPerson.titel ? props.profilPerson.titel + " " : "") + props.profilPerson.vorname + " " + props.profilPerson.nachname} zum Gespräch einladen
						</Button>
					</div>
				}

				<Grid container spacing={2}>

					{props.profilPerson &&
						<React.Fragment>
							<Grid item xs={12} md={6} xl={4}>
								<FptProfilPersonCard
									readonly={true}
									profilPerson={props.profilPerson}
								/>
							</Grid>

							{props.profileFirma && props.profileFirma.map(firma =>
								<Grid key={firma.idFptProfilFirma} item xs={12} md={6} xl={4}>
									<FptProfilFirmaCard readonly={true} profilFirma={firma} isRedaktion={props.profilPerson.idFptTeilnehmerTyp.includes('j')} />
								</Grid>
							)}
						</React.Fragment>
					}


					{props.profilPerson && !props.profilPerson.idFptTeilnehmerTyp.includes('j') && !props.profilPerson.idFptTeilnehmerTyp.includes('f') &&
						<React.Fragment>
							FEHLER: Person ist nicht angemeldet!
						</React.Fragment>
					}
				</Grid>

				{selectedPerson && !selectedPerson.nurOnline &&
					<Grid container spacing={2}>
						<Grid item xs={12} xl={8}>
							<FptCard
								style={{ marginBottom: '16px' }}
								title="Anwesenheiten"
							>
								<FptWerWannListe
									anwesenheiten={props.anwesenheiten && props.anwesenheiten.filter(a => !a.nurAnsprechpartner)}
									idFpt={idFpt}
									hideFilter
								/>
							</FptCard>
						</Grid>
					</Grid>
				}

				{props.themen && props.themen.map(thema =>
					<Grid key={thema.idFptThema} container spacing={2}>
						<Grid item xs={12} md={6} xl={4}>
							<FptThemaCard thema={thema} />
						</Grid>


						{thema && thema.youtubeId &&
							<Grid item xs={12} md={6} xl={4}>
								<FptVideoCard thema={thema} />
							</Grid>
						}

					</Grid>
				)}


			</FptForceAnmeldung>

		</React.Fragment>
	);

}


const mapStateToProps = state => ({
	profilPerson: state.fpt.profilPersonSelected,
	profileFirma: state.fpt.profileFirmaSelected,
	themen: state.fpt.themenSelected,
	anwesenheiten: state.fpt.anwesenheitenSelected,
	allePersonen: state.fpt[idFpt].allePersonen,
});

export default connect(mapStateToProps)(PageProfil);
