import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux'

import logo from './header-logo.png'

import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';

// Icons
import PersonIcon from '@material-ui/icons/Person';

import { setMobileOpen } from '../../actions/global';
import { config } from '../../config/config';
import SubNavigation2024 from './SubNavigation2024';
import SubNavigation2025 from './SubNavigation2025';


const useStyles = makeStyles((theme) => ({
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,

    menuIcon: {
        minWidth: 'auto',
        paddingRight: '12px',
    },
}));


const secondaryLight = '#fff'; //'#fc790d';
const iconColor = '#fff';
const dividerStyle = { backgroundColor: 'rgba(255, 255, 255, 0.4)', margin: '0 10px' };


const MainNavigation = (props) => {

    const [meineDatenOpen, setMeineDatenOpen] = useState(false);

    const classes = useStyles();
    const history = useHistory();

    const bttnHomeClick = () => {
        history.push('/');
    }

    const openPage = (target) => {
        props.dispatch(setMobileOpen(false));
        history.push(target);
    }

    const ListItemMain = (props) => {
        return (
            <ListItem button onClick={props.onClick}>
                <ListItemText
                    primaryTypographyProps={{ style: { color: secondaryLight } }}
                    primary={props.primary}
                    secondaryTypographyProps={{ style: { color: secondaryLight } }}
                    secondary={props.secondary}
                />
                {props.open ? <ExpandLess style={{ color: secondaryLight }} /> : <ExpandMore style={{ color: secondaryLight }} />}
            </ListItem>
        );
    }

    return (

        <div>
            <div className={classes.toolbar} onClick={bttnHomeClick} style={{ paddingLeft: '16px', paddingTop: '16px' }}>
                <img src={logo} style={{ marginLeft: '0px' }} />
            </div>

            <List>

                <SubNavigation2025
                    user={props.user}
                    onOpenPage={openPage}
                    defaultOpen={true}
                />

                <Divider style={dividerStyle} />

                <SubNavigation2024
                    user={props.user}
                    onOpenPage={openPage}
                    defaultOpen={false}
                />

                <Divider style={dividerStyle} />

            </List>

            <List>
                <ListItemMain
                    onClick={() => setMeineDatenOpen(!meineDatenOpen)}
                    primary="Meine Daten"
                    secondary=""
                    open={meineDatenOpen}
                />
                <Collapse in={meineDatenOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem button onClick={() => openPage('/meineDaten')}>
                            <ListItemIcon className={classes.menuIcon}><PersonIcon style={{ color: iconColor }} /></ListItemIcon>
                            <ListItemText primary="Mein Profil" />
                        </ListItem>
                        <ListItem button onClick={() => openPage(`/${config.currentFpt}/werWann/`)}>
                            <ListItemIcon className={classes.menuIcon}><PersonIcon style={{ color: iconColor }} /></ListItemIcon>
                            <ListItemText primary="Meine Anwesenheiten" />
                        </ListItem>
                        {/* 
                        <ListItem button onClick={() => openPage('/fpt032/blockout')}>
                            <ListItemIcon className={classes.menuIcon}><PersonIcon style={{ color: iconColor }} /></ListItemIcon>
                            <ListItemText primary="Meine Blockouts" />
                        </ListItem>
                        */}
                    </List>
                </Collapse>
            </List>

        </div>

    )
}


const mapStateToProps = state => ({
    user: state.global.user,
})

export default connect(mapStateToProps)(MainNavigation);