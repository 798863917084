import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'

import CircularProgress from '@material-ui/core/CircularProgress';
import FptForceAnmeldung from '../../utils/FptForceAnmeldung';
import FptHeader from '../../utils/FptHeader';
import { FptList, FptListItem } from '../../utils/FptList';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { startReadThemenliste } from '../../../actions/fpt';
import { logoUri } from '../../../config/globals';

const idFpt = 'fpt034';
const titel = 'Fachpressetage 2023';

const PageThemen = (props) => {

	useEffect(() => {
		startLoadThemen();
	}, []);

	const [filter, setFilter] = useState('');

	const startLoadThemen = () => {
		props.dispatch(startReadThemenliste(idFpt));
	}

	const openThema = (id) => {
		props.history.push(`/${idFpt}/thema/${id}`);
	}

	return (
		<React.Fragment>
			<FptHeader title={titel} />

			<FptForceAnmeldung idFpt={idFpt} fptTitel={titel}>

				<Grid container spacing={0}>
					<Grid item sm={12} md={10} lg={8} xl={6}>

						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Typography variant="h4" gutterBottom>
									Firmen &amp; Themen
								</Typography>
							</Grid>
						</Grid>

						<Grid container spacing={2}>
							<Grid item xs={12}>
								<TextField
									autoFocus={false}
									fullWidth
									label="Suche"
									variant="outlined"
									value={filter}
									onChange={(e) => setFilter(e.target.value)}
								/>
							</Grid>
						</Grid>

						<Grid container spacing={2}>
							<Grid item xs={12}>

								<FptList>
									{props.themen ?
										props.themen
											.sort((a, b) => (a.firmenname.toUpperCase() < b.firmenname.toUpperCase() ? -1 : 1))
											.filter(thema => thema.idFptVeranstaltung != 149)
											.filter(thema => filter ?
												thema.firmenname.toUpperCase().includes(filter.toUpperCase()) ||
												(thema.titel && thema.titel.toUpperCase().includes(filter.toUpperCase())) : true)
											.map(thema => (
												<FptListItem
													key={thema.idFptThema}
													primaryText={thema.firmenname}
													secondaryText={thema.titel}
													image={thema.logoFile && logoUri + thema.logoFile + "?v=" + (new Date()).getTime()}
													tooltipRechts={thema.veranstaltung}
													iconRechts={thema.veranstaltung.includes('Pressekonferenz') ? 'speaker_notes' : 'group'}
													chipRechts={
														thema.veranstaltung.includes('Pressekonferenz') ?
															'Fachvortrag' :
															thema.veranstaltung.includes('tisch') && 'Gesprächs-Tisch'
													}
													chipStyle={
														thema.veranstaltung.includes('Pressekonferenz') ?
															{backgroundColor: '#1976d2', color: '#fff' } :
															thema.veranstaltung.includes('tisch') && {backgroundColor: '#fc790d', color: '#fff' }
													}
													onClick={() => openThema(thema.idFptThema)}
												/>
											))
										:
										<CircularProgress />
									}
								</FptList>

							</Grid>
						</Grid>

					</Grid>
				</Grid>

			</FptForceAnmeldung>
		</React.Fragment>
	);

}


const mapStateToProps = state => ({
	themen: state.fpt[idFpt].themen,
});

export default connect(mapStateToProps)(PageThemen);
