import React, { useEffect } from 'react';
import { connect } from 'react-redux'

import { startReadFile, startReadFotos } from '../../actions/fpt';
import { Popover, Typography } from '@material-ui/core';


/**
 * Zeigt eine Themenseite an
 * 
 * @param {object} props Component props
 * @param {object} props.firma (required)
 */
const FptFotosSharepoint = (props) => {

    const [fotos, setFotos] = React.useState([]);

    useEffect(() => {
        props.thema && props.thema.idFptThema && loadFotos();
    }, []);

    const loadFotos = () => {
        props.dispatch(startReadFotos(props.thema.idFptThema, onFotosLoaded));
    }

    const onFotosLoaded = (fotos) => {
        console.log(fotos);
        setFotos(fotos);
    }

    const getDownloadData = (id) => {
        props.dispatch(startReadFile(id, onFileLoaded));
    }

    const onFileLoaded = (file) => {
        console.log(file.downloadUrl);
        window.open(file.downloadUrl, '_blank');
    }


    return (
        <div>
            <Typography style={{ marginTop: '16px' }} variant="h5" component="h2">
                Fotos
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
                Zum Download anklicken
            </Typography>
            {fotos.length == 0 &&
                <Typography variant="body2" gutterBottom>
                    Keine Fotos vorhanden.
                </Typography>
            }
            {fotos.map(foto => {
                return (
                    <div
                        key={foto.id}
                        onClick={() => getDownloadData(foto.id)}
                        className='rbs-foto-thubnail-container'
                    >
                        <img
                            src={foto.thumbnailUrl}
                            style={{ maxHeight: '117px', maxWidth: '117px', objectFit: 'cover', aspectRatio: '1/1' }} />
                    </div>
                )
            }
            )}
            <div style={{ clear: 'both' }}></div>
        </div>
    )

}

export default connect()(FptFotosSharepoint);
