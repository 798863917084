import React, { useState } from "react";
import { connect } from 'react-redux';

import { Avatar, Button, Container, TextField } from "@material-ui/core";
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import FptLink from "./FptLink";
import { startLogin } from "../../actions/global";


const LoginControl = (props) => {

    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');

    const bttnLoginClick = () => {
        props.dispatch(startLogin(userName, password));
    }

    const txtUsernameChange = (e) => {
        setUserName(e.target.value)
    }

    const txtPasswordKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.stopPropagation();
            props.dispatch(startLogin(userName, password));
        }
    }

    const txtPasswordChange = (e) => {
        setPassword(e.target.value)
    }

    return (
        <div>
            <Avatar style={{ margin: '10px auto', backgroundColor: '#1976d2', }} >
                <LockOutlinedIcon />
            </Avatar>
            <form style={{ width: '100%', marginTop: '8px', }} noValidate>
                <TextField
                    autoFocus={true}
                    key="txtUsername"
                    fullWidth
                    margin="normal"
                    label="Benutzer"
                    variant="outlined"
                    value={userName}
                    onChange={txtUsernameChange} />
                <TextField
                    fullWidth
                    key="txtPassword"
                    margin="normal"
                    variant="outlined"
                    label="Passwort"
                    type="password"
                    value={password}
                    onChange={txtPasswordChange}
                    onKeyDown={txtPasswordKeyDown} />
                <Button
                    fullWidth
                    onClick={bttnLoginClick}
                    variant="contained"
                    style={{ margin: '16px 0 16px 0' }}
                    size="large"
                    color="primary">
                    Login
                </Button>
            </form>
            {!props.hideForgotPwd &&
                <React.Fragment>
                    <FptLink to="/password">Passwort vergessen?</FptLink>
                    <FptLink to="/signup">Noch kein Zugang? Hier anmelden!</FptLink>
                </React.Fragment>
            }

        </div>
    )
}

export default connect()(LoginControl);
