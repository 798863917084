import React from 'react';
import { useParams } from "react-router-dom";

import FptHeader from '../utils/FptHeader';
import FptForceAnmeldung from '../utils/FptForceAnmeldung';
import FptThema from './generic/FptThemaSeite';




const PageRbsThema = (props) => {

	const { idFpt, idThema } = useParams();
	const fptTitel = "Interne Ansicht " + idFpt + " idThema=" + idThema;

	return (
		<React.Fragment>
			<FptHeader title={fptTitel} />

			<FptForceAnmeldung idFpt={idFpt} fptTitel={fptTitel}>

				<FptThema inlineFiles idThema={idThema} download={true} idFpt={idFpt} hideFotos={false} hideInvitation={true}/>

			</FptForceAnmeldung>
		</React.Fragment>
	);

}

export default PageRbsThema;
