const fptReducerDefaultState = {
    chatEntries: [],   
};

const chatReducer = (state = fptReducerDefaultState, action) => {    
    switch (action.type){
 
        case 'SET_CHAT_ENTRIES':
            {
                return {
                    ...state,
                    chatEntries: [
                        ...action.chatEntries,
                        ...state.chatEntries,
                    ],
                }
            }     
        case 'SET_RELOAD':
            {
                return {
                    ...state,
                    reload: action.reload
                }
            }
        default:
            return state;
    }
}



export default chatReducer;