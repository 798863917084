const meetingeducerDefaultState = {
    error: false,   
    zeitslots: [],
};

const meetingReducer = (state = meetingeducerDefaultState, action) => {    
    switch (action.type){
 
        case 'SET_ZEITSLOTS':
            {
                return {
                    ...state,
                    zeitslots: action.zeitslots,
                }
            }     
        case 'SET_GAST':
            {
                return {
                    ...state,
                    gast: action.gast,
                }
            }
        case 'SET_HOST':
            {
                return {
                    ...state,
                    host: action.host
                }
            }
        case 'SET_MEINE_TERMINE':
            {
                return {
                    ...state,
                    meineTermine: action.meineTermine
                }
            }
        case 'SET_GAST_TERMINE':
            {
                return {
                    ...state,
                    gastTermine: action.gastTermine,
                }
            }
        default:
            return state;
    }
}



export default meetingReducer;