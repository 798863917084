import React from "react";

import { ListItemIcon } from "@material-ui/core";

const SubNavItemIcon = (props) => {

    return (
        <ListItemIcon sx={{ minWidth: 'auto', paddingRight: '12px' }} >
            {props.children}
        </ListItemIcon>
    );
}

export default SubNavItemIcon;