import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'

import FptThemaEditCard from '../../utils/FptThemaEditCard';

import { startReadMeineThemen, startReadTeilnehmerPersonen, setMeineThemen } from '../../../actions/fpt';
import FptThemaUpload from '../../utils/FptThemaUpload';
import { Grid } from '@material-ui/core';


const MeineThemen = (props) => {

    useEffect(() => {
        startLoadThemen();
        // Mitarbeiter laden. Die brauchen wir für das Referenten DD
        startLoadMitarbeiter();
    }, []);

    const startLoadThemen = () => {

        props.dispatch(setMeineThemen(props.idFpt, []));
        // const profil = props.profileFirma.find(profil => profil.idAuftragFpt.includes(props.idFpt));
        // props.dispatch(startReadMeineThemen(profil.idFptProfilFirma, props.idFpt));

        const profile = props.profileFirma.filter(profil => profil.idAuftragFpt.includes(props.idFpt));
        if (profile) {
            profile.forEach(profil => {
                props.dispatch(startReadMeineThemen(profil.idFptProfilFirma, props.idFpt));
            });
        }
    }

    const startLoadMitarbeiter = () => {
        //const profil = props.profileFirma.find(profil => profil.idAuftragFpt.includes(props.idFpt));
        //props.dispatch(startReadTeilnehmerPersonen(profil.idFptProfilFirma, props.idFpt))
        const profile = props.profileFirma.filter(profil => profil.idAuftragFpt.includes(props.idFpt));
        if (profile) {
            profile.forEach(profil => {
                props.dispatch(startReadTeilnehmerPersonen(profil.idFptProfilFirma, props.idFpt));
            });
        }
    }

    return (
        <Grid container spacing={3}>
            {console.log('###############', props.fpt[props.idFpt].meineThemen)}
            {props.fpt[props.idFpt].meineThemen && props.fpt[props.idFpt].meineThemen.map(thema =>

                <React.Fragment key={thema.idFptThema}>
                    <Grid item xs={12} lg={6} xl={6}>
                        <FptThemaEditCard
                            key={thema.idFptThema}
                            thema={thema}
                            idFpt={props.idFpt}
                            hideUpload={true}
                        />
                    </Grid>
                    <Grid item xs={12} lg={6} xl={6}>
                        <FptThemaUpload thema={thema} />
                    </Grid>
                </React.Fragment>

            )}
        </Grid>
    )

}


const mapStateToProps = state => ({
    //profilFirma: state.global.teilnehmerFirma[0],
    profileFirma: state.global.teilnehmerFirma,
    fpt: state.fpt
});


export default connect(mapStateToProps)(MeineThemen);