import React from 'react';

import Snackbar from '@material-ui/core/Snackbar';
import RbsAlert from './RbsAlert';

/**
 * Component, das eine Info/Fehler/Alert anzeigt
 * 
 * @param {object} props Component props
 * @param {string} props.message to display
 * @param {boolean} props.isOpen
 * @param {function} props.onClose handle alert close
 */
const RbsAlertSnack = ({message, isOpen, onClose, severity, vertical = 'bottom', horizontal = 'left', autoHideDuration}) => {

    const bttnErrorClose = () => {
        onClose && onClose(true);
    }

    return (
        <Snackbar
            anchorOrigin={{ 
                vertical: vertical, 
                horizontal: horizontal 
            }}
            open={isOpen? isOpen : false}
            autoHideDuration={autoHideDuration}
            onClose={bttnErrorClose}
        >
            <RbsAlert
                severity={severity}
                message={message}
                onClose={bttnErrorClose}
            />

        </Snackbar>        
    );
}

export default RbsAlertSnack;