import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';

import { Alert } from '@material-ui/lab';
import FptBewertungThemen from './generic/FptBewertungThemen';
import FptForceAnmeldung from '../utils/FptForceAnmeldung';
import FptHeader from '../utils/FptHeader';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconYes from '@material-ui/icons/CheckCircleOutline';
import IconEnthaltung from '@material-ui/icons/RemoveCircleOutline';
import IconNo from '@material-ui/icons/CancelOutlined';

import { startReadBewertungErgebnis, startReadMeineBewertungen, startReadThemenliste } from '../../actions/fpt';
import { startLoginUid, logout } from '../../actions/global';
import { config } from '../../config/config';
import ErgebnisChart from '../utils/ErgebnisChart';


const idFpt = "fpt036";
const titel = 'Fachpressetage 2025 - Vorträge bewerten';

const PageWahl = (props) => {

    const { id } = useParams();

    useEffect(() => {
        id && props.dispatch(startLoginUid(id));
        //window.addEventListener('beforeunload', leavingPage)

        return () => {
            console.log('unmounting...');
            //window.removeEventListener('beforeunload', leavingPage)
            props.dispatch(logout());
        }
    }, []);

    useEffect(() => {
        if (props.user) {
            !props.fpt[idFpt].meineBewertungen && loadBewertungen();
            !props.fpt[idFpt].themen && loadThemen();
            loadWahlergebnis(idFpt);
        }
    }, [props.user]);

    const leavingPage = () => {
        props.dispatch(logout());
    }

    const loadBewertungen = () => {
        props.dispatch(startReadMeineBewertungen(props.user.idFptProfilPerson, idFpt));
    }

    const loadThemen = () => {
        props.dispatch(startReadThemenliste(idFpt))
    }

    const loadWahlergebnis = (idFpt) => {
        props.dispatch(startReadBewertungErgebnis(idFpt));
    }


    return (
        <React.Fragment>

            <FptHeader title={titel} />

            {config[idFpt].votingOpen ?
                <React.Fragment>
                    <Alert severity="info">
                        Entscheiden Sie mit, welche Vorträge ins Programm aufgenommen werden sollen.
                        <br />
                        <IconNo fontSize="small" /> soll nicht ins Programm<br />
                        <IconEnthaltung fontSize="small" /> Enthaltung (z.B. nicht mein Themengebiet)<br />
                        <IconYes fontSize="small" /> soll ins Programm<br />
                        <br />
                        Klicken Sie auf den jeweiligen Titel, um alle Details (+ Abstract) zu sehen und einen Kommentar einzutragen.
                    </Alert>

                    <Grid container spacing={2}>
                        <Grid item xs={12} md={10} lg={8} xl={6}>

                            <FptBewertungThemen onNewDataNeeded={() => loadBewertungen()} idFpt={idFpt} />

                        </Grid>

                    </Grid>
                </React.Fragment>
                :

                <Grid item xs={12} md={10} lg={8} xl={6}>
                    <div style={{ display: 'table', margin: '0 auto', paddingBottom: '20px', paddingTop: '10px' }}>
                        {!config[idFpt].ergebnisAnzeigen &&
                            <img src="/images/closed.png" />
                        }
                    </div>
                    <Typography variant='h5' gutterBottom>
                        Das Wahllokal ist geschlossen. Alle Stimmen, die bis zum 16.11.2022 abgegeben wurden, werden gezählt!
                    </Typography>

                    <br/><br/>
                    {config[idFpt].ergebnisAnzeigen &&
                        <React.Fragment>
                            <Typography variant='h4' gutterBottom>
                                Wahlergebnis
                            </Typography>
                            <Typography variant='body2' gutterBottom>
                                Hinweis: Aus Gründen der Diskretion sehen Sie abgelehnte Themen nicht in der Liste
                                <br/><br/>
                            </Typography>
                            <ErgebnisChart wahlergebnis={props.fpt[idFpt].wahlergebnis} />
                        </React.Fragment>
                    }

                </Grid>
            }


        </React.Fragment>
    )
}


const mapStateToProps = state => ({
    user: state.global.user,
    fpt: state.fpt,
});

export default connect(mapStateToProps)(PageWahl);
