//export const baseURL = 'https://localhost:8001';
export const baseURL = 'https://meinrbs.rbsonline.de';




/*
import axios from 'axios';

//export const baseURL = 'https://192.168.230.10:8001';

export default () => {
    //axios.defaults.baseURL = 'https://192.168.230.10:8001';
    //axios.defaults.baseURL = 'https://localhost:8001';
    axios.defaults.baseURL = 'https://erp.meinrbs.de:9902';
    axios.defaults.headers.post['Content-Type'] = 'application/json';
    axios.defaults.headers.post['Accept'] = 'application/json';
}*/
