import React from "react";
import FptThemaFiles from "./FptThemaFiles";
import FptCard from "./FptCard";
import { Button } from "@material-ui/core";
import { Alert } from "@material-ui/lab";


const FptDownloadCard = (props) => {

    return (
        <FptCard
            title="Download Pressetext"
        >
            {props.download ?
                <FptThemaFiles float={"left"} thema={props.thema} idThema={props.idThema} />
                :
                <Alert severity="warning">Der Download-Bereich wird erst am Tag vor den Fachpressetagen freigeschaltet.</Alert>
            }
        </FptCard>
    )
}

export default FptDownloadCard;