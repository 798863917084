import { createStore, combineReducers, applyMiddleware, compose } from 'redux';

import fptReducer from '../reducers/fptReducer';
import globalReducer from '../reducers/globalReducer';
import chatReducer from '../reducers/chatReducer';
import meetingReducer from '../reducers/meetingReducer';

import thunk from 'redux-thunk';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default () => {
    // Store createion 
    const store = createStore(
        combineReducers({
            global: globalReducer,
            fpt: fptReducer,
            chat: chatReducer,
            meeting: meetingReducer
        }),
        composeEnhancers(applyMiddleware(thunk))
    );

    return store;
};

