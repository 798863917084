import axios from 'axios';
import { buildErrorMessage } from '../helper/errorHelper';

export const setUser = (user) => ({
    type: 'SET_USER',
    user
});

export const setFavoriten = (favoriten) => ({
    type: 'SET_FAVORITEN',
    favoriten
});

export const setLoading = (value) => ({
    type: 'SET_LOADING',
    value,
});

export const setQuickMenuVisible = (value) => ({
    type: 'SET_QUICKMENU_VISIBLE',
    value,
});

export const setTabMenuVisible = (value) => ({
    type: 'SET_TABMENU_VISIBLE',
    value,
});

export const setGotoVisible = (value) => ({
    type: 'SET_GOTO_VISIBLE',
    value,
});

export const setGlobalError = (message) => ({
    type: 'SET_GLOBAL_ERROR',
    message
})


export const setGlobalWarning = (message) => {
    return (
        {
            type: 'SET_GLOBAL_WARNING',
            message
        });
}

export const setGlobalSuccess = (message) => ({
    type: 'SET_GLOBAL_SUCCESS',
    message
})

export const resetGlobalError = () => ({
    type: 'RESET_GLOBAL_ERROR'
})

export const resetGlobalSuccess = () => ({
    type: 'RESET_GLOBAL_SUCCESS'
})

export const resetGlobalWarning = () => ({
    type: 'RESET_GLOBAL_WARNING'
})

export const setAllMitarbeiter = (mitarbeiter) => ({
    type: 'SET_ALL_MITARBEITER',
    mitarbeiter
})

export const setPageTitle = (pageTitle) => ({
    type: 'SET_PAGE_TITLE',
    pageTitle
})

export const setMobileOpen = (mobileOpen) => ({
    type: 'SET_MOBILE_OPEN',
    mobileOpen
})

export const setTeilnehmerRedakteur = (teilnehmerRedakteur) => ({
    type: 'SET_TEILNEHMER_REDAKTEUR',
    teilnehmerRedakteur
})

export const setInitialized = (initialized) => ({
    type: 'SET_INITIALIZED',
    initialized
})

export const setTeilnehmerFirma = (teilnehmerFirma) => ({
    type: 'SET_TEILNEHMER_FIRMA',
    teilnehmerFirma
})

export const setAvatar = (bild) => ({
    type: 'SET_AVATAR',
    bild
})


export const startLogin = (user, password) => {
    return (dispatch, getState) => {

        const service = `/api/Auth/LoginFpt`;

        axios.post(
            service, {
            username: user,
            password: password
        }
        ).then(res => {
            // Wir setzen den Bearer
            axios.defaults.headers.common = { 'Authorization': `Bearer ${res.data.token}` }
            // Der User wird lokal gespeichert für einen Reload
            localStorage.setItem('fptLoginUser', JSON.stringify(res.data));
            dispatch(setUser(res.data));

            // Wir laden automatisch die Teilnehmer, das sind die Teilnahmen an einem bestimmten FPT
            dispatch(startLoadTeilnehmer(res.data));

        }).catch((error) => {
            //  console.log('Login fehler', error); 
            dispatch(setGlobalError('Fehler beim beim Login: ' + buildErrorMessage(error)));
        });
    };
}


export const startLoginUid = (id) => {
    return (dispatch, getState) => {

        const service = `/api/Auth/LoginFptUid/${id}`;

        axios.post(
            service
        ).then(res => {
            // Wir setzen den Bearer
            axios.defaults.headers.common = { 'Authorization': `Bearer ${res.data.token}` }
            // Der User wird lokal gespeichert für einen Reload
            //localStorage.setItem('fptLoginUser', JSON.stringify(res.data));
            dispatch(setUser(res.data));

            // Wir laden automatisch die Teilnehmer, das sind die Teilnahmen an einem bestimmten FPT
            dispatch(startLoadTeilnehmer(res.data));

        }).catch((error) => {
            //  console.log('Login fehler', error); 
            dispatch(setGlobalError('Fehler: Ungültige id!'));
        });
    };
}



export const startLoadTeilnehmer = (user) => {
    return (dispatch) => {
        if (user.idFptTeilnehmerTyp.includes('j')) {
            dispatch(startGetTeilnehmerRedakteur(user.idFptProfilPerson));
        }
        if (user.idFptTeilnehmerTyp.includes('f')) {
            dispatch(startGetTeilnehmerFirma(user.idFptProfilPerson));
        }
    }
}

export const logout = () => {
    return (dispatch, getState) => {
        localStorage.setItem('fptLoginUser', '');
        dispatch(setUser(undefined));
        dispatch(setTeilnehmerRedakteur(undefined));
        dispatch(setTeilnehmerFirma(undefined));
        dispatch(setInitialized(false));
    };
}

export const setGotoMenuData = (data) => ({
    type: 'SET_GOTO_MENU_DATA',
    data
})


export const startGetGotoMenuData = (idAuftrag, idFirma, idPerson) => {
    return (dispatch) => {
        dispatch(setGotoMenuData(undefined));
        const service = `/api/GotoMenu/GetAllGotoMenuItems/${idAuftrag}/${idFirma ? idFirma : 0}/${idPerson ? idPerson : 0}`;

        axios.get(
            service
        ).then(res => {
            console.log('GotoMenuData erfolgreich', res);
            dispatch(setGotoMenuData(res.data));
        }).catch((error) => {
            dispatch(setGlobalError('Fehler beim beim Laden von GotoMenu-Daten: ' + error.message));
        });
    };
}



export const startChangePassword = (username, oldPassword, newPassword, callbackSuccess, callbackError) => {
    return (dispatch, getState) => {

        const service = `/api/Fpt/ChangePassword/${username}/${oldPassword}/${newPassword}`;
        axios.put(
            service
        ).then(res => {
            callbackSuccess && callbackSuccess(res.data);
        }).catch((error) => {
            const message = error.response && error.response.data && error.response.data.error;
            callbackError && callbackError(message);
        });
    };
}


export const startResetPassword = (username, callbackSuccess, callbackError) => {
    return (dispatch, getState) => {

        let code = "de23!" + username.charAt(0) + "q9987";
        console.log(code);

        const service = `/api/Fpt/ResetPassword/${username}`;
        axios.put(
            service, { id: code }
        ).then(res => {
            callbackSuccess && callbackSuccess(res.data);
        }).catch((error) => {
            const message = error.response && error.response.data && error.response.data.error;
            callbackError && callbackError(message);
        });
    };
}

export const startSignUpUser = (name, redaktion, email, callbackSuccess, callbackError) => {
    return (dispatch, getState) => {

        const service = `/api/Fpt/SignUpUser/`;
        axios.put(
            service, {
                name: name,
                redaktion: redaktion,
                email: email
            }
        ).then(res => {
            callbackSuccess && callbackSuccess(res.data);
        }).catch((error) => {
            const message = error.response && error.response.data && error.response.data.error;
            callbackError && callbackError(message);
        });
    };
}



export const startGetTeilnehmerRedakteur = (idProfilPerson) => {
    return (dispatch, getState) => {

        const service = `/api/Fpt/ReadTeilnehmerRedakteur/${idProfilPerson}`;
        axios.get(
            service
        ).then(res => {
            dispatch(setTeilnehmerRedakteur(res.data));
            dispatch(setInitialized(true));
        }).catch((error) => {
            const message = error.response && error.response.data && error.response.data.error;
        });
    };
}

export const startGetTeilnehmerFirma = (idProfilPerson) => {
    return (dispatch, getState) => {

        const service = `/api/Fpt/ReadTeilnehmerFirma/${idProfilPerson}`;
        axios.get(
            service
        ).then(res => {
            dispatch(setTeilnehmerFirma(res.data));
            dispatch(setInitialized(true));
        }).catch((error) => {
            const message = error.response && error.response.data && error.response.data.error;
        });
    };
}


export const startSetAvatar = (bild) => {
    return (dispatch, getState) => {
        const user = {
            ...getState().global.user,
            bild: bild
        };

        localStorage.setItem('fptLoginUser', JSON.stringify(user));
        dispatch(setUser(user));
    }
}

export const startSetUserData = (userData) => {
    return (dispatch, getState) => {
        const user = {
            ...getState().global.user,
            funktion: userData.funktion,
            telefonnummer: userData.telefonnummer,
            email: userData.email,
            xingId: userData.xingId,
            linkedInId: userData.linkedInId,
        };

        localStorage.setItem('fptLoginUser', JSON.stringify(user));
        dispatch(setUser(user));
    }
}

export const startDeleteAvatar = (idProfilPerson, callbackSuccess) => {
    return (dispatch, getState) => {

        const service = `/api/Fpt/DeleteAvatar/${idProfilPerson}`;
        axios.delete(
            service
        ).then(res => {
            console.log('delete done');
            callbackSuccess && callbackSuccess();
            dispatch(startSetAvatar(undefined));
        }).catch((error) => {
            const message = error.response && error.response.data && error.response.data.error;
        });
    };
}

export const startDeleteFirmenLogo = (idProfilFirma, callbackSuccess) => {
    return (dispatch, getState) => {

        const service = `/api/Fpt/DeleteFirmenLogo/${idProfilFirma}`;
        axios.delete(
            service
        ).then(res => {
            callbackSuccess && callbackSuccess(res.data);
        }).catch((error) => {
            const message = error.response && error.response.data && error.response.data.error;
        });
    };
}





export const startSaveProfilFirma = (profilFirma, callbackSuccess) => {
    return (dispatch, getState) => {

        const service = `/api/Fpt/UpdateProfilFirma`;
        axios.put(
            service, profilFirma
        ).then(res => {
            callbackSuccess && callbackSuccess(res.data);
        }).catch((error) => {
            const message = error.response && error.response.data && error.response.data.error;
        });
    };
}

export const sendAutoMail = (idAutomail, mailAdress, formData, onSuccess, onFail) => {
    return (dispatch, getState) => {

        // convert all Entries in formData to string
        const stingFormData = {};
        Object.keys(formData).forEach(function (key) {
            stingFormData[key] = formData[key].toString();
        });

        const daten = {
            toMailAdress: mailAdress,
            fromMailAdress: "kontakt@rbsonline.de",
            idAutomail: idAutomail,
            ablegen: false,
            hash: "1928#toh",
            daten: stingFormData
        }

        dispatch(setLoading(true));
        const service = `/api/Kontakt/SendAutoMail`;

        axios.post(
            service, daten
        ).then(res => {
            dispatch(setLoading(false));
            onSuccess && onSuccess(res);
        }).catch((error) => {
            onFail && onFail(error);
            dispatch(setLoading(false));
        });
    }
}
