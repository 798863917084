import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import { useParams } from "react-router-dom";

import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import FptLink from '../utils/FptLink';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { startResetPassword } from '../../actions/global';

const PageResetPassword = (props) => {

    const { username } = useParams();

    const [userName, setUserName] = useState(username);
    const [successMessage, setSuccessMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const txtUsernameChange = (e) => {
        setUserName(e.target.value)
    }

    const bttnResetClick = () => {
        setSuccessMessage(undefined);
        setErrorMessage(undefined);
        props.dispatch(startResetPassword(userName, onSuccess, onFail))
    }

    const onSuccess = (mail) => {
        setSuccessMessage("Ihr Passwort wurde zurückgesetzt. Sie erhalten eine Mail an: " + mail);
    }

    const onFail = (fehler) => {
        setErrorMessage("Fehler: " + fehler);
    }


    return (
        <div>
            <Grid container spacing={0}>
                <Grid item xs={12} md={6} lg={4}>

                    <Typography variant="h4" gutterBottom>
                        Passwort zurücksetzen
                    </Typography>

                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="body1" gutterBottom>
                                Geben Sie Ihren Benutzername ein und Sie erhalten eine E-Mail mit dem neuen Passwort an die hinterlegte Adresse.
                            </Typography>
                        </Grid>

                    
                        <Grid item xs={12}>
                            <TextField
                                autoFocus={true}
                                fullWidth                                
                                label="Benutzer"
                                variant="outlined"
                                value={userName}
                                onChange={txtUsernameChange}
                            />
                        </Grid>

                        { successMessage && <Grid item xs={12}><Alert severity="success">{successMessage}</Alert></Grid> }
                        { errorMessage && <Grid item xs={12}><Alert severity="error">{errorMessage}</Alert></Grid> }

                        <Grid item xs={12}>
                            <Button
                                onClick={bttnResetClick}
                                variant="contained"
                                size="large"
                                color="primary"
                            >
                                Passwort zurücksetzen
                            </Button>
                            
                        </Grid>
                        <Grid item xs={12}>
                            <FptLink to="/">zum Login</FptLink>
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>
        </div>
    );

}


export default connect()(PageResetPassword);