import React from 'react';
import { useHistory } from 'react-router-dom'

import FptCard from './FptCard';
import { FptList, FptListItem } from './FptList';
import Typography from '@material-ui/core/Typography';


/**
 * Zeigt eine Themenseite an
 * 
 * @param {object} props Component props
 * @param {object} props.firma (required)
 */
const FptMitabeiterCard = (props) => {

    const history = useHistory();

    console.log('FptMitabeiterCard', props.idFpt)

    const einladen = (person) => {
        const isJournalist = person.idFptTeilnehmerTyp.includes('j');
        !props.hideInvitation && history.push(`/${props.idFpt}/einladen/${person.idFptProfilPerson}/${props.idFpt}/${isJournalist}/false`);
    }
    
    const showDetails = (person) => {        
        history.push(`/${props.idFpt}/profil/${person.idFptProfilPerson}`);
    }

    return (
        <FptCard title="Ansprechpartner">
            { !props.hideInvitation &&
                <Typography variant="body1">
                    Klicken Sie auf eine Person, um sie zu einem Gepräch einzuladen.
            </Typography>
            }

            <FptList>
                <React.Fragment>
                    {props.profilPersonen && props.profilPersonen.length > 0 && props.profilPersonen.map(pers =>
                        <FptListItem
                            key={pers.idFptProfilPerson}
                            onClick={() => showDetails(pers)}
                            primaryText={(pers.titel ? pers.titel + " " : "") + pers.vorname + " " + pers.nachname}
                            secondaryText={
                                <React.Fragment>
                                    {pers.funktion &&
                                        <Typography variant="body2">
                                            {pers.funktion}
                                        </Typography>
                                    }

                                    <Typography variant="body2" style={{ color: '#ddd' }}>
                                        {pers.email ?
                                            <a href={'mailto:' + pers.email}>{pers.email}</a>
                                            :
                                            'keine E-Mail hinterlegt'
                                        }
                                        <br />
                                    </Typography>

                                    <Typography variant="body2" style={{ color: '#ddd' }}>
                                        {pers.telefonnummer ?
                                            <a href={'tel:' + pers.telefonnummer}>{pers.telefonnummer}</a>
                                            :
                                            'kein Fon hinterlegt'
                                        }
                                        <br />
                                    </Typography>
                                </React.Fragment>
                            }
                            tooltipRechts={pers.isAnsprechpartnerRed && "Ansprechpartner für Redaktionen"}
                            iconRechts={pers.isAnsprechpartnerRed && 'record_voice_over'}
                            avatar={pers.bild ? pers.bild : " "}
                        />
                    )}
                </React.Fragment>
            </FptList>

        </FptCard>
    )

}


export default FptMitabeiterCard
