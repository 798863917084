import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import FptBlockoutItem from '../../utils/FptBlockoutItem';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { startGetZeitslots, startGetMeineTermine, startRemoveBlockout, startSetBlockout} from '../../../actions/meeting';


const FptBlockout = (props) => {

    useEffect(() => {
        if (props.tage && props.meeting.zeitslots.length < 1) {
            getAllZeitslots();
        }
    }, [props.tage]);


    // Wenn Zeitslots geladen sind 
    // Meine Termine laden, damit Sie blockiert werden.
    useEffect(() => {
        if (props.meeting.zeitslots.length > 0) {
            props.dispatch(startGetMeineTermine(props.user.idFptProfilPerson));
        }
    }, [props.meeting.zeitslots]);

    // Wenn meine Termine geladen sind
    // Available initialisieren
    useEffect(() => {
        if (props.meeting.meineTermine) {
            initAvailable()
        }
    }, [props.meeting.meineTermine]);



    const [expanded, setExpanded] = React.useState(false);
    const [selectedSlot, setSelectedSlot] = useState(0);
    const [available, setAvailable] = useState(undefined);

    const getAllZeitslots = () => {
        props.dispatch(startGetZeitslots(props.tage));
    }

    const initAvailable = () => {
        //const blockoutList = props.meeting.meineTermine.filter(termin => termin.status === 0)
        const av = [
            ...props.meeting.zeitslots.map(slot => {
                return {
                    idFptMeetingZeitslot: slot.idFptMeetingZeitslot,
                    verfuegbar: true
                }
            })
        ];
        console.log('##############', av);
        setAvailable(av);
    }

    const accordionHandleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const blockoutChanged = (idZeitslot, isBlocked) => {
        console.log(idZeitslot, isBlocked);

        if( isBlocked ){
            props.dispatch(startSetBlockout(props.user.idFptProfilPerson, idZeitslot))
        }
        else {
            props.dispatch(startRemoveBlockout(props.user.idFptProfilPerson, idZeitslot))
        }


    }

    const getTag = (tag) => props.meeting.zeitslots.find(zeit => zeit.idFptMeetingTag === tag);

    return (
        <React.Fragment>

            <div style={{ marginBottom: '24px' }}>
                <Typography variant="h5" gutterBottom>
                    Blockouts setzen
                    </Typography>

                <Typography variant="body" gutterBottom>
                    Sie können Zeitslots markieren, an denen Sie nicht verfügbar sind.
                </Typography>

            </div>


            { props.tage && props.tage.map(tag =>
                <Accordion
                    expanded={expanded === 'panelTag' + tag}
                    onChange={accordionHandleChange('panelTag' + tag)}
                    key={tag}
                >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>
                            {getTag(tag) && getTag(tag).tagString}
                        </Typography>
                    </AccordionSummary>

                    <AccordionDetails>
                        <List style={{ width: '100%' }}>
                            {props.meeting.zeitslots
                                .filter(zeit => zeit.idFptMeetingTag === tag)
                                .sort((a, b) => (a.beginnStunde != b.beginnStunde ? a.beginnStunde - b.beginnStunde : a.beginnMinute - b.beginnMinute))
                                .map(zeit =>
                                    <FptBlockoutItem 
                                        key={zeit.idFptMeetingZeitslot}
                                        blocked={props.meeting.meineTermine &&
                                            props.meeting.meineTermine.some(termin => termin.status === 0 && termin.idFptMeetingZeitslot === zeit.idFptMeetingZeitslot)}
                                        uhrzeit={zeit.beginnStunde + ":" + (zeit.beginnMinute < 9 ? '0' : '') + zeit.beginnMinute.toString()}
                                        idFptMeetingZeitslot={zeit.idFptMeetingZeitslot}
                                        disabled={props.meeting.meineTermine &&
                                            props.meeting.meineTermine.some(termin => termin.status > 0 && termin.idFptMeetingZeitslot === zeit.idFptMeetingZeitslot)
                                        }
                                        onBlockoutChange={blockoutChanged}
                                    />
                                   
                                )}
                        </List>
                    </AccordionDetails>

                </Accordion>
            )}
        </React.Fragment>
    )

}


const mapStateToProps = state => ({
    meeting: state.meeting,
    user: state.global.user
});

export default connect(mapStateToProps)(FptBlockout);