import React, { useEffect } from 'react';
import { connect } from 'react-redux'

import FptLink from '../../utils/FptLink';
import FptTermine from '../generic/FptTermine';
import FptForceAnmeldung from '../../utils/FptForceAnmeldung';
import FptHeader from '../../utils/FptHeader';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';



const idFpt = 'fpt032';



const PageTermineFpt032 = (props) => {

	return (
		<React.Fragment>
			<FptHeader title="Fachpressetage 2021" />

			<FptForceAnmeldung idFpt={idFpt} fptTitel="Fachpressetage 2021">

				<Grid container spacing={0}>
					<Grid item xs={12} md={8} lg={6}>

						<Typography variant="h4" gutterBottom>
							Meine Termine
						</Typography>

						<Typography variant="body1" gutterBottom style={{marginBottom: '24px'}}>
							Sie können sich mit anderen Teilnehmer:innen der FPT zu einem Online-Meeting verabreden. 
							Suchen Sie dafür unter "<FptLink component="span" to={`/${idFpt}/teilnehmer`}>Teilnehmer</FptLink>" 
							oder "<FptLink component="span" to={`/${idFpt}/themen`}>Firmen & Themen</FptLink>"
							nach dem Ansprechpartner.
						</Typography>
						
						<Typography variant="body1" gutterBottom style={{marginBottom: '24px'}}>
							Sie können definieren, wann Sie verfügbar sind, indem Sie <FptLink component="span" to={`/${idFpt}/blockout`}>Blockouts setzen</FptLink>.
						</Typography>

						<FptTermine idProfilPerson={props.user.idFptProfilPerson} idFpt={idFpt}/>


					</Grid>
				</Grid>
			</FptForceAnmeldung>

		</React.Fragment>
	);

}


const mapStateToProps = state => ({
	user: state.global.user,
});

export default connect(mapStateToProps)(PageTermineFpt032);
