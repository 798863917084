import React, { useEffect } from 'react';
import { connect } from 'react-redux'

import FptTermin from '../generic/FptTermin';
import FptForceAnmeldung from '../../utils/FptForceAnmeldung';
import FptHeader from '../../utils/FptHeader';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';



const idFpt = 'fpt032';



const PageTerminFpt032 = (props) => {

	return (
		<React.Fragment>
			<FptHeader title="Fachpressetage 2021" />

			<FptForceAnmeldung idFpt={idFpt} fptTitel="Fachpressetage 2021">

				<Grid container spacing={0}>
					<Grid item xs={12} md={8} lg={6}>

						<Typography variant="h4" gutterBottom>
							Termin
						</Typography>

						<FptTermin idFpt={idFpt}/>


					</Grid>
				</Grid>
			</FptForceAnmeldung>

		</React.Fragment>
	);

}


const mapStateToProps = state => ({
	user: state.global.user,
});

export default connect()(PageTerminFpt032);
