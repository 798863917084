
export const buildErrorMessage = (error) => {
    let message = undefined;

    if (error.response) {
        console.log(error.response);
        if(error.response.status === 403){
            let service = '';
            if( error.request ){
                service = ' Service: ' + error.request.responseURL.substring(22);
            }
            message = "Sie haben keine ausreichenden Rechte. " + service;
        } else {
            message = error.response.data && error.response.data.error;
        }                            
    } else {
        message = error.message;
    }
    return message;
}