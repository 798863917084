import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'

import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import FptLink from '../utils/FptLink';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { startSignUpUser } from '../../actions/global';

const PageAkkreditieren = (props) => {

    const [formData, setFormData] = useState({});
    const [successMessage, setSuccessMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const txtInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
        
        setErrorMessage(undefined);
    }

    const bttnSubmitClick = () => {
        if(checkForm()){
            props.dispatch(startSignUpUser(formData.name, formData.redaktion, formData.email, onSuccess, onFail));
        }
    }    
    
    const onSuccess = (result) => {
        result ? setSuccessMessage('Vielen Dank für Ihre Anmeldung. Wir werden einen Login erstellen und Sie erhalten die Zugangsdaten per Mail. Bitte haben Sie etwas Geduld, wir prüfen Ihre Daten manuell, weil nur Journalisten Zugriff erhalten.'): 
        setErrorMessage("Sorry, da ist leider etwas schief gegangen, bitte schicken Sie eine Mail an: ioehlschlaeger@rbsonline.de");
    }

    const onFail = (result) => {
        setErrorMessage("Sorry, da ist leider etwas schief gegangen, bitte schicken Sie eine Mail an: ioehlschlaeger@rbsonline.de");
    }

    const checkForm = () => {
        if( formData.name && formData.redaktion && formData.email){
            return true;
        }
        else {
            setErrorMessage("Bitte füllen Sie alle Felder aus!");
            return false;
        }
    }


    return (
        <div>
            <Grid container spacing={0}>
                <Grid item xs={12} md={6} lg={4}>

                    <Typography variant="h4" gutterBottom>
                        Als Journalist akkreditieren
                    </Typography>

                    <Typography variant="body1" gutterBottom>
                        Sie sind Redakteur und möchten zu Fachpressetagen eingeladen werden? Hier können Sie einen Login anlegen. 
                    </Typography>

                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                autoFocus={true}
                                fullWidth
                                margin="normal"
                                name="name"
                                label="Name"
                                variant="outlined"
                                value={formData.name}
                                onChange={txtInputChange}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                margin="normal"
                                name="redaktion"
                                label="Redaktion"
                                variant="outlined"
                                value={formData.redaktion}
                                onChange={txtInputChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                margin="normal"
                                name="email"
                                label="E-Mail"
                                variant="outlined"
                                value={formData.email}
                                onChange={txtInputChange}
                            />
                        </Grid>

                        { successMessage && <Grid item xs={12}><Alert severity="success">{successMessage}</Alert></Grid> }
                        { errorMessage && <Grid item xs={12}><Alert severity="error">{errorMessage}</Alert></Grid> }

                        <Grid item xs={12}>
                            <Button
                                onClick={bttnSubmitClick}
                                variant="contained"
                                size="large"
                                color="primary"
                            >
                                Benutzer erstellen
                            </Button>
                        </Grid>                     
                        <Grid item xs={12}>
                            <FptLink to="/">zum Login</FptLink>
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>

        </div>
    );

}

export default connect()(PageAkkreditieren);