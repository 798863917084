import React, { useEffect, useState } from "react";
import { getThumbnail } from "../../actions/fpt";
import { connect } from "react-redux";
import FileIcon from '@material-ui/icons/Description';
//import DownloadIcon from '@material-ui/icons/GetApp';
import DeleteIcon from '@material-ui/icons/Delete';
import { Button, CircularProgress, Typography } from "@material-ui/core";

const RbsFileVizualizer = (props) => {

    const [thumbnail, setThumbnail] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showDownload, setShowDownload] = useState(false);

    useEffect(() => {
        //console.log('fetch thumbnail', props.file);
        props.dispatch(getThumbnail(props.file.id, onThumbnailLoaded));
    }, [props.file]);

    const onThumbnailLoaded = (_thumbnail) => {
        //console.log('thumbnail', _thumbnail);
        setThumbnail(_thumbnail);
    }

    const deleteFile = (id) => {
        setLoading(true);
        props.onDeleteFile && props.onDeleteFile(id)
    }

    return (
        <div style={{ float: props.float ? props.float : "none" }}>
            <a
                href={props.file.downloadUrl}
                onMouseEnter={() => setShowDownload(true)}
                onMouseLeave={() => setShowDownload(false)}
            >
                <div className="rbs-file-vizualizer-main">
                    <div className="rbs-file-vizualizer-thubnail-container" >
                        {thumbnail ?
                            <img src={thumbnail.small.url} />
                            :
                            <FileIcon style={{ color: '#bbb', width: '96px', height: '96px' }} />
                        }
                    </div>
                    {showDownload &&
                        <div className="rbs-file-vizualizer-filename-complete">
                            {props.file.name}
                        </div>
                    }

                    {loading &&
                        <div className="rbs-file-visualizer-loading-container">
                            <CircularProgress className="rbs-file-visualizer-loading-img" />
                        </div>
                    }
                    <div>
                        <Typography variant="body2" className="rbs-file-visualizer-filename">
                            {!showDownload && props.file.name}
                        </Typography>

                    </div>
                </div>
            </a>

            {props.deletable &&
                <Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    onClick={() => deleteFile(props.file.id)}
                    style={{ marginBottom: '10px' }}
                >
                    <DeleteIcon />
                    löschen
                </Button>
            }
        </div>
    )
}

export default connect()(RbsFileVizualizer);