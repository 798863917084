import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'

import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import FptLink from '../utils/FptLink';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { startChangePassword } from '../../actions/global';

const PageChangePassword = (props) => {

    const [formData, setFormData] = useState({});
    const [formError, setFormError] = useState({});
    const [successMessage, setSuccessMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const checkData = (rec) => {
        let pass=true;
        let newFormError = {};

        if( !rec.passwordOld ){
            newFormError = { ...newFormError, passwordOld: 'Bitte altes Passwort eingeben!' };
            pass=false;
        }
        if( !rec.passwordNew ){
            newFormError = { ...newFormError, passwordNew: 'Bitte neue Passwort eingeben!' };
            pass=false;
        }
        if( !rec.passwordNew2 ){
            newFormError = { ...newFormError, passwordNew2: 'Bitte neues Passwort wiederholen!' };
            pass=false;
        }        
        if( rec.passwordNew && rec.passwordNew2 ){
            if( rec.passwordNew !== rec.passwordNew2 ){
                newFormError = {                 
                    ...newFormError, 
                    passwordNew: 'Passwörter sind nicht identisch.',
                    passwordNew2: 'Passwörter sind nicht identisch.' 
                };
            pass=false;
            }
        }

        if(!pass){
            setFormError(newFormError);
        }
        return pass;
    } 

    const txtInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }

    const bttnLoginClick = () => {
        setErrorMessage(undefined);
        setSuccessMessage(undefined);

        if( checkData(formData)){
            props.dispatch(startChangePassword(props.user.loginname, formData.passwordOld, formData.passwordNew, onPasswordChanged, onPasswordFail))
        }
    }

    const onPasswordChanged = (result) => {
        result ? setSuccessMessage('Ihr Passwort wurde geändert! Sie können es beim nächsten Login verwenden.'): setErrorMessage("Ein unvorhergesehener Fehler ist aufgetren.");
    }

    const onPasswordFail = (result) => {
        setErrorMessage(result)
    }

    return (
        <div>
            <Grid container spacing={0}>
                <Grid item xs={12} md={6} lg={4}>

                    <Typography variant="h4" gutterBottom>
                        Passwort ändern
                    </Typography>

                    <Grid container spacing={2}>

                        <Grid item xs={12}>
                            <TextField
                                autoFocus={true}

                                fullWidth
                                label="altes Passwort"
                                variant="outlined"
                                type="password"
                                name="passwordOld"
                                value={formData.passwordOld}
                                onChange={txtInputChange}
                                error={!!formError.passwordOld} 
                                helperText={formError.passwordOld} 
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                size="medium"
                                label="neues Passwort"
                                variant="outlined"
                                type="password"
                                name="passwordNew"
                                value={formData.passwordNew}
                                onChange={txtInputChange}
                                error={!!formError.passwordNew} 
                                helperText={formError.passwordNew} 
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth

                                label="neues Passwort (wiederholen)"
                                variant="outlined"
                                type="password"
                                name="passwordNew2"
                                value={formData.passwordNew2}
                                onChange={txtInputChange}
                                error={!!formError.passwordNew2} 
                                helperText={formError.passwordNew2} 
                            />
                        </Grid>

                        { successMessage && <Grid item xs={12}><Alert severity="success">{successMessage}</Alert></Grid> }
                        { errorMessage && <Grid item xs={12}><Alert severity="error">{errorMessage}</Alert></Grid> }

                        <Grid item xs={12}>
                            <Button
                                onClick={bttnLoginClick}
                                variant="contained"
                                size="large"
                                color="primary"
                            >
                                Passwort ändern
                            </Button>
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>
        </div>
    );

}


const mapStateToProps = state => ({
    user: state.global.user,
});

export default connect(mapStateToProps)(PageChangePassword);