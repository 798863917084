import React, { useEffect } from "react";



const MaxSizeImage = (props) => {

    const maxSize = props.maxSize ? props.maxSize : { height: 60, width: 200 };

    const [height, setHeight] = React.useState(undefined);
    const [width, setWidth] = React.useState(undefined);

    const onImgLoad = (e) => {
        const breakpoint = maxSize.height / maxSize.width;
        const height = e.target.height;
        const width = e.target.width;
        const ratio = height / width;

        if (ratio > breakpoint) {
            setHeight(maxSize.height);
            setWidth(maxSize.height / ratio);
        } else {
            setHeight(maxSize.width * ratio);
            setWidth(maxSize.width);
        }
    }

    return (
        <img onLoad={onImgLoad} height={height} width={width} src={props.imageUrl} alt="Logo" style={props.imageStayle} />
    )
}

export default MaxSizeImage;