import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'


import ChatItem from './ChatItem';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import SendIcon from '@material-ui/icons/Send';
import UpdateIcon from '@material-ui/icons/Update';

import { startGetChatEntries, startAddChatEntry, setReload } from '../../actions/chat';


const chatRefreshInterval = 60 * 1000;

const Chat = (props) => {

    useEffect(() => {   
        if(!props.reload){
            startLoadChat();
        }
    }, []);

    const [txtInput, setTxtInput] = useState('');    
    

    const sendMessage = () => {

        const newData = {
            idChatEntry: 0,
            avatar: props.user.bild,
            sender: props.user.vorname + " " + props.user.nachname,
            datum: null,
            message: txtInput,
            hide: false
        };

        props.dispatch(startAddChatEntry(newData))
        setTxtInput('');
    }

    const startLoadChat = () => {

        props.dispatch(startGetChatEntries());

        const idReload = setInterval(() => { 
            console.log("Checking Chat...");
            props.dispatch(startGetChatEntries());
        }, chatRefreshInterval);

        props.dispatch(setReload(idReload));
    } 

    /*
    const lastId = () => {
        let lastId = 0;
        if( props.chatEntries.length > 0){
            lastId = Math.max.apply(Math, props.chatEntries.map(function(entry) { return entry.idChatEntry; }));
        }
        return lastId;
    }*/


    const bttnSendClick = () => {
        txtInput && sendMessage();
    }

    const bttnUpdateClick = () => {
        props.dispatch(startGetChatEntries());
    }

    const txtInputKeyDown = (e) => {        
        if (e.key === 'Enter') {
            e.stopPropagation();
            sendMessage();
        }
    }

    return (
        <div style={{ height: '100%', overflowY: 'auto' }}>

            <FormControl variant="outlined" fullWidth style={{paddingBottom:'12px'}}> 
                <Input
                    placeholder="Ihre Chat-Nachricht"
                    autoFocus={false}
                    value={txtInput}
                    onChange={(e) => setTxtInput(e.target.value)}
                    onKeyDown={txtInputKeyDown}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton color="primary" component="span" onClick={bttnSendClick}>
                                <SendIcon />
                            </IconButton>
                            <IconButton color="primary" component="span" onClick={bttnUpdateClick}>
                                <UpdateIcon />
                            </IconButton>
                        </InputAdornment>
                    }

                />  
            </FormControl>

            {props.chatEntries && props.chatEntries.map(c =>
                <ChatItem key={c.idChatEntry} avatar={c.avatar} sender={c.sender} date={c.datum} message={c.message} />
            )
            }
        </div>
    )
}

const mapStateToProps = state => ({
    user: state.global.user,
    chatEntries: state.chat.chatEntries,
    reload: state.chat.reload,
});

export default connect(mapStateToProps)(Chat);
