import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';


const FptLink = (props) => {

    const ensureHttp = (link) => {
        return (link.startsWith('http') || link.startsWith('mailto')) ? link : 'http://' + link;
    }

    return (
        <React.Fragment>

        { props.to &&
            <Link to={props.to}>
                <Typography component={props.component} variant={props.typoVariant ? props.typoVariant : "body1"} color="secondary" gutterBottom>
                    {props.children}
                </Typography>
            </Link>
        }

        { props.href &&
            <Typography component={props.component} variant={props.typoVariant ? props.typoVariant : "body1"} color="secondary" gutterBottom>
                <a href={ensureHttp(props.href)} target="_blank">{props.children}</a>
            </Typography>
        }

        </React.Fragment>        
    )
}

export default FptLink;