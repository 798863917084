const globalReducerDefaultState = {
    user: undefined,
    error: undefined,
    success:undefined,
    loading: false,
    lookup: {
        dataType: 'Stamm_auftragData'
    },
    pageTitle: 'App Fachpressetage',
    mobileOpen: false,
};

const globalReducer = (state = globalReducerDefaultState, action) => {    
    switch (action.type){
        case 'SET_USER':
            return {
                ...state,
                user: action.user
            }    
        case 'SET_AVATAR':
            return {
                ...state,
                user: {
                    ...state.user,
                    bild: action.bild
                }
            }
        case 'SET_FAVORITEN':
            return {
                ...state,
                user: {
                    ...state.user,
                    favoriten: action.favoriten
                }
            }   
        case 'SET_LOADING':
        return {
            ...state,
            loading: action.value
        }
        case 'SET_TABMENU_VISIBLE':
            return {
                ...state,
                tabMenuVisible: action.value
            }
        case 'SET_QUICKMENU_VISIBLE' :
            return {
                ...state,
                quickMenuVisible: action.value
            }
        case 'SET_GOTO_VISIBLE' :
            return {
                ...state,
                gotoVisible: action.value
            }
        case 'SET_GLOBAL_ERROR':
        return {
            ...state,
            error: {
                message: action.message
            }
        }
        case 'RESET_GLOBAL_ERROR':
        return {
            ...state,
            error: undefined
        }
        case 'SET_GOTO_MENU_DATA':
        return {
            ...state,
            gotoMenuData: action.data
        }
        case 'SET_GLOBAL_SUCCESS' :
            return {
                ...state,
                success: action.message,
            }
        case 'RESET_GLOBAL_SUCCESS':
            return {
                ...state,
                success: undefined,
            }
        case 'SET_GLOBAL_WARNING':
            return {
                ...state,
                warning: action.message,
            }
        case 'RESET_GLOBAL_WARNING':
            return {
                ...state,
                warning: undefined,
            }
        case 'SET_ALL_MITARBEITER':
            return{
                ...state,
                mitarbeiter: action.mitarbeiter,
            }
        case 'SET_PAGE_TITLE':
            return{
                ...state,
                pageTitle: action.pageTitle
            }
        case 'SET_MOBILE_OPEN':
            return {
                ...state,
                mobileOpen: action.mobileOpen
            }
        case 'SET_TEILNEHMER_REDAKTEUR':
            return {
                ...state,
                teilnehmerRedakteur: action.teilnehmerRedakteur
            }
        case 'SET_TEILNEHMER_FIRMA':
            return {
                ...state,
                teilnehmerFirma: action.teilnehmerFirma
            }   
        case 'SET_INITIALIZED' :    
            return{
                ...state,
                initialized: action.initialized
            }
        
        default:
            return state;
    }
}

export default globalReducer;