import React, { useEffect } from 'react';
import { connect } from 'react-redux'


import FptProfilPersonCard from '../utils/FptProfilPersonCard';
import FptProfilFirmaCard from '../utils/FptProfilFirmaCard';
import FptHeader from '../utils/FptHeader';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { startReadProfileFirmaByUser, startReadTeilnehmerPersonen } from '../../actions/fpt';
import { startSaveProfilPerson } from '../../actions/fpt';
import { config } from '../../config/config';

const idFpt = config.currentFpt; // 'fpt032';

const PageMeineDaten = (props) => {

	useEffect(() => {
		loadFirmenPerson(props.user.idFptProfilPerson);
	}, []);

	// Wenn MeineFirmen geladen sind, laden wir für 
	// Firmenteilnehmer auch die anderen Personen aus
	// der Firma.
	useEffect(() => {
		if (props.myFirmen && props.myFirmen.length > 0) {
			console.log('myFirmen loaded!');
			// Für Firmen laden wir alle Personen 
			if (props.user.idFptTeilnehmerTyp.includes('f')) {
				props.myFirmen.forEach(firma => {
					loadAlleFirmenPersonen(firma.idFptProfilFirma);
				});

			}
		}
	}, [props.myFirmen]);

	const firmaDataChanged = () => {
		loadFirmenPerson(props.user.idFptProfilPerson);
	}

	const loadFirmenPerson = (idProfilPerson) => {
		props.dispatch(startReadProfileFirmaByUser(idProfilPerson, idFpt))
	}

	const loadAlleFirmenPersonen = (idProfilFirma) => {
		props.dispatch(startReadTeilnehmerPersonen(idProfilFirma, idFpt))
	}

	const bttnSavePersonFirmaClick = (profilPerson) => {
		props.dispatch(startSaveProfilPerson(profilPerson));
	}

	const bttnSaveRedakteurClick = (profilPerson) => {
		console.log("Save Redakteur");
		props.dispatch(startSaveProfilPerson(profilPerson, props.myFirmen[0].idFptProfilFirma, idFpt));
	}


	return (

		<React.Fragment>
			<FptHeader title="Einstellungen" />

			<Typography variant="h5" gutterBottom>
				Mein Profil
			</Typography>

			<Grid container spacing={2}>

				{/* Login user ist Journalist */}
				{props.user.idFptTeilnehmerTyp.includes('j') &&
					<React.Fragment>
						<Grid item xs={12} md={6} lg={4}>
							<FptProfilPersonCard
								profilPerson={props.user}
								onSaveClick={bttnSavePersonFirmaClick}
							/>
						</Grid>

						{props.myFirmen && props.myFirmen.map(firma =>
							<Grid item xs={12} md={6} lg={4} key={firma.idFptProfilFirma}>
								<FptProfilFirmaCard profilFirma={firma} onDataChanged={firmaDataChanged} isRedaktion={true} />
							</Grid>
						)}
					</React.Fragment>
				}

				{/* Login user ist Firma */}
				{props.user.idFptTeilnehmerTyp.includes('f') &&
					<React.Fragment>
						{props.myFirmen && props.myFirmen.map(firma =>
							<React.Fragment>
								<Grid item xs={12} md={6} lg={4} key={firma.idFptProfilFirma}>
									<FptProfilFirmaCard profilFirma={firma} onDataChanged={firmaDataChanged} isRedaktion={false} />
								</Grid>
								{props.fptFirmen[firma.idFptProfilFirma] && props.fptFirmen[firma.idFptProfilFirma].teilnehmerPersonen
									.map(person =>
										<Grid item xs={12} md={6} lg={4} key={person.idFptProfilPerson}>
											<FptProfilPersonCard
												onSaveClick={bttnSaveRedakteurClick}
												profilPerson={person} />
										</Grid>
									)
								}
							</React.Fragment>
						)}

					</React.Fragment>
				}


				{!props.user.idFptTeilnehmerTyp.includes('j') && !props.user.idFptTeilnehmerTyp.includes('f') &&
					<React.Fragment>
						Schwerer Datenfehler: Sie sind weder Redakteur noch Firmenteilnehmer. Bitte melden Sie sich bei uns: ioehlschlaeger@rbsonline.de, +49 7244 73969-0
					</React.Fragment>
				}

			</Grid>

		</React.Fragment>
	);

}


const mapStateToProps = state => ({
	user: state.global.user,
	myFirmen: state.fpt[idFpt].myFirmenProfile,
	fptFirmen: state.fpt[idFpt].firmen,
});

export default connect(mapStateToProps)(PageMeineDaten);
