import axios from 'axios';
import { setGlobalError, setLoading } from './global';
import { buildErrorMessage } from '../helper/errorHelper';


export const setChatEntries = (chatEntries) => ({
    type: 'SET_CHAT_ENTRIES',
    chatEntries,
})

export const setReload = (reload) => ({
    type: 'SET_RELOAD',
    reload
})

export const startGetChatEntries = () => {
    return (dispatch, getState) => {
        dispatch(setLoading(true));


        const entries = getState().chat.chatEntries;
        const user = getState().global.user;        
        const lastId = entries.length > 0 ? Math.max.apply(Math, entries.map(function(entry) { return entry.idChatEntry; })) : 0;

        const service = `/api/Fpt/GetChatEntries/${user.idFptProfilPerson}/${lastId}`;
            
        axios.get(
            service
        ).then(res => {      
            dispatch(setLoading(false));
            dispatch(setChatEntries(res.data));         
        }).catch((error) => { 
            // Wir geben hier keinen Fehler aus. Wenn das nicht klappt => Pech! 
            // Das Porblem: wir laden ständig neu und ein Handy geht zwitweise offline! 
            // das führt zu unschönen Fehlermeldunge, die bem User nichts bringen!
            //dispatch(setGlobalError(buildErrorMessage(error)));

            dispatch(setLoading(false));
        });
    };
}


export const startAddChatEntry = (chatEntry) => {
    return (dispatch, getState) => {
        dispatch(setLoading(true));

        const entries = getState().chat.chatEntries;      
        const lastId = entries.length > 0 ? Math.max.apply(Math, entries.map(function(entry) { return entry.idChatEntry; })) : 0;

        const service = `/api/Fpt/AddChatEntry/${lastId}`;
            
        axios.put(
            service, chatEntry
        ).then(res => {      
            dispatch(setLoading(false));
            dispatch(setChatEntries(res.data));         
        }).catch((error) => { 
            dispatch(setGlobalError(buildErrorMessage(error)));
            dispatch(setLoading(false));
        });
    };
}