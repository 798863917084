import React, { useEffect, useState } from 'react';

import FptForceAnmeldung from '../../utils/FptForceAnmeldung';
import FptHeader from '../../utils/FptHeader';
import Grid from '@material-ui/core/Grid';

import FptBlockout from '../generic/FptBlockout';


const tage = [1, 2, 3];
const idFpt = "fpt032";


const PageBlockout = (props) => {

    return (
        <React.Fragment>
            <FptHeader title="Fachpressetage 2021" />

            <FptForceAnmeldung idFpt={idFpt} fptTitel="Fachpressetage 2021">

                <Grid container spacing={2}>
                    <Grid item xs={12} md={8} lg={6}>

                        <FptBlockout tage={tage} />

                    </Grid>

                </Grid>
            </FptForceAnmeldung>

        </React.Fragment>
    )

}



export default PageBlockout