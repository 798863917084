import React, { useState } from 'react';
import { useHistory } from 'react-router-dom'

import { Checkbox, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';

import IconAnwesend from '@material-ui/icons/Check';
import IconAbwesend from '@material-ui/icons/HighlightOffOutlined'
import IconFirmen from '@material-ui/icons/Business';
import IconJournalisten from '@material-ui/icons/ImportContacts';


/**
 * Zeigt eine Liste von Anwesenhieten an
 * 
 * 
 * @param {object} props Component props
 * @param {object} props.anwesenheiten (required)
 */
const FptWerWannListe = (props) => {


    const [showFirmen, setShowFirmen] = useState(true);
    const [showJournalisten, setShowJournalisten] = useState(true);
    const [suchFilter, setSuchFilter] = useState('');

    const history = useHistory();

    const generateName = (anw) => {
        const name = anw.vorname + " " + anw.nachname;
        return anw.titelPerson ? anw.titelPerson + " " + name : name;
    }

    const zumProfil = (idFptProfilPerson) => {
        console.log(idFptProfilPerson);
        history.push(`/${props.idFpt}/profil/${idFptProfilPerson}`);
    }

    return (
        <React.Fragment>
            {!props.hideFilter &&
                <React.Fragment>
                    <TextField
                        autoFocus={false}
                        fullWidth
                        value={suchFilter}
                        label="Suche Redaktion/Firma/Person"
                        variant="outlined"
                        onChange={(e) => setSuchFilter(e.target.value)}
                    />

                    <FormGroup row>
                        <FormControlLabel
                            control={<Checkbox checked={showFirmen} onChange={() => setShowFirmen(!showFirmen)} />}
                            label={
                                <span>
                                    <IconFirmen style={{ marginRight: '8px' }} />
                                    Firmenteilnehmer
                                </span>
                            }
                        />

                        <FormControlLabel
                            control={<Checkbox checked={showJournalisten} onChange={() => setShowJournalisten(!showJournalisten)} />}
                            label={
                                <span>
                                    <IconJournalisten style={{ marginRight: '8px' }} />
                                    Journalisten
                                </span>
                            }
                        />
                    </FormGroup>
                </React.Fragment>
            }

            <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                Name/Firma
                            </TableCell>
                            <TableCell>V1</TableCell>
                            <TableCell>M1</TableCell>
                            <TableCell>N1</TableCell>
                            <TableCell>A1</TableCell>
                            <TableCell>V2</TableCell>
                            <TableCell>M2</TableCell>
                            <TableCell>N2</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>

                        {props.anwesenheiten && props.anwesenheiten
                            .sort((a, b) => (a.nachname < b.nachname ? -1 : 1))
                            .filter(anw => {
                                if (anw.idFptTeilnehmerTyp.includes("f")) {
                                    return showFirmen;
                                }
                                if (anw.idFptTeilnehmerTyp.includes("j")) {
                                    return showJournalisten
                                }
                            })
                            .filter(anw => suchFilter ?
                                anw.firmenname && anw.firmenname.toUpperCase().includes(suchFilter.toUpperCase()) ||
                                anw.firmennamen && anw.firmennamen.join().toUpperCase().includes(suchFilter.toUpperCase()) ||
                                (anw.nachname.toUpperCase().includes(suchFilter.toUpperCase()))
                                : true)
                            .map(anw =>
                                <TableRow hover key={anw.idFptProfilPerson.toString() + anw.firmenname}>
                                    <TableCell style={{ padding: '0px' }}>
                                        <ListItem button onClick={() => zumProfil(anw.idFptProfilPerson)}>
                                            <ListItemText
                                                primary={generateName(anw)}
                                                secondary={anw.firmenname}
                                            ></ListItemText>
                                        </ListItem>
                                    </TableCell>
                                    <TableCell>{anw.v1.includes("X") ? <IconAnwesend /> : <IconAbwesend />} </TableCell>
                                    <TableCell>{anw.m1.includes("X") ? <IconAnwesend /> : <IconAbwesend />} </TableCell>
                                    <TableCell>{anw.n1.includes("X") ? <IconAnwesend /> : <IconAbwesend />} </TableCell>
                                    <TableCell>{anw.a1.includes("X") ? <IconAnwesend /> : <IconAbwesend />} </TableCell>
                                    <TableCell>{anw.v2.includes("X") ? <IconAnwesend /> : <IconAbwesend />} </TableCell>
                                    <TableCell>{anw.m2.includes("X") ? <IconAnwesend /> : <IconAbwesend />} </TableCell>
                                    <TableCell>{anw.n2.includes("X") ? <IconAnwesend /> : <IconAbwesend />} </TableCell>
                                </TableRow>
                            )}


                    </TableBody>

                </Table>
            </TableContainer>

        </React.Fragment >

    )

}

export default FptWerWannListe;