import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { connect } from 'react-redux'

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation';

import { startGetZeitslots, readReadTelnehmerPersonMeeting, startEinladungAussprechen, startGetMeineTermine, startGetTermineGast } from '../../../actions/meeting';



const PageEinladen = (props) => {

    const { idPerson, idFpt, isJournalist, isErsatzTermin } = useParams();


    // Meine Termine laden, damit Sie blockiert werden.
    useEffect(() => {    
        props.dispatch(startGetMeineTermine(props.user.idFptProfilPerson));
    }, []);


    useEffect(() => {
        if (props.tage && props.meeting.zeitslots.length < 1) {
            getAllZeitslots();
        }
    }, [props.tage]);



    // Die beiden Teilnehmer (Host und Gast laden)
    useEffect(() => {
        if (idPerson && idFpt) {
            // Gast laden und auch alle Termin berücksichtigen
            props.dispatch(readReadTelnehmerPersonMeeting(idPerson, idFpt, isJournalist, false));
            props.dispatch(startGetTermineGast(idPerson));

            // Host laden, falls noch nicht geschehen
            // das bin ja ich und wir können das etwas statischer handhaben.
            if (!props.meeting.host) {                
                props.dispatch(readReadTelnehmerPersonMeeting(props.user.idFptProfilPerson, idFpt, props.user.idFptTeilnehmerTyp.includes('j'), true));
            }
        }
    }, [idPerson]);


    const [expanded, setExpanded] = React.useState(false);
    const [selectedSlot, setSelectedSlot] = useState(0);


    const getAllZeitslots = () => {
        props.dispatch(startGetZeitslots(props.tage));
    }

    const accordionHandleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const BuchenCard = ({ zeit }) => {

        const [infoGast, setInfoGast] = useState('');
        const [einladungSuccess, setEinladungSuccess] = useState(undefined);
        const [einladungError, setEinladungError] = useState(undefined);

        const [kanal, setKanal] = useState({jitsi:true, fon:true, sonst:false});
        const [fon, setFon] = useState(props.user.telefonnummer);
        const [sonstKanal, setSonstKanal] = useState('');

        const firmaGast = props.meeting.gast.firmenname ? props.meeting.gast.firmenname : props.meeting.gast.firmennamen.join(', ');
        const firmaHost = props.meeting.host.firmenname ? props.meeting.host.firmenname : props.meeting.host.firmennamen.join(', ');


        const buchen = (idFptMeetingZeitslot) => {
            const meeting = {
                idFptMeetingZeitslot: idFptMeetingZeitslot,
                idPersonGast: props.meeting.gast.idFptProfilPerson,
                idPersonHost: props.meeting.host.idFptProfilPerson,
                infoGast: infoGast,
                firmaGast: firmaGast,
                firmaHost: firmaHost,
                isGastJournalist: (isJournalist == 'true'),
                isHostJournalist: props.user.idFptTeilnehmerTyp.includes('j'),
                isVerschoben: (isErsatzTermin == 'true'),
                jitsi: kanal.jitsi,
                fon: kanal.fon ? fon : null,
                sonstKanal: kanal.sonst ? sonstKanal : null,
            };

            console.log(meeting);
            props.dispatch(startEinladungAussprechen(meeting, onEinladungDone))
        }

        const onEinladungDone = (result) => {
            result.success ?
                setEinladungSuccess('Ihre Einladung wurde per Mail verschickt. Sie erhalten eine Kopie als cc.')
                :
                setEinladungError(result.errorMessage);
        }

        const txtKanalChange = (event) => {
            if(event.target.value.length > 0){
                setKanal({...kanal, sonst: true})
            } 
            else {
                setKanal({...kanal, sonst: false})
            }
            setSonstKanal(event.target.value);
        }

        const txtFonChange = (event) => {
            setFon(event.target.value);
        }

        return (
            <Card variant="outlined">
                {!einladungSuccess && !einladungError &&
                    <CardContent>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell width='100px' component="th" scope="row">
                                        Host:
                                    </TableCell>
                                    <TableCell >
                                        {props.meeting.host.vorname} {props.meeting.host.nachname} <br /> {firmaHost}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        Gast:
                                </TableCell>
                                    <TableCell >
                                        {props.meeting.gast.vorname} {props.meeting.gast.nachname} <br /> {firmaGast}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell scope="row" colSpan={2}>
                                        Hinweis: Sie können mehrere Kontakt-Alternativen anbieten, unter denen Ihr Gast wählen kann:<br/><br/>
                                        <Grid container spacing={2}>
                                            
                                            <Grid item xs={2} lg={1}>
                                                <Checkbox
                                                    checked={kanal.jitsi}
                                                    onChange={() => setKanal({...kanal, jitsi: !kanal.jitsi})}
                                                    color="primary"
                                                />
                                            </Grid>
                                            <Grid item xs={10} lg={11}>
                                                <Typography variant="body2" gutterBottom>
                                                    <strong>Standard: https://meet.fachpresse.live</strong> (Link in Einladungsmail)<br/>
                                                    <a href="https://meet.fachpresse.live/foyer" target="_blank">Hier können Sie testen!</a> (neuer Tab)
                                                </Typography>  
                                            </Grid>


                                            <Grid item xs={2} lg={1}>
                                                <Checkbox
                                                    checked={kanal.fon}
                                                    onChange={() => setKanal({...kanal, fon: !kanal.fon})}
                                                    color="primary"
                                                />
                                            </Grid>
                                            <Grid item xs={10} lg={11}>
                                                <TextField 
                                                    fullWidth
                                                    label="Über diese Telefonnummer"
                                                    variant="outlined"
                                                    style={{width: '100%'}}
                                                    value={fon}
                                                    onChange={txtFonChange}
                                                />
                                            </Grid>

                                            <Grid item xs={2} lg={1}>
                                                <Checkbox
                                                    checked={kanal.sonst}
                                                    onChange={() => setKanal({...kanal, sonst: !kanal.sonst})}
                                                    color="primary"
                                                />
                                            </Grid>
                                            <Grid item xs={10} lg={11}>
                                                <TextField 
                                                    fullWidth
                                                    label="Alternativer Meeting-Link (Teams, Zoom, ....)"
                                                    variant="outlined"
                                                    multiline
                                                    rows={3}
                                                    style={{width: '100%'}}
                                                    value={sonstKanal}
                                                    onChange={txtKanalChange}
                                                />
                                            </Grid>
                                    
                                        </Grid>
                                    </TableCell>
                                </TableRow>   
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        Termin:
                                    </TableCell>
                                    <TableCell >
                                        {zeit.beginnStunde}:{zeit.beginnMinute < 10 ? '0' : ''}{zeit.beginnMinute.toString()} Uhr 
                                        am {zeit.tagString}
                                    </TableCell>
                                </TableRow>                                                            
                            </TableBody>
                        </Table>

                        <TextField
                            autoFocus={false}
                            fullWidth
                            value={infoGast}
                            label="Zusatz-Info für den Gast"
                            variant="outlined"
                            onChange={(e) => setInfoGast(e.target.value)}
                        />

                    </CardContent>
                }
                {einladungSuccess &&
                    <CardContent>
                        <Alert severity="success">{einladungSuccess}</Alert>
                    </CardContent>
                }
                {einladungError &&
                    <CardContent>
                        <Alert severity="error">{einladungError}</Alert>
                    </CardContent>
                }
                <CardActions>
                    <Button variant="contained" onClick={() => setSelectedSlot(0)}>Abbrechen</Button>
                    <Button variant="contained" color="primary" disabled={!kanal.jitsi && !kanal.fon && !kanal.sonst} onClick={() => buchen(zeit.idFptMeetingZeitslot)}>Einladen</Button>
                </CardActions>
            </Card>
        );
    };


    const getTag = (tag) => props.meeting.zeitslots.find(zeit => zeit.idFptMeetingTag === tag);

    return (
        <React.Fragment>
            {props.meeting.gast &&
                <div style={{ marginBottom: '24px' }}>
                    <Typography variant="h5" gutterBottom>
                        {props.meeting.gast.vorname} {props.meeting.gast.nachname} einladen
                    </Typography>


                    <Typography variant="h6" gutterBottom>
                        {props.meeting.gast.firmenname && "Firma: " + props.meeting.gast.firmenname}
                        {props.meeting.gast.firmennamen && props.meeting.gast.firmennamen.length > 0 && "Redaktion: " + props.meeting.gast.firmennamen.join(', ')}
                    </Typography>

                    <Typography variant="body" gutterBottom>
                        Bitte wählen Sie einen Termin und eine Uhrzeit aus. Wenn Sie auf "Einladen" klicken  
                        erhält {props.meeting.gast.vorname} {props.meeting.gast.nachname} eine E-Mail 
                        mit den Daten und einem Link zum Online-Meeting und kann den Termin 
                        bestätigen. Sie erhalten auch eine Mail als CC. 
                    </Typography>

                </div>
            }

            { props.tage && props.tage.map(tag =>
                <Accordion
                    expanded={expanded === 'panelTag' + tag}
                    onChange={accordionHandleChange('panelTag' + tag)}
                    key={tag}
                >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>
                            {getTag(tag) && getTag(tag).tagString}
                        </Typography>
                    </AccordionSummary>

                    <AccordionDetails>
                        <List style={{ width: '100%' }}>
                            {props.meeting.zeitslots
                                .filter(zeit => zeit.idFptMeetingTag === tag)
                                .sort((a, b) => (a.beginnStunde != b.beginnStunde ? a.beginnStunde - b.beginnStunde : a.beginnMinute - b.beginnMinute))
                                .map(zeit =>
                                    <React.Fragment>
                                        <ListItem
                                            disabled={props.meeting.meineTermine && props.meeting.meineTermine.some(termin => termin.idFptMeetingZeitslot === zeit.idFptMeetingZeitslot) || 
                                                props.meeting.gastTermine && props.meeting.gastTermine.some(termin => termin.idFptMeetingZeitslot === zeit.idFptMeetingZeitslot)
                                            }
                                            button
                                            key={zeit.idFptMeetingZeitslot}
                                            onClick={() => setSelectedSlot(zeit.idFptMeetingZeitslot)}
                                        >
                                            <ListItemIcon>
                                                <InsertInvitationIcon />
                                            </ListItemIcon>
                                            <ListItemText primary={zeit.beginnStunde + ":" + (zeit.beginnMinute < 9 ? '0' : '') + zeit.beginnMinute.toString()} />
                                        </ListItem>

                                        {selectedSlot === zeit.idFptMeetingZeitslot &&
                                            <BuchenCard zeit={zeit} />
                                        }
                                    </React.Fragment>
                                )}
                        </List>
                    </AccordionDetails>

                </Accordion>
            )}
        </React.Fragment>
    )

}


const mapStateToProps = state => ({
    meeting: state.meeting,
    user: state.global.user
});

export default connect(mapStateToProps)(PageEinladen);