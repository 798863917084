import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'

import Chat, { ChatItem } from '../../utils/Chat';
import FptForceAnmeldung from '../../utils/FptForceAnmeldung';
import FptHeader from '../../utils/FptHeader';
import Grid from '@material-ui/core/Grid';

import { startGetYoutubeId } from '../../../actions/fpt';
    

const idFpt="fpt033";

const PageLive = (props) => {

    useEffect(() => {
        props.dispatch(startGetYoutubeId(idFpt));
    }, []);

    //const [youtubeId, setYoutubeId] = useState('jEE00LJ48QU');

    return (
        <React.Fragment>
            <FptHeader title="Live-Stream" />

            <FptForceAnmeldung idFpt={idFpt} fptTitel="Fachpressetage 2021">

                <Grid container spacing={2} style={{ height: 'calc( 100% + 12px )' }}>
                    <Grid item md={8} style={{ width: '100%' }}>
                        
                        <iframe
                            style={{ minWidth: '100%' }}
                            width="100%"
                            height="100%"
                            src={`https://www.youtube.com/embed/${props.youtubeId}?autoplay=1`}
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                            allowfullscreen>
                        </iframe>
                        
                    </Grid>
                    <Grid item md={4} style={{ height: '100%' }}>
                        <Chat />
                    </Grid>
                </Grid>

            </FptForceAnmeldung>

        </React.Fragment>
    );

}


const mapStateToProps = state => ({
    user: state.global.user,
    youtubeId: state.fpt[idFpt].youtubeId
});

export default connect(mapStateToProps)(PageLive);
