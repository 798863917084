import React from 'react';

import FptCard from './FptCard';
import FlickrLightbox from 'react-flickr-lightbox'
import { Typography } from '@material-ui/core';


/**
 * Zeigt eine Themenseite an
 * 
 * @param {object} props Component props
 * @param {object} props.thema (required)
 */
const FptMediaCard = (props) => {


    return (
        <FptCard title="Video">
            {props.thema && props.thema.youtubeId ?
                <iframe
                    style={{ width: '100%', height: '300px' }}
                    src={"https://www.youtube-nocookie.com/embed/" + props.thema.youtubeId}
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen="">
                </iframe>
                :
                <div>kein Video vorhanden.</div>
            }

            {props.thema &&
                <React.Fragment>
                    <Typography style={{marginTop: '16px'}} variant="h5" component="h2" gutterBottom>
                        Fotos
                    </Typography>
                    <FlickrLightbox api_key='e117905dbf4b792551c6d094ddd8fc41' user_id='140184022@N05' searchTerm={'idfptthema' + props.thema.idFptThema} limit={20} />
                </React.Fragment>
            }

        </FptCard>
    )

}

export default FptMediaCard