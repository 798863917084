import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'

import FptForceAnmeldung from '../../utils/FptForceAnmeldung';
import FptHeader from '../../utils/FptHeader';
import MeineThemen from '../generic/MeineThemenInlineUpload';
import Typography from '@material-ui/core/Typography';



const idFpt = "fpt035";
const titel = 'Fachpressetage 2024';


// sinnloser Comment
const PageMeineThemen = (props) => {

    return (
        <React.Fragment>
            <FptHeader title={titel} />

            <FptForceAnmeldung idFpt={idFpt} fptTitel={titel}>

                <Typography variant="h4" gutterBottom>
                    Meine Themen
                </Typography>

                <MeineThemen idFpt={idFpt} />

            </FptForceAnmeldung>

        </React.Fragment>
    )
}

export default connect()(PageMeineThemen);